import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { apiUrl } from "../../../../ApiUrlAndToken";
import EditWorkers from "./EditWorkers";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import GeneralModal from "../../../../components/GeneralModal";
import PhoneIcon from "@mui/icons-material/CallOutlined";

const WorkersList = () => {
  const [workers, setWorkers] = useState([]);
  const [workerID, setWorkerID] = useState(null);
  const [workerName, setWorkerName] = useState("");
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  const handleEdit = (ID, Name) => {
    setWorkerID(ID);
    setWorkerName(Name);
    toggleModal();
  };
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const renderAvatar = (name) => {
    const firstLetter = name.charAt(0).toUpperCase();
    const color = getRandomColor();
    return (
      <div
        style={{ backgroundColor: color }}
        className="w-12 h-12 text-center text-white text-4xl font-bold rounded-2xl pt-[2px]"
      >
        {firstLetter}
      </div>
    );
  };

  const getAllWorkers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/worker/getAllWorkers`);
      // console.log(response.data.resultArr);
      setWorkers(response.data.resultArr);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllWorkers();
  }, []);

  const handleDelete = async (workerID, name) => {
    if (window.confirm(`Are you sure you want to remove ${name}?`)) {
      try {
        await axios.delete(`${apiUrl}/worker/deleteWorker/${workerID}`);
        getAllWorkers();

        // setTimeout(() => {
        // window.location.href = `/admin/${id.ProjectName}/${id.ProjectID}`;
        // }, 500);
        toast.warning("Worker Deleted Successfully");
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  return (
    <>
      <table className="mx-auto">
        <thead>
          <tr className="border-b border-slate-300 flex gap-5">
            <th className="w-12"></th>
            <th className="w-40">Worker Name</th>
            <th className="w-72">Contact Info.</th>
            <th className="w-36">
              Rating{"("} Out of 5 {")"}
            </th>
            <th className="w-40">Contractor</th>
            <th className="w-16">Edit</th>
            <th className="w-16">Delete</th>
          </tr>
        </thead>
        <tbody>
          {/* Map through your data and render each row */}
          {workers.map((worker, index) => (
            <tr
              key={index}
              className="border-b border-slate-300 hover:bg-slate-100 h-20 flex gap-5 items-center"
            >
              <td>{renderAvatar(worker.name)}</td>

              <td className="w-40">
                <div className="font-bold">{worker.name}</div>
                <div className="text-sm text-slate-400">
                  Blood Group: {worker.bloodgroup}
                </div>
              </td>

              <td className="w-72">
                {worker.address}
                <br />
                <Link
                  to={`tel:${worker.contact}`}
                  target="_blank"
                  className="badge badge-info badge-outline hover:shadow"
                >
                  <PhoneIcon fontSize="inherit" className="mt-1 mr-1" />
                  {worker.contact}
                </Link>
              </td>

              <td className="w-36">
                <div className="font-bold">{worker.rating}/5</div>
              </td>
              <td className="w-40">
                <div className="font-bold">{worker.nameContractor}</div>
              </td>

              <td className="w-16">
                <button
                  className="btn btn-ghost hover:text-blue-500"
                  onClick={() => handleEdit(worker.worker_id, worker.name)}
                >
                  <EditIcon />
                </button>
              </td>

              <td className="w-16">
                <button
                  className="btn btn-ghost hover:text-red-500"
                  onClick={() => handleDelete(worker.worker_id, worker.name)}
                >
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Edit Worker Details */}
      {modal && workerID && workerName && (
        <GeneralModal title={`Edit ${workerName}`} onClose={toggleModal}>
          <EditWorkers workerID={workerID} onCancel={() => setWorkerID(null)} />
        </GeneralModal>
      )}
    </>
  );
};

export default WorkersList;
