import React, { useEffect, useState } from "react";
import "../../../components/Scrollbar.css";
import { apiUrl } from "../../../ApiUrlAndToken";
import axios from "axios";
import EditIcon from "@mui/icons-material/BorderColor";
import { hoverScale } from "../../../components/DesignStandardize";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import EditWorkQualityReport from "./EditWorkQualityReport";
import DownloadWorkQualityReport from "../../projectIndividual/workQualityReport/DownloadWorkQualityReport";

const WorkQualityReport = ({ project }) => {
  // console.log(project)
  const [workQualityReport, setWorkQualityReport] = useState([]);
  const getWorkQualityReportByProjectID = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/work-qualities/project/${parseInt(project.ProjectID)}`
      );
      // setWorkQualityReport(response.data);
      // console.log("workQualityReport:", response.data);
      const updatedWorkQualityReport = await Promise.all(
        response.data.map(async (el) => {
          // Fetch work name for each workID
          const workNameResponse = await axios.get(
            `${apiUrl}/scope-works/workName/${parseInt(el.WorkID)}`
          );
          // console.log(workNameResponse.data[0]);
          // console.log(workNameResponse.data[0][0]?.Name);
          // Adding WorkName property to the object
          return { ...el, WorkName: workNameResponse.data[0][0]?.Name };
        })
      );
      // console.log("workQualityReport:", updatedWorkQualityReport);
      setWorkQualityReport(updatedWorkQualityReport);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getWorkQualityReportByProjectID();
  }, [project.projectID]);

  const [workID, setWorkID] = useState(null);
  const [workName, setWorkName] = useState("");
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  const editModal = (id, name) => {
    setWorkID(id);
    setWorkName(name);
    toggleModal();
  };

  return (
    <>
      <div className="border-[1px] border-slate-300 hover:border-slate-400 rounded-lg p-2 min-w-[280px]">
        <div className="flex flex-row justify-between pb-2 border-b-[1px] border-slate-400">
          <p className="font-semibold text-xl text-black text-left">
            Work Quality Report
          </p>
          <DownloadWorkQualityReport project={project} />
        </div>
        <div className="overflow-x-auto h-screen my-2 flex flex-col gap-3">
          {workQualityReport.map((el, i) => {
            return (
              <div
                key={i}
                className={`bg-slate-50 w-full text-left border-[1px] border-solid rounded-lg flex flex-col gap-3 hover:shadow p-2`}
              >
                <div className="bg-inherit border-b-[1px] border-solid flex gap-1 justify-between">
                  {/* {el.WorkName.map((item, index) => (
                    <p key={index} className="text-lg font-semibold">
                      {item.Name}
                    </p>
                  ))} */}
                  <p className="text-lg font-semibold">
                    {
                      //  console.log(e.WorkName[0].Name);
                      el.WorkName
                    }
                  </p>
                  <div
                    className={`hover:cursor-pointe rounded-full ${hoverScale} text-blue-600 hover:text-blue-700`}
                    onClick={() => {
                      editModal(el.WorkQualityID, el.WorkName);
                    }}
                  >
                    <EditIcon fontSize="1px" />
                  </div>
                </div>
                <>
                  <p>
                    <span className="text-slate-500">
                      সম্পূর্ণ কৃতকাজের পরিমাণ - স্কয়ার ফিট হিসেবে:{" "}
                    </span>
                    {el.TotalWork}
                  </p>
                  <p>
                    <span className="text-slate-500">
                      কাজের গুণগত মানের জন্য তারিখ নির্ধারিত:{" "}
                    </span>
                    {el.Curent_Date}
                  </p>

                  <p>
                    <span className="text-slate-500">
                      এলাইনমেন্ট সঠিক কিনা:{" "}
                    </span>
                    {el.Allignment === 0
                      ? "ভালো নয়"
                      : el.Allignment === 1
                      ? "ভালো"
                      : "-"}
                  </p>

                  <p>
                    <span className="text-slate-500">
                      এক্সাসরিস সঠিকভাবে লাগানো হয়েছে কিনা:{" "}
                    </span>
                    {el.Accesories === 0
                      ? "ভালো নয়"
                      : el.Accesories === 1
                      ? "ভালো"
                      : "-"}
                  </p>

                  <p>
                    <span className="text-slate-500">
                      সিলিকন আউট সাইট সঠিক:{" "}
                    </span>
                    {el.SiliconOut === 0
                      ? "ভালো নয়"
                      : el.SiliconOut === 1
                      ? "ভালো"
                      : "-"}
                  </p>

                  <p>
                    <span className="text-slate-500">
                      সিলিকন ইন সাইট সঠিক:{" "}
                    </span>
                    {el.SiliconIn === 0
                      ? "ভালো নয়"
                      : el.SiliconIn === 1
                      ? "ভালো"
                      : "-"}
                  </p>

                  <p>
                    <span className="text-slate-500">আচরণ: </span>
                    {el.Behaviour}
                  </p>
                  <p>
                    <span className="text-slate-500">মন্তব্য: </span>
                    {el.Comment}
                  </p>
                </>
              </div>
            );
          })}

          <div className="mb-44" />
        </div>
      </div>

      {/* Modal to edit work quality report */}
      {modal && workID && workName && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex flex-col gap-3 justify-between">
              <div className="flex w-full justify-between">
                <p className="text-xl text-blue-900 font-bold w-max text-left">
                  Edit
                </p>
                <CloseIcon
                  onClick={toggleModal}
                  fontSize="1px"
                  className={`mb-auto hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
                />
              </div>
              <p className="max-h-28 h-max overflow-y-auto w-64 text-left mr-auto">
                {workName}
              </p>
            </div>
            <EditWorkQualityReport WorkQualityID={workID} />
          </div>
        </div>
      )}
    </>
  );
};

export default WorkQualityReport;
