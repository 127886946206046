import React, { useEffect, useState } from "react";
import { submitButton } from "../../../../components/DesignStandardize";
import GeneralInput from "../../../../components/GeneralInput";
import { apiUrl } from "../../../../ApiUrlAndToken";
import axios from "axios";
import { toast } from "react-toastify";

export const EditTarget = ({ scopeOfWorks }) => {
  const [targetIDs, setTargetIDs] = useState([]);
  const date = new Date().toISOString().split("T")[0];
  const initialRows = scopeOfWorks.map((work) => ({
    WorkID: work.WorkID,
    amount: 0,
    targetID: null,
    workID: null,
  }));
  const [rows, setRows] = useState(initialRows);

  const fetchTargetIDs = async () => {
    try {
      const targetIDsArray = await Promise.all(
        scopeOfWorks.map(async (work) => {
          const response = await axios.get(
            `${apiUrl}/workflow/getTargetID/${work.WorkID}/${date}`
          );
          return {
            amount: response.data[0]?.amount || 0,
            targetID: response.data[0]?.targetworkflow_id || null,
            workID: response.data[0]?.scope_work_id || null,
          };
        })
      );
      setRows(targetIDsArray);
    } catch (error) {
      console.error("Error fetching target IDs:", error);
    }
  };

  useEffect(() => {
    fetchTargetIDs();
  }, [scopeOfWorks]);

  const handleInputChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index].amount = parseFloat(value);
    setRows(updatedRows);
  };

  const handleEdit = async () => {
    try {
      let allUpdated = true;
      for (const row of rows) {
        if (row.targetID === null) continue;
        // const isEmpty = rows.some((row) => row.amount === 0);
        // if (isEmpty) {
        //   toast.error("Please fill in all the input fields.");
        //   return;
        // }
        const data = {
          workID: row.workID,
          amount: row.amount,
          date: new Date().toISOString().split("T")[0],
        };
        const response = await axios.put(
          `${apiUrl}/workflow/updateTarget/${row.targetID}`,
          data
        );
        // console.log(response.data);
        if (response.status !== 200) {
          allUpdated = false;
        }
      }
      if (allUpdated) {
        toast.success("All complete works have been edited successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        toast.error("Some complete works could not be edited.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="overflow-x-auto max-w-[320px] md:max-w-[520px] lg:max-w-[800px]">
        <table className="">
          <thead>
            <tr className="text-base text-black text-center">
              <th className="p-1">Scope of Work</th>
              <th className="p-1">Edit the amount</th>
            </tr>
          </thead>
          <tbody>
            {scopeOfWorks.map((work, index) => (
              <tr key={work.WorkID}>
                <td className="p-0">
                  <div
                    className={`px-3 py-1 text-sm flex-1 h-10 bg-transparent border w-full mt-2 cursor-not-allowed bg-slate-300`}
                  >
                    <p className="mt-1 text-left">{work.Work_Name}</p>
                  </div>
                </td>
                {rows[index].targetID !== null ? (
                  <td className="p-0">
                    <GeneralInput
                      type="number"
                      value={rows[index].amount}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      table={1}
                      min={0}
                    />
                  </td>
                ) : (
                  <td className="p-0">Data not available</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button className={`${submitButton} mt-3 mx-auto`} onClick={handleEdit}>
        Edit
      </button>
    </div>
  );
};
