import React, { useEffect, useState } from "react";
import PageTitle from "../pageTitle/PageTitle";
import PeopleIcon from "@mui/icons-material/PeopleAltOutlined";
import { hoverScale, submitButton } from "../../components/DesignStandardize";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import AddContractors from "./contractor/components/AddContractors";
import { Link } from "react-router-dom";
import { isEmployeeLoggedIn } from "../../ApiUrlAndToken";
import Contractor from "./contractor/Contractor";
import GeneralModal from "../../components/GeneralModal";

const Manpower = () => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <PageTitle title="Manpower" />
      <div className="md:ml-40 p-4">
        {loading ? (
          <div
            className={`mt-16 animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500
              border-solid m-auto`}
          />
        ) : (
          <div className="flex flex-col gap-3">
            <div className="mb-3 w-max mx-auto mt-12 md:mt-14 flex">
              <p className={`font-bold border-b-2 border-black px-2 pb-1`}>
                Contractor List
              </p>
              <Link
                to={`/${
                  isEmployeeLoggedIn ? "employee" : "admin"
                }/manpower/worker`}
                className={`px-2 pb-1 border-b-2 border-slate-400 hover:border-slate-700`}
              >
                Worker List
              </Link>
            </div>

            <Contractor />
          </div>
        )}
      </div>

      {/* Button to add new Contractor */}
      <>
        <div
          className={`w-max hidden md:block fixed bottom-2 right-2 hover:cursor-pointer font-Inter ${submitButton} ${hoverScale}`}
          onClick={toggleModal}
        >
          <PeopleIcon className="text-sm" /> Add Contractor
        </div>

        <div
          className={`w-max fixed bottom-2 left-2 md:hidden hover:cursor-pointer font-Inter ${submitButton} ${hoverScale}`}
          onClick={toggleModal}
        >
          <PeopleIcon className="text-sm" /> Add Contractor
        </div>
      </>

      {/* To Register New Contractor */}
      {modal && (
        <GeneralModal title="Add New Contractor" onClose={toggleModal}>
          <AddContractors />
        </GeneralModal>
      )}
    </>
  );
};

export default Manpower;
