import React, { useState, useEffect } from "react";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import axios from "axios";
import { apiUrl } from "../../../../ApiUrlAndToken";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import DisApproved from "../request/DisApproved";

const DownloadMaterialReport = ({ project, userId }) => {
  // console.log(project);
  const [materials, setMaterials] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [requestedItemNames, setRequestedItemNames] = useState([]);
  const getProjectName = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/project/fetchName/${project}`
      );
      // console.log(response.data);
      setProjectName(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getProjectName();
  }, [project]);
  const getAllRequests = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/materialReq/getAllMRbyProjectID/${project}`
      );
      // console.log(response.data)
      const mrIds = response.data.requestItemArr.map((el) => el.MR_ID);
      const assignIDs = response.data.requestItemArr.map((el) => el.AssignedID);
      const promises = mrIds.map((MR_ID) =>
        axios.get(`${apiUrl}/QCReport/fetchQCReportByMRID/${MR_ID}`)
      );
      const responses = await Promise.all(promises);
      const filteredResults = responses.flatMap((response, index) => {
        const MR_ID = mrIds[index];
        const AssignID = assignIDs[index];
        return response.data.filteredResult.map((report) => ({
          ...report,
          MR_ID,
          AssignID,
        }));
      });
      // setMaterials(filteredResults);
      // console.log(filteredResults);
      const ApprovedResults = filteredResults.filter(
        (item) => item[0]?.ApprovalStatus === 1 || item[0]?.ApprovalStatus === 2
      );
      // const statusOne = filteredResults.filter(item => item[0].ApprovalStatus === 1);
      // const statusTwo = filteredResults.filter(item => item[0].ApprovalStatus === 2);

      // console.log('Approved:', ApprovedResults);
      setMaterials(ApprovedResults);
      // console.log(filteredResults);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDownload = async () => {
    try {
      const requestedItemIDs = materials
        .map((obj) => (obj["0"] ? obj["0"]["RequestedItemID"] : undefined))
        .filter((id) => id !== undefined);

      let responseData = [];
      for (const el of requestedItemIDs) {
        const response = await axios.get(
          `${apiUrl}/requestedItem/fetchItemByID/${el}`
        );
        responseData = responseData.concat(
          response.data.map((el1) => ({
            itemId: el,
            ItemName: el1.ItemName,
            ExThickness: el1.Thickness,
            ExSize: el1.SIZE,
            ExAmount: el1.AmountExpected,
            ExDate: el1.ExpectedDate,
          }))
        );
      }
      setRequestedItemNames(responseData);
    } catch (error) {
      console.error("Error fetching requested item names:", error);
    }
    const data = materials.map((item) => ({
      ItemName:
        requestedItemNames.find(
          (el) => el.itemId === item["0"]?.RequestedItemID
        )?.ItemName || "",
      Status:
        item["0"]?.ApprovalStatus === 1
          ? "Approved"
          : item["0"]?.ApprovalStatus === 2 && "Disapproved",
      Size: item["0"]?.SIZE || "",
      Thickness: item["0"]?.Thickness || "",
      AmountReceived: item["0"]?.AmountGood || "",
      ArrivalDate: item["0"]?.ReportDate || "",
      Quality: item["0"]?.Quality || "",
      Remarks: item["0"]?.Remarks || "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    worksheet["A1"].v = "Item Name";
    worksheet["B1"].v = "Status";
    worksheet["C1"].v = "Size";
    worksheet["D1"].v = "Thickness";
    worksheet["E1"].v = "Amount Received";
    worksheet["F1"].v = "Arrival Date";
    worksheet["G1"].v = "Quality";
    worksheet["H1"].v = "Remarks";

    const columnWidths = [
      { wch: 30 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
    ];
    worksheet["!cols"] = columnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Material Report");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `Material_Report_${projectName}.xlsx`);
  };

  useEffect(() => {
    getAllRequests();
  }, [project]);

  return (
    <button
      onClick={handleDownload}
      className="hover:bg-success rounded hover:text-white"
    >
      <DownloadIcon />
    </button>
  );
};

export default DownloadMaterialReport;
