import React, { useEffect, useState } from "react";
import OngoingEmpProjects from "./OngoingEmpProjects";
import CompletedEmpProjects from "./CompletedEmpProjects";
import UpComingProjects from "./UpComingProjects";

const EmployeeProjects = ({ userId }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <>
      {loading ? (
        <div className="animate-spin rounded-full h-10 w-10 mx-auto border-t-4 border-blue-500 border-solid" />
      ) : (
        <div className="flex flex-col md:grid md:grid-cols-3 gap-2 font-Inter">
          <OngoingEmpProjects userId={userId} />
          <UpComingProjects userId={userId} />
          <CompletedEmpProjects userId={userId} />
        </div>
      )}
    </>
  );
};

export default EmployeeProjects;
