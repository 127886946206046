import React from "react";
import { Link, useLocation } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/PeopleAltOutlined";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import ProjectsIcon from "@mui/icons-material/HomeWorkOutlined";
import EngineeringIcon from "@mui/icons-material/Engineering";
import dTechLogo from "../assets/img/logo.png";
import { hoverScale } from "./DesignStandardize";
import { isAdminLoggedIn, isEmployeeLoggedIn } from "../ApiUrlAndToken";
import EngineerIcon from "@mui/icons-material/Engineering";

export const handleLogout = () => {
  localStorage.removeItem("jwtToken");
  if (isEmployeeLoggedIn) {
    localStorage.removeItem("employeeToken");
  } else if (isAdminLoggedIn) {
    localStorage.removeItem("admin");
  }
  window.location.href = "/";
};

const Navbar = () => {
  const location = useLocation();

  const userPanel = [
    {
      name: "Projects",
      path: "/employee/projects",
      Icons: <ProjectsIcon />,
    },
    {
      name: "Employees",
      path: "/employee/employee-list",
      Icons: <EngineerIcon />,
    },
    {
      name: "Manpower",
      path: "/employee/manpower/contractor",
      Icons: <PeopleIcon />,
    },
  ];

  const adminPanel = [
    {
      name: "Dashboard",
      path: "/admin/dashboard",
      Icons: <HomeIcon />,
    },
    {
      name: "Employees",
      path: "/admin/employee-list",
      Icons: <EngineerIcon />,
    },
    {
      name: "Manpower",
      path: "/admin/manpower/contractor",
      Icons: <PeopleIcon />,
    },
  ];

  return (
    <div className="font-Inter hidden md:flex flex-col justify-between px-2 py-5 bg-blue-50 text-white shadow-xl border-b-2 w-40 h-screen fixed left-0 top-0 bottom-0">
      <div className="text-center flex flex-col gap-6 items-center">
        {isAdminLoggedIn !== null && isEmployeeLoggedIn === null ? (
          <Link to="/admin/dashboard">
            <img
              src={dTechLogo}
              alt="Welcme to DTech Corp."
              className="my-auto w-20"
            />
          </Link>
        ) : (
          <Link to="/employee/projects">
            <img
              src={dTechLogo}
              alt="Welcme to DTech Corp."
              className="my-auto w-20"
            />
          </Link>
        )}

        <ul className="flex flex-col text-left gap-3 mt-10 w-max">
          {isAdminLoggedIn !== null && isEmployeeLoggedIn === null
            ? adminPanel.map((el, i) => (
                <li
                  key={i}
                  className={`text-lg lg:text-xl w-full p-1 rounded-lg text-black ${
                    location.pathname === el.path
                      ? "bg-blue-900 text-blue-50 shadow-lg"
                      : `hover:bg-blue-900 hover:text-white cursor-pointer`
                  }`}
                >
                  <Link
                    to={el.path}
                    className={`flex gap-2 items-center tooltip tooltip-right`}
                    // data-tip={el.name}
                  >
                    <p>{el.Icons}</p>
                    <p className="mt-1">{el.name}</p>
                  </Link>
                </li>
              ))
            : userPanel.map((el, i) => (
                <li
                  key={i}
                  className={`text-lg lg:text-xl w-full p-1 rounded-lg text-black ${
                    location.pathname === el.path
                      ? "bg-blue-900 text-blue-50 shadow-lg"
                      : `hover:bg-blue-900 hover:text-white cursor-pointer`
                  }`}
                >
                  <Link
                    to={el.path}
                    className={`flex gap-2 items-center tooltip tooltip-right`}
                    // data-tip={el.name}
                  >
                    <p>{el.Icons}</p>
                    <p className="mt-1">{el.name}</p>
                  </Link>
                </li>
              ))}
        </ul>
      </div>

      <Link
        className={`px-1 pb-1 rounded-lg text-xl hover:font-semibold mx-auto text-red-500 hover:text-white hover:bg-red-500 ${hoverScale}`}
        onClick={handleLogout}
      >
        <LogoutIcon /> Log out
      </Link>
    </div>
  );
};

export default Navbar;
