import React, { useEffect, useState } from "react";
import {
  apiUrl,
  isAdminLoggedIn,
  isEmployeeLoggedIn,
} from "../../ApiUrlAndToken";
import AdminSessionMenu from "./components/AdminSessionMenu";
import EmployeeSessionMenu from "./components/EmployeeSessionMenu";
import InsideProjectTopNavbar from "./components/InsideProjectTopNavbar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { hoverScale } from "../../components/DesignStandardize";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { useParams } from "react-router-dom";
import DetailsModalProject from "../projects/components/DetailsModalProject";
import axios from "axios";
import EditProject from "../projects/components/EditProject";
import EditIcon from "@mui/icons-material/BorderColor";
import GeneralModal from "../../components/GeneralModal";

const PageTitle = ({ title, insideProject }) => {
  const id = useParams();
  const [project, setProject] = useState("");
  const [detailsModal, setDetailsModal] = useState(false);
  const toggleDetailsModal = () => {
    setDetailsModal((prevShowModal) => !prevShowModal);
  };
  const ProjectDetailsModal = (project) => {
    setProject(project);
    toggleDetailsModal();
  };

  const [editModal, setEditModal] = useState(false);
  const toggleModal = () => {
    setEditModal((prevShowModal) => !prevShowModal);
  };

  const getProjectByID = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/project/fetchProject/${id.ProjectID}`
      );
      setProject(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (id.ProjectID) getProjectByID();
  }, [id.ProjectID]);

  return (
    <>
      <div className="font-Inter fixed top-0 pt-1 right-0 left-0 md:left-40 bg-slate-50 shadow-md h-max lg:h-12 px-1 md:px-4 flex flex-col gap-2">
        <div className="flex flex-row flex-wrap justify-between items-center">
          <div className="flex flex-row flex-wrap gap-2">
            <p className="text-left my-auto text-xl md:text-2xl font-semibold">
              {title}
            </p>

            <div className="join">
              {insideProject === 1 && (
                <div
                  onClick={() => ProjectDetailsModal(project)}
                  className="my-auto btn btn-sm btn-outline hover:text-white join-item"
                >
                  <VisibilityIcon /> View
                </div>
              )}
              {isAdminLoggedIn && insideProject === 1 && (
                <div
                  className="my-auto btn btn-sm btn-outline btn-info hover:text-white join-item"
                  onClick={toggleModal}
                >
                  <EditIcon fontSize="1px" /> Edit
                </div>
              )}
            </div>
            <div className="flex flex-row gap-3 md:hidden ml-auto">
              {insideProject === 1 && <InsideProjectTopNavbar />}

              {insideProject !== 1 && isAdminLoggedIn && <AdminSessionMenu />}
              {insideProject !== 1 && isEmployeeLoggedIn && (
                <EmployeeSessionMenu />
              )}
            </div>
          </div>

          <div className="md:flex flex-row gap-3 hidden">
            {insideProject === 1 && <InsideProjectTopNavbar />}

            {insideProject !== 1 && isAdminLoggedIn && <AdminSessionMenu />}
            {insideProject !== 1 && isEmployeeLoggedIn && (
              <EmployeeSessionMenu />
            )}
          </div>
        </div>

        {/* <div className="block lg:hidden">
          {insideProject === 1 && <InsideProjectTopNavbar />}
        </div> */}
      </div>

      {/* Modal to see details of a project */}
      {detailsModal && project && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleDetailsModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                {project.ProjectName} -{" "}
                <span
                  className={`${
                    project.Status === 0
                      ? "text-info"
                      : project.Status === 1
                      ? "text-warning"
                      : "text-success"
                  }`}
                >
                  {project.Status === 0
                    ? "Upcoming"
                    : project.Status === 1
                    ? "Ongoing"
                    : "Completed"}
                </span>
              </p>
              <CloseIcon
                onClick={toggleDetailsModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>

            <DetailsModalProject project={project} />
          </div>
        </div>
      )}

      {/* modal edit project */}
      {editModal && (
        <GeneralModal title={`Edit ${id.ProjectName}`} onClose={toggleModal}>
          <EditProject projectID={id.ProjectID} />
        </GeneralModal>
      )}
    </>
  );
};

export default PageTitle;
