import React from "react";
import GetImageRequestByStatus from "./GetImageRequestByStatus";
import GetRequestByStatus from "./GetRequestByStatus";

const Pending = ({ project, status, userID }) => {
  return (
    <>
      <div className="min-w-96 overflow-x-auto h-screen">
        <div className="my-2 flex flex-col gap-3">
          <GetRequestByStatus
            project={project}
            status={status}
            userID={userID}
          />

          <GetImageRequestByStatus
            project={project}
            status={status}
            userID={userID}
          />
        </div>
        <div className="mb-64" />
      </div>
    </>
  );
};

export default Pending;
