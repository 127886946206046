import React, { useEffect, useState } from "react";
import PeopleIcon from "@mui/icons-material/PeopleAltOutlined";
import RateWorkers from "../../manpower/worker/components/RateWorkers";
import { useParams } from "react-router-dom";
import { hoverScale } from "../../../components/DesignStandardize";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { apiUrl } from "../../../ApiUrlAndToken";
import PersonRemoveIcon from "@mui/icons-material/PersonRemoveAlt1Outlined";
import axios from "axios";
import { toast } from "react-toastify";

const ListContractorsWorkers = () => {
  const id = useParams();
  const [workerRatingModal, setWorkerRatingModal] = useState(false);
  const [arrayOfContractorAndWorkers, setArrayOfContractorAndWorkers] =
    useState([]);

  const toggleWorkerRatingModal = () => {
    setWorkerRatingModal((prevShowModal) => !prevShowModal);
  };

  const getContractorByP_ID = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/assignWorker/getContractorInfo/${id.ProjectID}`
      );

      const responseWorker = await axios.get(
        `${apiUrl}/assignWorker/getAssignedWorker/${id.ProjectID}`
      );

      const transformedData = response.data.Contractor.map((contractor) => ({
        contractor: contractor.name,
        id: contractor.contractor_id,
        worker: [],
      }));

      const updatedArray = transformedData.map((contractor) => {
        // Filter workers associated with the current contractor
        const associatedWorkers = responseWorker.data.Workers.filter(
          (worker) => worker.contractor_id === contractor.id
        );
        // console.log(associatedWorkers);
        // Map worker names
        const workerNamesAndRatings = associatedWorkers.map((worker) => ({
          workerName: worker.name,
          workerRating: worker.rating,
          workerID: worker.worker_id,
        }));
        // console.log(workerNamesAndRatings);
        // Set worker names based on contractor_id matching id
        return contractor.id === contractor.id
          ? { ...contractor, worker: workerNamesAndRatings }
          : contractor;
      });

      // setArrayOfContractorAndWorkers(transformedData);
      // console.log(updatedArray);
      setArrayOfContractorAndWorkers(updatedArray);
      // console.log("arrayOfContractorAndWorkers:", arrayOfContractorAndWorkers);
      // console.log("Contractor and Workers:", updatedArray);
    } catch (error) {
      console.error("Error fetching contractor data:", error);
    }
  };

  const deleteWorkerFromProject = async (workid, name) => {
    try {
      // console.log(workid);
      const response = await axios.delete(
        `${apiUrl}/assignWorker/deleteAssignedWorkerOne/${workid}/${id.ProjectID}`
      );
      // console.log(response.data);
      toast.warning(`${name} has been removed from the ${id.ProjectName}`);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (id.ProjectID) getContractorByP_ID();
  }, [id.ProjectID]);

  return (
    <>
      <div className="dropdown dropdown-hover dropdown-bottom dropdown-end my-auto">
        <div tabIndex={0} role="button" className="bg-slate-200 p-1 rounded-lg">
          <PeopleIcon /> Contractors
        </div>

        <ul
          tabIndex={0}
          className="dropdown-content z-40 menu p-2 shadow bg-base-100 rounded-box w-32"
        >
          {arrayOfContractorAndWorkers.length > 0 ? (
            <>
              {arrayOfContractorAndWorkers.map((el, i) => {
                return (
                  <li key={i} className="relative group">
                    <a>{el.contractor}</a>
                    <ul className="menu absolute hidden group-hover:block shadow bg-base-100 rounded-box w-36 right-28">
                      <div
                        onClick={() => toggleWorkerRatingModal()}
                        className={`text-black hover:text-white btn-accent btn btn-sm gap-0 mb-2`}
                      >
                        Add Rating
                      </div>
                      {el.worker.map((el1, index) => {
                        return (
                          <li key={index} className="text-left">
                            <a>
                              <div
                                className="tooltip tooltip-bottom"
                                data-tip="Remove"
                                onClick={() => {
                                  deleteWorkerFromProject(
                                    el1.workerID,
                                    el1.workerName
                                  );
                                }}
                              >
                                <PersonRemoveIcon
                                  className="hover:cursor-pointer"
                                  fontSize="small"
                                />
                              </div>
                              <div className="flex flex-col">
                                <p>{el1.workerName}</p>

                                <div>
                                  Rating:{" "}
                                  <span className="font-semibold">
                                    {el1.workerRating}
                                  </span>
                                </div>
                                {/* {renderStars(el1.workerRating)} */}
                              </div>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </>
          ) : (
            <p className="font-semibold animate-pulse">
              No Workers are assigned for {id.ProjectName}
            </p>
          )}
        </ul>
      </div>

      {/* Modal to rate worker */}
      {workerRatingModal && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleWorkerRatingModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                Rate Assigned Workers for <br />
                {id.ProjectName}
              </p>
              <CloseIcon
                onClick={toggleWorkerRatingModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
            <RateWorkers projectID={id} />
          </div>
        </div>
      )}
    </>
  );
};

export default ListContractorsWorkers;
