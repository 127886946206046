import axios from "axios";
import React, { useState, useEffect } from "react";
import { apiUrl } from "../../../ApiUrlAndToken";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import GeneralSelect from "../../../components/GeneralSelect";
import { submitButton } from "../../../components/DesignStandardize";

const ScopeWorkEdit = ({
  workName,
  workID,
  workStatus,
  wordDescription,
  totalWork,
}) => {
  const [workNameI, setWorkNameI] = useState(workName);
  const [description, setDescription] = useState(wordDescription);
  const [squareFoot, setSqaureFoot] = useState(totalWork);
  // const [status, setStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const ProjectStatus = [
    { value: "Upcoming", label: "Upcoming" },
    { value: "Ongoing", label: "Ongoing" },
    { value: "Completed", label: "Completed" },
  ];
  useEffect(() => {
    fetchScopeWork();
  }, [workID]);

  const fetchScopeWork = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/scope-works/getWorkByID/${workID}`
      );
      const data = response.data;

      // setWorkName(data.Work_Name);
      // setDescription(data.Description);
      // setStatus(data.Status);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const updateScopeWork = async (ev) => {
    ev.preventDefault();
    try {
      let statusChanged = false;
      let detailsChanged = false;
      if (selectedStatus) {
        updateStatusScope();
        statusChanged = true;
      }
      const data = {
        workName: workNameI,
        sqrft: squareFoot || 0,
        description: description,
      };

      const response = await axios.put(`${apiUrl}/scope-works/${workID}`, data);
      console.log("Individual Scope of work:", response.data);
      // if(response.status === 201){console.log(true)}
      if (response.status === 201) {
        detailsChanged = true;
      }
      if (statusChanged && detailsChanged) {
        toast.success("Overall Details Updated Successfully!!");
      } else if (statusChanged) {
        toast.success("Status Got Updated Successfully!!");
      } else if (detailsChanged) {
        toast.success("Work Details Updated Successfully!!");
      } else {
        toast.info("No modification.");
      }
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        toast.error(`${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response receieved from the server");
      } else {
        toast.error("An error occured");
      }
    }
  };

  const updateStatusScope = async () => {
    // ev.preventDefault();
    let statusValue;
    switch (selectedStatus) {
      case "Upcoming":
        statusValue = 0;
        break;
      case "Ongoing":
        statusValue = 1;
        break;
      case "Completed":
        statusValue = 2;
        break;
      default:
        statusValue = -1;
        break;
    }
    if (statusValue === -1) {
      toast.error("Invalid project status");
      return;
    }
    try {
      const data = {
        status: statusValue,
      };
      const response = await axios.put(
        `${apiUrl}/scope-works/statusUpdate/${workID}`,
        data
      );
      // console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form
      className="flex flex-col gap-3 items-center mx-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={updateScopeWork}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 max-h-96 h-max overflow-y-auto p-1">
        <div className="flex flex-col gap-3 items-center">
          <GeneralInput
            type="textarea"
            label="Work Name"
            placeholder="Enter Work Name"
            value={workNameI}
            onChange={(ev) => setWorkNameI(ev.target.value)}
            required
          />
          <GeneralInput
            type="number"
            label="Total Work (sq ft)"
            placeholder="Enter the amount"
            value={squareFoot}
            onChange={(ev) => setSqaureFoot(ev.target.value)}
            required
          />
        </div>

        <div className="flex flex-col gap-3 items-center">
          <GeneralInput
            label="Description"
            type="textarea"
            placeholder="Enter Description"
            value={description}
            onChange={(ev) => setDescription(ev.target.value)}
            required
          />
          <GeneralSelect
            options={ProjectStatus}
            label="Select Project Status"
            placeholder="Upcoming/Ongoing/Complete"
            onChange={(selectedOption) =>
              setSelectedStatus(selectedOption.value)
            }
            value={
              ProjectStatus.find((el) => el.value === selectedStatus) || null
            }
          />
        </div>
      </div>
      <button className={`${submitButton} mt-3`}>Update Scope Work</button>
    </form>
  );
};

export default ScopeWorkEdit;
