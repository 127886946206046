import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isEmployeeLoggedIn } from "../../../ApiUrlAndToken";
import PageTitle from "../../pageTitle/PageTitle";
import WorkersList from "./components/WorkersList";

const Worker = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      <PageTitle title="Manpower" />
      <div className="md:ml-40 p-4">
        {loading ? (
          <div
            className={`mt-16 animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500
              border-solid m-auto`}
          />
        ) : (
          <div className="flex flex-col gap-3">
            <div className="mb-3 w-max mx-auto mt-12 md:mt-14 flex">
              <Link
                to={`/${
                  isEmployeeLoggedIn ? "employee" : "admin"
                }/manpower/contractor`}
                className={`px-2 pb-1 border-b-2 border-slate-400 hover:border-slate-700`}
              >
                Contractor List
              </Link>
              <p className={`font-bold border-b-2 border-black px-2 pb-1`}>
                Worker List
              </p>
            </div>

            <WorkersList />
          </div>
        )}
      </div>
    </>
  );
};

export default Worker;
