import React, { useEffect, useState } from "react";
import GeneralInput from "../../../components/GeneralInput";
import { submitButton } from "../../../components/DesignStandardize";
import axios from "axios";
import { apiUrl } from "../../../ApiUrlAndToken";
import { toast } from "react-toastify";

const AdminProfileEdit = ({ id }) => {
  const [data, setData] = useState({
    Email: "",
    Name: "",
  });
  const [Password, setPassword] = useState("123456");
  const [picture, setPicture] = useState(null);

  const getAdmin = async () => {
    try {
      const response = await axios.get(`${apiUrl}/admin/fetchAdmin/${id}`);
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const updateAdminInfo = async (e) => {
    e.preventDefault();
    try {
      if (Password && picture) {
        const formData = new FormData();
        formData.append("Name", data.Name);
        formData.append("Email", data.Email);
        formData.append("Password", Password);
        formData.append("picture", picture);

        const response = await axios.put(
          `${apiUrl}/admin/update/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        toast.success("Admin information updated successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        toast.error("Please enter your password and select a picture!");
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(`${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response received from the server");
      } else {
        toast.error("An error occurred while processing the request");
      }
    }
  };

  useEffect(() => {
    getAdmin();
  }, [id]);

  return (
    <form
      className="flex flex-col gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={updateAdminInfo}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <GeneralInput
          label="Admin Email"
          type="email"
          placeholder="Enter Admin Email"
          value={data.Email}
          onChange={(e) => {
            setData({ ...data, Email: e.target.value });
          }}
        />
        <GeneralInput
          label="Admin Name"
          type="text"
          placeholder="Enter Admin Name"
          value={data.Name}
          onChange={(e) => {
            setData({ ...data, Name: e.target.value });
          }}
        />

        <GeneralInput
          label="Admin Image"
          id="Photo"
          name="Photo"
          type="file"
          onChange={(e) => setPicture(e.target.files[0])}
        />

        <div>
          <GeneralInput
            label="Admin Password"
            type="password"
            placeholder="Enter Admin Password"
            value={Password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <p className="w-60 font-light text-slate-400 text-sm mt-2">
            Admin Password is set to "123456" by default
          </p>
        </div>
      </div>

      <button className={`${submitButton} mt-3`}>Update</button>
    </form>
  );
};

export default AdminProfileEdit;
