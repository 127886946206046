import React, { useEffect, useRef, useState } from "react";
import "../../../components/Scrollbar.css";
import AddScopeofWOrkIcon from "@mui/icons-material/PostAddOutlined";
import { hoverScale } from "../../../components/DesignStandardize";
import ScopeWorkCreate from "./ScopeWorkCreate";
import axios from "axios";
import { apiUrl, isEmployeeLoggedIn } from "../../../ApiUrlAndToken";
import EditIcon from "@mui/icons-material/BorderColor";
import ScopeWorkEdit from "../scopeOfWork/ScopeWorkEdit";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";

const ScopeWork = ({ id }) => {
  const { ProjectName, ProjectID } = useParams();
  const [scopeOfWork, setScopeOfWork] = useState([]);
  const [workID, setWorkID] = useState(null);
  const [workName, setWorkName] = useState("");
  const [workStatuss, setWorkStatuss] = useState(0);
  const [workDescription, setWorkDescription] = useState("");
  const [text, setText] = useState("");
  const [color, setColor] = useState("");
  const [totalWork, setTotalWork] = useState(null);
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  const editModal = (id, name, status, description, totalWork, text, color) => {
    console.log(totalWork);
    setWorkID(id);
    setWorkName(name);
    setWorkStatuss(status);
    setTotalWork(totalWork);
    setWorkDescription(description);
    setText(text);
    setColor(color);
    toggleModal();
  };

  const getAllWorks = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/scope-works/project/${parseInt(id)}`
      );

      setScopeOfWork(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return { text: "Upcoming", color: "text-info" };
      case 1:
        return { text: "Ongoing", color: "text-warning" };
      case 2:
        return { text: "Completed", color: "text-success" };
      default:
        return "";
    }
  };

  useEffect(() => {
    getAllWorks();
  }, [id]);

  const [showCard, setShowCard] = useState(false);
  const cardRef = useRef(null);
  const handleAddScopeOfWorkClick = () => {
    setShowCard(true);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setShowCard(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const DeleteWork = async (workID, name) => {
    try {
      const response = await axios.delete(
        `${apiUrl}/scope-works/deleteScopeWork/${workID}`
      );
      // console.log(response.data);
      toast.warn(`${name} has been deleted successfully!`);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      toast.error("An error occurd!");
    }
  };
  return (
    <>
      <div className="border-[1px] border-slate-300 hover:border-slate-400 rounded-lg p-2">
        <div className="flex gap-2 justify-between pb-2 border-b-[1px] border-slate-400">
          <p className="font-semibold text-xl">Scope of Work</p>{" "}
          <div
            className={`${hoverScale}`}
            onClick={handleAddScopeOfWorkClick}
            // data-tip={"Add Task"}
          >
            <AddScopeofWOrkIcon className="text-sm" />
          </div>
        </div>

        <div className="overflow-x-auto h-screen my-2 flex flex-col gap-3">
          {showCard && (
            <div
              ref={cardRef}
              className="border-[1px] border-slate-400 p-1 rounded-lg"
            >
              <ScopeWorkCreate id={id} />
            </div>
          )}
          {scopeOfWork.map((el, i) => {
            const { text, color } = getStatusText(el.Status);
            return (
              <div
                key={i}
                className={`w-full border-[1px] border-solid text-left rounded-lg flex flex-col hover:shadow p-2 ${
                  el.sqrfoot === null ? "bg-red-100" : "bg-slate-50 "
                }`}
              >
                <Link
                  className="hover:cursor-pointer"
                  to={`/${
                    isEmployeeLoggedIn ? "employee" : "admin"
                  }/scope-of-work/${ProjectName}/${ProjectID}/${el.Work_Name}/${
                    el.WorkID
                  }`}
                >
                  {el.Work_Name}
                </Link>
                {el.sqrfoot === null ? (
                  <p className="text-red-700 animate-pulse font-bold">
                    Please add total area
                  </p>
                ) : (
                  <p>
                    <span className="text-slate-500">Total area:</span>{" "}
                    <span className="font-semibold">{el.sqrfoot}</span> sq ft
                  </p>
                )}

                <div className="flex justify-between mt-2">
                  <p className={`${color}`}>{text}</p>
                  <div className="flex gap-2">
                    {/* Edit Button */}
                    <div
                      className={`hover:cursor-pointe rounded-full ${hoverScale} text-blue-600 hover:text-blue-700 `}
                      onClick={() => {
                        editModal(
                          el.WorkID,
                          el.Work_Name,
                          el.Status,
                          el.Description,
                          el.sqrfoot,
                          text,
                          color
                        );
                      }}
                    >
                      <EditIcon fontSize="1px" />
                    </div>

                    {/* Delete Button */}
                    <div
                      className={`hover:cursor-pointe rounded-full ${hoverScale} text-black hover:text-red-500 `}
                      onClick={() => {
                        DeleteWork(el.WorkID, el.Work_Name);
                      }}
                    >
                      <DeleteIcon fontSize="1px" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="mb-44" />
        </div>
      </div>

      {/* Modal to edit scope of work */}
      {modal && workID && workName && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex flex-col gap-3 justify-between">
              <div className="flex w-full justify-between">
                <p className="text-xl text-blue-900 font-bold w-64 text-left">
                  Edit -{" "}
                  <span className={`${color} animate-pulse`}>{text}</span>
                </p>
                <CloseIcon
                  onClick={toggleModal}
                  fontSize="1px"
                  className={`mb-auto hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
                />
              </div>
              <p className="max-h-28 h-max overflow-y-auto w-64 text-left mr-auto">
                {workName}
              </p>
            </div>
            <ScopeWorkEdit
              workID={workID}
              workName={workName}
              workStatus={workStatuss}
              wordDescription={workDescription}
              totalWork={totalWork}
              onCancel={() => setWorkID(null)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ScopeWork;
