import React, { useEffect, useState } from "react";
import axios from "axios";
import EditEmployee from "./EditEmployee";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { hoverScale } from "../../../components/DesignStandardize";
import EditIcon from "@mui/icons-material/BorderColor";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ProjectsIcon from "@mui/icons-material/HomeWorkOutlined";
import { toast } from "react-toastify";
import EmployeeProjects from "../employeeProjects/EmployeeProjects";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [editingUserId, setEditingUserId] = useState(null);
  const [projectModalID, setProjectModalID] = useState(null);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/users`);
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  const [projectModal, setProjectModal] = useState(false);
  const toggleProjectModal = () => {
    setProjectModal(!projectModal);
  };
  const ProjectFetch = (userID) => {
    setProjectModalID(userID);
    toggleProjectModal();
  };
  const handleEdit = (userId) => {
    setEditingUserId(userId);
    toggleModal();
  };

  const handleDelete = async (userId, name) => {
    if (window.confirm(`Are you sure you want to remove ${name}?`)) {
      try {
        await deleteAssignedEngineer(userId);
        await axios.delete(`${apiUrl}/users/${userId}`);
        // Refresh the user list after deletion
        const response = await axios.get(`${apiUrl}/users`);
        setUsers(response.data);
        toast.warning("Employee Deleted Successfully");
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };
  const deleteAssignedEngineer = async (userID) => {
    try {
      const response = await axios.delete(
        `${apiUrl}/assign/deleteAllAssignedByUser/${userID}`
      );
      // console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <ul className="flex flex-row flex-wrap gap-4 md:gap-6 font-Inter">
        {users.map((user) => (
          <li
            key={user.UserID}
            className="w-max h-max bg-[#f9fbfd] p-4 flex flex-col gap-2 hover:shadow-md rounded-xl border border-solid"
          >
            <div className="flex flex-row gap-5 justify-between">
              <img
                src={`${apiUrl}/uploads/${user.Photo}`}
                alt={user.Name}
                className="w-20 h-20 shadow rounded-full"
              />
              {isAdminLoggedIn && (
                <div className="flex flex-row gap-3">
                  <button
                    className={`mb-auto hover:text-blue-500 hover:cursor-pointer flex items-center justify-center rounded-full text-black ${hoverScale}`}
                    onClick={() => handleEdit(user.UserID)}
                  >
                    <EditIcon fontSize="1px" />
                  </button>
                  {/* <button
                    className={`mb-auto hover:cursor-pointer flex items-center justify-center rounded-full text-black ${hoverScale} hover:text-red-500`}
                    onClick={() => handleDelete(user.UserID, user.Name)}
                  >
                    <DeleteIcon fontSize="1px" />
                  </button> */}

                  <button
                    className={`mb-auto hover:cursor-pointer flex items-center justify-center rounded-full text-black ${hoverScale} hover:text-green-500`}
                    onClick={() => ProjectFetch(user.UserID)}
                  >
                    <ProjectsIcon fontSize="1px" />
                  </button>
                </div>
              )}
            </div>

            <div className="text-left">
              {/* <p>
                Employee ID: <span>{user.UserID}</span>
              </p> */}
              <p>
                Name: <strong>{user.Name}</strong>
              </p>
              <p>
                Email: <span>{user.Email}</span>
              </p>

              <p>
                Contact: <span>{user.Contact}</span>
              </p>
            </div>
          </li>
        ))}
      </ul>

      {modal && editingUserId && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                Edit Employee Details
              </p>
              <CloseIcon
                onClick={toggleModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
            <EditEmployee
              userId={editingUserId}
              onCancel={() => setEditingUserId(null)}
            />
          </div>
        </div>
      )}
      {projectModal && projectModalID && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleProjectModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                See Individual Projects
              </p>
              <CloseIcon
                onClick={toggleProjectModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
            <EmployeeProjects
              userId={projectModalID}
              onCancel={() => setProjectModalID(null)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default UserList;
