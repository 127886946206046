import React, { useState, useEffect } from "react";
import PageTitle from "../pageTitle/PageTitle";
import { isAdminLoggedIn } from "../../ApiUrlAndToken";
import { hoverScale, submitButton } from "../../components/DesignStandardize";
import ProjectsIcon from "@mui/icons-material/HomeWorkOutlined";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import OngoingProjects from "../projects/OngoingProjects";
import CompletedProjects from "../projects/CompletedProjects";
import UpcomingProjects from "../projects/UpcomingProjects";
import ProjectAdd from "../projects/components/ProjectAdd";
import SearchForm from "./component/SearchProject";
import GeneralModal from "../../components/GeneralModal";

const Dashbooard = () => {
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <PageTitle title="Dashboard" />

      <div className="my-14 md:my-16 md:ml-40 flex flex-col gap-6 font-Inter">
        {loading ? (
          <div className="mt-5 animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid m-auto" />
        ) : (
          <>
            <SearchForm />
            <OngoingProjects />
            <CompletedProjects />
            <UpcomingProjects />
          </>
        )}
      </div>

      {/* Button To Add New Projects md to large screen */}
      {isAdminLoggedIn && (
        <div
          className={`w-max hidden md:block fixed bottom-2 right-2 hover:cursor-pointer font-Inter ${submitButton} ${hoverScale}`}
          onClick={toggleModal}
        >
          <ProjectsIcon className="text-sm" /> Add New Project
        </div>
      )}
      {/* Button To Add New Projects small screen */}
      {isAdminLoggedIn && (
        <div
          className={`w-max fixed bottom-2 left-2 md:hidden hover:cursor-pointer font-Inter ${submitButton} ${hoverScale}`}
          onClick={toggleModal}
        >
          <ProjectsIcon className="text-sm" /> Add New Project
        </div>
      )}

      {/* Modal To Add New Projects */}
      {modal && (
        <GeneralModal title="New Project" onClose={toggleModal}>
          <ProjectAdd />
        </GeneralModal>
      )}
    </>
  );
};

export default Dashbooard;
