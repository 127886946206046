import React from "react";
import { apiUrl, isAdminLoggedIn } from "../../../../ApiUrlAndToken";
import { toast } from "react-toastify";
import axios from "axios";

const UpdateStatusRequest = ({ MR_ID, MR_Status }) => {
  const handleStatusChange = async (status) => {
    // console.log("status:", status);
    try {
      const data = {
        status,
      };
      // console.log("data:", data);

      const response = await axios.put(
        `${apiUrl}/materialReq/updateRequisitionStatus/${MR_ID}`,
        data
      );
      // console.log("data:", response.data);

      {
        status === 1
          ? toast.success("Material Request has been approved.")
          : toast.error("Material Request has been denied.");
      }

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      toast.error("An error has occured!");
      console.log(error);
    }
  };
  return (
    <>
      {isAdminLoggedIn && MR_Status === 0 && (
        <div className="flex gap-3 justify-center">
          <button
            className="btn btn-sm btn-success hover:text-white"
            onClick={() => {
              handleStatusChange(1);
            }}
          >
            Approve
          </button>

          <button
            className="btn btn-sm btn-error hover:text-white"
            onClick={() => {
              handleStatusChange(2);
            }}
          >
            Disapprove
          </button>
        </div>
      )}
    </>
  );
};

export default UpdateStatusRequest;
