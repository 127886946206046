import React, { useEffect, useState } from "react";
import "../../../components/Scrollbar.css";
import axios from "axios";
import { apiUrl } from "../../../ApiUrlAndToken";
import EditIcon from "@mui/icons-material/BorderColor";
import { hoverScale } from "../../../components/DesignStandardize";
import ScopeWorkEdit from "../scopeOfWork/ScopeWorkEdit";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import RightArrowIcon from "@mui/icons-material/KeyboardArrowRight";
import LeftArrowIcon from "@mui/icons-material/KeyboardArrowLeft";
import { toast } from "react-toastify";
import AddWQRIcon from "@mui/icons-material/Addchart";
import AddWorkQualityReport from "../workQualityReport/AddWorkQualityReport";
import DeleteIcon from "@mui/icons-material/Delete";

const OngoingScopeOfWork = ({ id }) => {
  const [scopeOfWork, setScopeOfWork] = useState([]);
  const [workID, setWorkID] = useState(null);
  const [workName, setWorkName] = useState("");
  const [workStatuss, setWorkStatuss] = useState(0);
  const [workDescription, setWorkDescription] = useState("");
  const [totalWork, setTotalWork] = useState(null);
  const [modal, setModal] = useState(false);
  const currentDate = new Date().toISOString().split("T")[0];
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  const editModal = (id, name, status, description, totalWork) => {
    setWorkID(id);
    setWorkName(name);
    setWorkStatuss(status);
    setWorkDescription(description);
    setTotalWork(totalWork);
    toggleModal();
  };
  const [workQualityModal, setWorkQualityModal] = useState(false);
  const toggleWorkQualityModal = () => {
    setWorkQualityModal((prevShowModal) => !prevShowModal);
  };
  const addWorkQualityReport = (id, name) => {
    setWorkID(id);
    setWorkName(name);
    toggleWorkQualityModal();
  };
  const getAllWorks = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/scope-works/project/${parseInt(id)}`
      );
      const filteredData = response.data.filter((item) => item.Status === 1);
      // setScopeOfWork(filteredData);
      const workQualityReport = await Promise.all(
        filteredData.map(async (el) => {
          const workDateResponse = await axios.get(
            `${apiUrl}/work-qualities/getWorkQualityByScopeWorkID/${parseInt(
              el.WorkID
            )}`
          );

          // console.log("Work Date: ", workDateResponse.data[0]?.Curent_Date);

          const workQualityDates =
            workDateResponse.data.length > 0 &&
            workDateResponse.data.map((quality) => quality.Curent_Date);
          return {
            ...el,
            WorkQualityDates: workQualityDates[0] || "",
            // shouldRemoveButton: shouldRemoveButton
          };
        })
      );
      // console.log("workQualityReport:", workQualityReport);
      setScopeOfWork(workQualityReport);
    } catch (error) {
      console.log(error);
    }
  };
  const updateStatusLeft = async (ID, name) => {
    try {
      const data = {
        status: 0,
      };
      const response = await axios.put(
        `${apiUrl}/scope-works/statusUpdate/${ID}`,
        data
      );
      // console.log(response.data);
      toast.success(`${name} is moved to Upcoming`);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };
  const updateStatusRight = async (ID, name) => {
    try {
      const data = {
        status: 2,
      };
      const response = await axios.put(
        `${apiUrl}/scope-works/statusUpdate/${ID}`,
        data
      );
      // console.log(response.data);
      toast.success(`${name} is moved to Completed`);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllWorks();
  }, [id]);
  const DeleteWork = async (workID, name) => {
    try {
      const response = await axios.delete(
        `${apiUrl}/scope-works/deleteScopeWork/${workID}`
      );
      // console.log(response.data);
      toast.warn(`${name} has been deleted successfully!`);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      toast.error("An error occurd!");
    }
  };
  return (
    <>
      <div className="border-[1px] border-slate-300 hover:border-slate-400 rounded-lg p-2">
        <p className="font-semibold text-xl text-orange-500 text-left pb-2 border-b-[1px] border-slate-400">
          Ongoing
        </p>

        <div className="overflow-x-auto h-screen my-2 flex flex-col gap-3">
          {scopeOfWork.map((el, i) => {
            return (
              <div
                key={i}
                className={`${
                  el.sqrfoot === null ? "bg-red-100" : "bg-slate-50 "
                } w-full border-[1px] border-solid text-left rounded-lg flex flex-col hover:shadow p-2`}
              >
                <p
                  className="hover:cursor-pointer"
                  onClick={() =>
                    editModal(
                      el.WorkID,
                      el.Work_Name,
                      el.Status,
                      el.Description,
                      el.sqrfoot
                    )
                  }
                >
                  {el.Work_Name}
                </p>
                {el.sqrfoot === null ? (
                  <p className="text-red-700 animate-pulse font-bold">
                    Please add total area
                  </p>
                ) : (
                  <p>
                    <span className="text-slate-500">Total area:</span>{" "}
                    <span className="font-semibold">{el.sqrfoot}</span> sq ft
                  </p>
                )}

                <div className="flex justify-between mt-2">
                  <div className={`flex`}>
                    <div
                      className={`hover:cursor-pointe rounded-full ${hoverScale}`}
                      onClick={() => {
                        updateStatusLeft(el.WorkID, el.Work_Name);
                      }}
                    >
                      <LeftArrowIcon />
                    </div>
                    <p className="text-warning">Ongoing</p>
                  </div>
                  <div className="flex gap-2">
                    <div
                      className={`hover:cursor-pointe rounded-full ${hoverScale} text-blue-600 hover:text-blue-700`}
                      onClick={() =>
                        editModal(
                          el.WorkID,
                          el.Work_Name,
                          el.Status,
                          el.Description,
                          el.sqrfoot
                        )
                      }
                    >
                      <EditIcon fontSize="1px" />
                    </div>
                    <div
                      className={`hover:cursor-pointe rounded-full ${hoverScale} text-black hover:text-red-500 `}
                      onClick={() => {
                        DeleteWork(el.WorkID, el.Work_Name);
                      }}
                    >
                      <DeleteIcon fontSize="1px" />
                    </div>
                    <div
                      className={`hover:cursor-pointe rounded-full ${hoverScale}`}
                      onClick={() => {
                        updateStatusRight(el.WorkID, el.Work_Name);
                      }}
                    >
                      <RightArrowIcon />
                    </div>
                  </div>
                </div>
                {el.WorkQualityDates !== currentDate && (
                  <div
                    onClick={() =>
                      addWorkQualityReport(el.WorkID, el.Work_Name)
                    }
                    className={`btn btn-outline btn-sm mt-3`}
                  >
                    <AddWQRIcon /> Work Quality Report
                  </div>
                )}
              </div>
            );
          })}

          <div className="mb-44" />
        </div>
      </div>

      {modal && workID && workName && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex flex-col gap-3 justify-between">
              <div className="flex w-full justify-between">
                <p className="text-xl text-blue-900 font-bold w-64 text-left">
                  Edit -{" "}
                  <span className="text-warning animate-pulse">Ongoing</span>
                </p>
                <CloseIcon
                  onClick={toggleModal}
                  fontSize="1px"
                  className={`mb-auto hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
                />
              </div>
              <p className="max-h-28 h-max overflow-y-auto w-64 text-left mr-auto">
                {workName}
              </p>
            </div>
            <ScopeWorkEdit
              workID={workID}
              workName={workName}
              workStatus={workStatuss}
              wordDescription={workDescription}
              totalWork={totalWork}
              onCancel={() => setWorkID(null)}
            />
          </div>
        </div>
      )}
      {workQualityModal && id && workID && workName && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleWorkQualityModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex flex-col gap-3 justify-between">
              <div className="flex justify-between w-full">
                <p className="text-xl text-blue-900 font-bold w-64 text-left">
                  Add - Work Quality Report
                </p>
                <CloseIcon
                  onClick={toggleWorkQualityModal}
                  fontSize="1px"
                  className={`mb-auto hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
                />
              </div>
              <p className="max-h-28 h-max overflow-y-auto w-[267px] md:w-[512px] text-left mr-auto">
                {workName}
              </p>
            </div>
            <AddWorkQualityReport
              workID={workID}
              projectId={id}
              workName={workName}
              onCancel={() => setWorkID(null)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default OngoingScopeOfWork;
