import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import { submitButton } from "../../../components/DesignStandardize";
import { apiUrl } from "../../../ApiUrlAndToken";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  photo: Yup.mixed().required("Photo is required"),
});

const AddNewEmployee = () => {
  const [photo, setPhoto] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      photo: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("password", values.password);
        formData.append("photo", values.photo);

        const response = await axios.post(
          `${apiUrl}/users/register`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        toast.success(`New Employee Registered`);
        setTimeout(() => {
          window.location.href = "/admin/employee-list";
        }, 500);
      } catch (error) {
        // Error handling logic remains the same
      }
    },
  });

  const handlePhotoChange = (event) => {
    setPhoto(event.currentTarget.files[0]);
    formik.setFieldValue("photo", event.currentTarget.files[0]);
  };

  return (
    <form
      className="flex flex-col gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={formik.handleSubmit}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <div>
          <GeneralInput
            label="Employee Name"
            id="name"
            name="name"
            type="text"
            placeholder="Enter Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-red-500 font-light">{formik.errors.name}</div>
          ) : null}
        </div>

        <div>
          <GeneralInput
            label="Employee Email"
            id="email"
            name="email"
            type="email"
            placeholder="Enter Employee Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500 font-light">{formik.errors.email}</div>
          ) : null}
        </div>

        <div>
          <GeneralInput
            label="Employee Picture"
            id="photo"
            name="photo"
            type="file"
            accept="image/*"
            onChange={handlePhotoChange}
          />
          {formik.touched.photo && formik.errors.photo ? (
            <div className="text-red-500 font-light">{formik.errors.photo}</div>
          ) : null}
        </div>
        <div>
          <GeneralInput
            label="Employee Password"
            id="password"
            name="password"
            type="password"
            placeholder="Enter Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="text-red-500 font-light">
              {formik.errors.password}
            </div>
          ) : null}
        </div>
      </div>

      <button className={`${submitButton} mt-3`} type="submit">
        Add
      </button>
    </form>
  );
};

export default AddNewEmployee;
