import React, { useState, useEffect } from "react";
import {
  apiUrl,
  isAdminLoggedIn,
  isEmployeeLoggedIn,
} from "../../../ApiUrlAndToken";
import axios from "axios";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LocationIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { Link } from "react-router-dom";

const SearchEmpProjects = ({ id }) => {
  const [projects, setProjects] = useState([]);
  const [query, setQuery] = useState("");
  const [projectSuggestions, setProjectSuggestions] = useState([]);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState("");
  const [locationInputField, setLocationInputField] = useState(false);
  const [projectInputField, setProjectInputField] = useState(false);
  const [projectsByLocation, setProjectsByLocation] = useState([]);
  const handleProjectInputChange = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    if (inputValue === "") {
      setProjectSuggestions([]);
      return;
    }
    const filtered = projects.filter((project) =>
      project.ProjectName.toLowerCase().includes(inputValue.toLowerCase())
    );
    setProjectSuggestions(filtered);
  };

  const handleLocationInputChange = (e) => {
    const inputValue = e.target.value.trim();
    setQuery(inputValue);
    const filtered = projects.filter((project) =>
      project.Location.toLowerCase().includes(inputValue.toLowerCase().trim())
    );
    if (inputValue === "") {
      setLocationSuggestions([]);
      return;
    }
    const uniqueLocations = Array.from(
      new Set(filtered.map((project) => project.Location))
    );

    setLocationSuggestions(
      uniqueLocations.map((location) =>
        filtered.find((project) => project.Location === location)
      )
    );
  };

  const handleProjectSuggestionClick = (project) => {
    setQuery(project.ProjectName);
    setProjectSuggestions([]);
  };

  const handleLocationSuggestionClick = (project) => {
    setQuery(project.Location);
    setLocationSuggestions([]);
  };

  const getOnGoingProjects = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/assign/fetchProjectsByUserID/${id}`
      );
      // console.log(response.data);
      const projectsData = response.data;

      // Fetch detailed information for each project
      const projectsWithDetails = await Promise.all(
        projectsData.map(async (project) => {
          const detailedInfo = await getProjectDetails(project.ID);
          return { ...project, ...detailedInfo };
        })
      );
      // console.log(projectsWithDetails);
      setProjects(projectsWithDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectDetails = async (projectId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/project/fetchProject/${projectId}`
      );
      return response.data; // Assuming the detailed information is directly available in the response
    } catch (error) {
      console.log(error);
      return {}; // Or handle the error appropriately
    }
  };

  useEffect(() => {
    getOnGoingProjects();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const filterProject = projects.find((item) => item.ProjectName === query);
    // console.log(filterProject);
    setSelectedProjects(filterProject);
    return filterProject;
  };

  const handleLocationSubmit = (e) => {
    e.preventDefault();
    const filterProject = projects.filter((item) => item.Location === query);
    // console.log(filterProject);
    setProjectsByLocation(filterProject);
    return filterProject;
  };

  const handleClearField = (e) => {
    e.preventDefault();
    setQuery("");
    setProjectsByLocation([]);
    setSelectedProjects("");
  };

  return (
    <div className="flex flex-col">
      <div className="mb-3 flex flex-row flex-wrap gap-5 justify-center">
        <button
          onClick={() => {
            setLocationInputField(!locationInputField);
            setProjectInputField(false);
            setQuery("");
            setProjectsByLocation([]);
            setSelectedProjects("");
          }}
          className={`text-blue-400 hover:text-blue-500 underline underline-offset-2`}
        >
          Search By Project Location
        </button>
        <button
          onClick={() => {
            setProjectInputField(!projectInputField);
            setLocationInputField(false);
            setQuery("");
            setProjectsByLocation([]);
            setSelectedProjects("");
          }}
          className={`text-blue-400 hover:text-blue-500 underline underline-offset-2`}
        >
          Search By Project Name
        </button>
      </div>

      {locationInputField && !projectInputField && (
        <div className="mb-[2px] animate-bounce-once">
          <input
            type="text"
            placeholder="Search by Project Location..."
            value={query}
            className="input input-bordered w-72"
            onChange={handleLocationInputChange}
          />
          <button type="submit" className={`-ml-14`}>
            <SearchIcon onClick={handleLocationSubmit} className="mr-1" />

            <SearchOffIcon onClick={handleClearField} />
          </button>
        </div>
      )}

      {projectInputField && !locationInputField && (
        <div className="mb-[2px] animate-bounce-once">
          <input
            type="text"
            placeholder="Search by Project Name..."
            value={query}
            className="input input-bordered w-72"
            onChange={handleProjectInputChange}
          />
          <button type="submit" className={`-ml-14`}>
            <SearchIcon onClick={handleSubmit} className="mr-1" />

            <SearchOffIcon onClick={handleClearField} />
          </button>
        </div>
      )}

      {/* Display suggestions */}
      {(projectSuggestions.length > 0 || locationSuggestions.length > 0) &&
        query !== "" && (
          <div className="flex flex-col text-left p-2 gap-1 w-[292px] mx-auto bg-slate-50 rounded">
            {projectInputField ? (
              projectSuggestions.map((project) => (
                <div
                  key={project.id}
                  onClick={() => handleProjectSuggestionClick(project)}
                  className="border-b py-2 cursor-pointer text-base hover:text-blue-500 hover:bg-slate-100"
                >
                  {project.ProjectName}
                </div>
              ))
            ) : locationInputField ? (
              locationSuggestions.map((project) => (
                <div
                  key={project.id}
                  onClick={() => handleLocationSuggestionClick(project)}
                  className="border-b py-2 cursor-pointer text-base hover:text-blue-500 hover:bg-slate-100"
                >
                  {project.Location}
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        )}

      {selectedProjects && (
        <div
          className={`text-left p-4 w-60 border-[1px] border-solid rounded-xl bg-slate-100 flex flex-col gap-3 hover:shadow mb-2 mx-auto`}
        >
          {selectedProjects.Upload ? (
            <Link
              to={`/employee/${selectedProjects.ProjectName}/${selectedProjects.ProjectID}`}
            >
              <img
                className={`w-full h-32 hover:cursor-pointer rounded-xl`}
                src={`${apiUrl}/uploads/${selectedProjects.Upload}`}
                alt="Profile"
              />
            </Link>
          ) : (
            <Link
              to={`/employee/${selectedProjects.ProjectName}/${selectedProjects.ProjectID}`}
              className={`w-full h-32 bg-[#a1a0a6] rounded-xl`}
            />
          )}

          <div className="flex justify-between gap-3 border-b-[1px] border-solid pb-1">
            <p className="text-xl font-semibold hover:cursor-pointer w-28 truncate">
              <Link
                to={`/employee/${selectedProjects.ProjectName}/${selectedProjects.ProjectID}`}
              >
                {selectedProjects.ProjectName}
              </Link>
            </p>
            <div
              className={`hover:cursor-pointer rounded-full text-slate-900 hover:text-black hover:bg-slate-300 p-[2px] hover:translate-x-1 transition-all duration-300`}
            >
              <Link
                to={`/employee/${selectedProjects.ProjectName}/${selectedProjects.ProjectID}`}
              >
                <ArrowForwardIcon />
              </Link>
            </div>
          </div>

          <div>
            <div className="grid grid-cols-2">
              <p className="text-sm text-slate-400">Start Date</p>
              <p>: {selectedProjects.Start_Date?.split("T")[0]}</p>
              <p className="text-sm text-slate-400">End Date</p>
              <p>: {selectedProjects.End_Date?.split("T")[0]}</p>
            </div>
            <p className="truncate flex items-center">
              <LocationIcon fontSize="1px" />
              <p className="">{selectedProjects.Location}</p>
            </p>
            <div
              // onClick={() => ProjectDetailsModal(el)}
              className={`hover:cursor-pointe btn btn-outline btn-sm mt-3`}
            >
              <VisibilityIcon fontSize="1px" /> View Details
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col md:flex-row flex-wrap gap-4 overflow-x-auto md:overflow-x-hidden w-11/12 mx-auto">
        {projectsByLocation.map((el, i) => {
          return (
            <div
              key={i}
              className={`text-left p-4 w-60 border-[1px] border-solid rounded-xl bg-slate-100 flex flex-col gap-3 hover:shadow mb-2`}
            >
              {el.Upload ? (
                <Link to={`/employee/${el.ProjectName}/${el.ProjectID}`}>
                  <img
                    className={`w-full h-32 hover:cursor-pointer rounded-xl`}
                    src={`${apiUrl}/uploads/${el.Upload}`}
                    alt="Profile"
                  />
                </Link>
              ) : (
                <Link
                  to={`/employee/${el.ProjectName}/${el.ProjectID}`}
                  className={`w-full h-32  ${
                    i % 2 === 0 ? "bg-[#d3e0cc]" : "bg-[#a1a0a6]"
                  } rounded-xl`}
                />
              )}
              <div className="flex justify-between gap-3 border-b-[1px] border-solid pb-1">
                <p className="text-xl font-semibold hover:cursor-pointer w-28 truncate">
                  <Link to={`/employee/${el.ProjectName}/${el.ProjectID}`}>
                    {el.ProjectName}
                  </Link>
                </p>

                <div
                  className={`hover:cursor-pointer rounded-full text-slate-900 hover:text-black hover:bg-slate-300 p-[2px] hover:translate-x-1 transition-all duration-300`}
                >
                  {isAdminLoggedIn ? (
                    <Link to={`/admin/${el.ProjectName}/${el.ProjectID}`}>
                      <ArrowForwardIcon />
                    </Link>
                  ) : isEmployeeLoggedIn ? (
                    <Link to={`/employee/${el.ProjectName}/${el.ProjectID}`}>
                      <ArrowForwardIcon />
                    </Link>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div>
                <div className="grid grid-cols-2">
                  <p className="text-sm text-slate-400">Start Date</p>
                  <p>: {el.Start_Date.split("T")[0]}</p>
                  <p className="text-sm text-slate-400">End Date</p>
                  <p>: {el.End_Date.split("T")[0]}</p>
                </div>
                <p className="truncate flex items-center">
                  <LocationIcon fontSize="1px" />
                  <p className="">{el.Location}</p>
                </p>
                <div
                  // onClick={() => ProjectDetailsModal(el)}
                  className={`hover:cursor-pointe btn btn-outline btn-sm mt-3`}
                >
                  <VisibilityIcon fontSize="1px" /> View Details
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchEmpProjects;
