import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { apiUrl } from "../../../ApiUrlAndToken";
import GeneralInput from "../../../components/GeneralInput";
import GeneralSelect from "../../../components/GeneralSelect";
import { submitButton } from "../../../components/DesignStandardize";

const ProjectAdd = () => {
  const [name, setName] = useState("");
  const [details, setDetails] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [location, setLocation] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [file, setFile] = useState(null); // State to store the selected file

  const ProjectStatus = [
    { value: "Upcoming", label: "Upcoming" },
    { value: "Ongoing", label: "Ongoing" },
    { value: "Completed", label: "Completed" },
  ];

  const [sow, setSow] = useState("");
  const [itemsOfSow, setItemsOfSow] = useState([]);

  const handleClick = () => {
    if (sow.trim() !== "") {
      setItemsOfSow([...itemsOfSow, sow]);
      setSow("");
    }
  };

  const createScopeWork = async (id) => {
    try {
      for (const el of itemsOfSow) {
        const data = {
          workName: el,
          description: "",
          status: 0,
          projectId: parseInt(id),
        };

        await axios.post(`${apiUrl}/scope-works`, data);
        // console.log(response.data);
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        toast.error(`${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response received");
      } else {
        toast.error("An error occurred while processing the request");
      }
    }
  };

  const AddProjects = async (e) => {
    e.preventDefault();
    let statusValue;
    switch (selectedStatus) {
      case "Upcoming":
        statusValue = 0;
        break;
      case "Ongoing":
        statusValue = 1;
        break;
      case "Completed":
        statusValue = 2;
        break;
      default:
        statusValue = -1; // Handle invalid status
        break;
    }

    if (statusValue === -1) {
      toast.error("Invalid project status");
      return;
    }

    // Use FormData to handle file upload along with other data
    const formData = new FormData();
    formData.append("name", name);
    formData.append("details", details);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("location", location);
    formData.append("status", statusValue);
    if (file) {
      formData.append("Upload", file); // 'Upload' matches the server's expected field name
    }

    try {
      const response = await axios.post(`${apiUrl}/project/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log(response.data.projectId);
      createScopeWork(response.data.projectId);
      toast.success("Project Added Successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(`${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response received from the server");
      } else {
        toast.error("An error occurred while processing the request");
      }
    }
  };

  return (
    <div className="p-1 flex flex-col gap-3 itemsOfSow-center min-h-max max-h-[440px] overflow-y-auto mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base">
      <div className="flex flex-col md:flex-row gap-3">
        {/*  */}
        <div className="flex flex-col gap-3">
          <GeneralInput
            label="Project Name"
            type="text"
            placeholder="Enter Project Name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
          <GeneralSelect
            options={ProjectStatus}
            label="Select Project Status" // Corrected from 'lable' to 'label'
            placeholder="Upcoming/Ongoing/Complete"
            onChange={(selectedOption) =>
              setSelectedStatus(selectedOption.value)
            }
            value={
              ProjectStatus.find((option) => option.value === selectedStatus) ||
              ""
            }
          />
          <GeneralInput
            label="Project Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <GeneralInput
            label="Project End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>

        {/*  */}
        <div className="flex flex-col gap-3">
          <GeneralInput
            label="Add Image"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
          <div className="hidden">
            <GeneralInput
              label="Project Details"
              type="textarea"
              placeholder="Enter Project Details"
              value={details}
              required
              onChange={(e) => setDetails(e.target.value)}
            />
          </div>

          <GeneralInput
            label="Project Location"
            type="textarea"
            placeholder="Enter Project Location"
            value={location}
            required
            onChange={(e) => setLocation(e.target.value)}
          />

          <div>
            <GeneralInput
              label="Scope of Work"
              type="text"
              value={sow}
              onChange={(e) => setSow(e.target.value)}
              placeholder="Enter Scope of Work"
              addItems={1}
              buttonFuntion={handleClick}
            />
            <p className="text-sm text-slate-500 font-normal mx-1">
              Press Enter or + button
            </p>
            {itemsOfSow.length > 0 && (
              <ul
                className={`flex flex-row flex-wrap gap-1 w-60 bg-[#c4c4ec] -mt-7 pt-8 pb-1 px-1 rounded-b`}
              >
                {itemsOfSow.map((item, index) => (
                  <li
                    key={index}
                    className="bg-white text-black rounded-xl px-1 ring-1 font-normal"
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>

      <button
        className={`${submitButton} mt-3 mx-auto`}
        type="submit"
        onClick={AddProjects}
      >
        Submit
      </button>
    </div>
  );
};

export default ProjectAdd;
