import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../../../ApiUrlAndToken";
import AddWorkersIcon from "@mui/icons-material/GroupAdd";
import EditIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import EditContractor from "./components/EditContractor";
import AddWorkers from "../worker/components/AddWorkers";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import GeneralModal from "../../../components/GeneralModal";
import PhoneIcon from "@mui/icons-material/CallOutlined";

const Contractor = () => {
  const [contractors, setContractors] = useState([]);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const renderAvatar = (name) => {
    const firstLetter = name.charAt(0).toUpperCase();
    const color = getRandomColor();
    return (
      <div
        style={{ backgroundColor: color }}
        className="w-12 h-12 text-center text-white text-4xl font-bold rounded-2xl pt-[2px]"
      >
        {firstLetter}
      </div>
    );
  };

  const [contractorID, setContractorID] = useState(null);
  const [contractorName, setContractorName] = useState(null);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  const handleEdit = (ID, Name) => {
    setContractorName(Name);
    setContractorID(ID);
    toggleModal();
  };

  const [WorkerModal, setWorkerModal] = useState(false);
  const toggleWorkerModal = () => {
    setWorkerModal((prevShowModal) => !prevShowModal);
  };
  const AddWorker = (ID, Name) => {
    setContractorID(ID);
    setContractorName(Name);
    toggleWorkerModal();
  };

  const getContractors = async () => {
    try {
      const response = await axios.get(`${apiUrl}/contractor/getAllContractor`);
      // console.log(response.data.data);
      setContractors(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getContractors();
  }, []);

  const deleteContractor = async (id, name) => {
    alert(`Are you sure you want to remove ${name}?`);
    try {
      const response = await axios.delete(
        `${apiUrl}/contractor/deleteContracter/${id}`
      );
      toast.warning(`Contractor ${name} has been removed successfully`);
      getContractors();
    } catch (error) {
      console.log(error);
      toast.error("An error occured");
    }
  };
  return (
    <>
      <table className="mx-auto">
        <thead>
          <tr className="border-b border-slate-300 flex gap-5">
            <th className="w-12"></th>
            <th className="w-40">Name</th>
            <th className="w-72">Contact Info.</th>
            <th className="w-28">Add Workers</th>
            <th className="w-16">Edit</th>
            <th className="w-16">Delete</th>
          </tr>
        </thead>

        <tbody>
          {/* Map through your data and render each row */}
          {contractors.map((contractor, index) => (
            <tr
              key={index}
              className="border-b border-slate-300 hover:bg-slate-100 h-20 flex gap-5 items-center"
            >
              <td>{renderAvatar(contractor.name)}</td>

              <td className="w-40">
                <div className="font-bold">{contractor.name}</div>
                <div className="text-sm text-slate-400">
                  Blood Group: {contractor.bloodgroup || "Not added"}
                </div>
              </td>

              <td className="w-72">
                {contractor.address !== ""
                  ? contractor.address
                  : "Not available"}
                <br />

                {contractor.contact !== "" ? (
                  <Link
                    to={`tel:${contractor.contact}`}
                    target="_blank"
                    className="badge badge-info badge-outline hover:shadow"
                  >
                    <PhoneIcon fontSize="inherit" className="mt-1 mr-1" />

                    {contractor.contact}
                  </Link>
                ) : (
                  <p className="badge badge-info badge-outline hover:shadow">
                    -
                  </p>
                )}
              </td>

              <td className="w-28">
                <button
                  className="btn btn-ghost hover:text-green-700"
                  onClick={() => {
                    AddWorker(contractor.contractor_id, contractor.name);
                  }}
                >
                  <AddWorkersIcon />
                </button>
              </td>

              <td className="w-16">
                <button
                  className="btn btn-ghost hover:text-blue-500"
                  onClick={() =>
                    handleEdit(contractor.contractor_id, contractor.name)
                  }
                >
                  <EditIcon />
                </button>
              </td>

              <td className="w-16">
                <button
                  className="btn btn-ghost hover:text-red-500"
                  onClick={() => {
                    deleteContractor(contractor.contractor_id, contractor.name);
                  }}
                >
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Edit Contractor */}
      {modal && contractorID && contractorName && (
        <GeneralModal title={`Edit ${contractorName}`} onClose={toggleModal}>
          <EditContractor
            contractorID={contractorID}
            onCancel={() => setContractorID(null)}
          />
        </GeneralModal>
      )}

      {/* Add Worker */}
      {WorkerModal && contractorID && contractorName && (
        <GeneralModal
          title={`Add Workers for ${contractorName}`}
          onClose={toggleWorkerModal}
        >
          <AddWorkers
            contractorID={contractorID}
            onCancel={() => setContractorID(null)}
          />
        </GeneralModal>
      )}
    </>
  );
};

export default Contractor;
