import React from "react";
import { apiUrl, isAdminLoggedIn } from "../../../../ApiUrlAndToken";
import { toast } from "react-toastify";
import axios from "axios";

const UpdateStatusImageReport = ({ MR_ID, MR_Status }) => {
  const handleStatusChange = async (status) => {
    try {
      const data = {
        ReqStatus: status, // Change 'status' to 'ReqStatus'
      };

      const response = await axios.put(
        `${apiUrl}/report/update-reqstatus/${MR_ID}`,
        data // Send 'data' object instead of 'status' directly
      );

      console.log("data:", response.data);

      // Show success or error toast based on the response
      if (status === 1) {
        toast.success("Material Report has been approved.");
      } else {
        toast.error("Material Report has been denied.");
      }

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      toast.error("An error has occurred!");
      console.log(error);
    }
  };

  return (
    <>
      {isAdminLoggedIn && MR_Status === 0 && (
        <div className="flex gap-3 justify-center">
          <button
            className="btn btn-sm btn-success hover:text-white"
            onClick={() => {
              handleStatusChange(1);
            }}
          >
            Approve
          </button>

          <button
            className="btn btn-sm btn-error hover:text-white"
            onClick={() => {
              handleStatusChange(2);
            }}
          >
            Disapprove
          </button>
        </div>
      )}
    </>
  );
};

export default UpdateStatusImageReport;
