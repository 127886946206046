import React, { useEffect, useState } from "react";
import { submitButton } from "../../../../components/DesignStandardize";
import GeneralInput from "../../../../components/GeneralInput";
import { apiUrl } from "../../../../ApiUrlAndToken";
import axios from "axios";
import { toast } from "react-toastify";

const ImageRequest = ({ userID, project }) => {
  const [file, setFile] = useState(null); // State to store the selected file

  const [assignId, setAssignId] = useState(null);
  let projectID = parseInt(project);

  useEffect(() => {
    const fetchAssignId = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/assign/fetchAssignID/${userID}/${projectID}`
        );
        // console.log(response.data);
        const [assignIdFromResponse] = response.data;

        setAssignId(assignIdFromResponse);
      } catch (error) {
        console.error("Error fetching assign ID:", error);
      }
    };

    fetchAssignId();
  }, [userID, projectID]);

  const AddImageRequest = async (e) => {
    e.preventDefault();

    // Use FormData to handle file upload along with other data
    const formData = new FormData();
    formData.append("ProjectID", project);
    formData.append("AssignID", assignId);
    formData.append("ReqStatus", 0);

    if (file) {
      formData.append("Picture", file);
    }

    console.log("formData", formData);
    try {
      const response = await axios.post(`${apiUrl}/request/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log("response:", response.data);

      toast.success("Material Request Placed Successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(`${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response received from the server");
      } else {
        toast.error("An error occurred while processing the request");
      }
    }
  };

  return (
    <div className="p-1 flex flex-col gap-3 itemsOfSow-center min-h-max max-h-[440px] overflow-y-auto mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base">
      <GeneralInput
        label="Add Image for Request"
        type="file"
        onChange={(e) => setFile(e.target.files[0])}
      />

      <button
        className={`${submitButton} mt-3 mx-auto`}
        type="submit"
        onClick={AddImageRequest}
      >
        Submit
      </button>
    </div>
  );
};

export default ImageRequest;
