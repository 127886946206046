import React, { useEffect, useState } from "react";
import GeneralInput from "../../../components/GeneralInput";
import GeneralSelect from "../../../components/GeneralSelect";
import { submitButton } from "../../../components/DesignStandardize";
import axios from "axios";
import { apiUrl } from "../../../ApiUrlAndToken";
import { toast } from "react-toastify";

const EditProject = ({ projectID }) => {
  const [data, setData] = useState({
    ProjectName: "",
    Details: "",
    Start_Date: "",
    End_Date: "",
    Location: "",
    Status: "",
  });
  const [selectedStatus, setSelectedStatus] = useState("");
  const [file, setFile] = useState(null); // State for file

  useEffect(() => {
    const getProjectInfo = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/project/fetchProject/${projectID}`
        );
        setData(response.data);
        setSelectedStatus(mapStatus(response.data.Status));
      } catch (error) {
        console.log("No data Present");
      }
    };
    getProjectInfo();
  }, [projectID]);

  const mapStatus = (status) => {
    switch (status) {
      case "1":
        return "Ongoing";
      case "0":
        return "Upcoming";
      case "2":
        return "Completed";
      default:
        return "";
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      // Check if a file is selected
      setFile(e.target.files[0]); // Update file state
    }
  };

  const EditProjects = async (e) => {
    e.preventDefault();

    try {
      let statusChanged = false;
      let detailsChanged = false;
      if (selectedStatus) {
        await editStatus();
        statusChanged = true;
      }
      const formattedData = {
        ...data,
        Start_Date: data.Start_Date.split("T")[0],
        End_Date: data.End_Date.split("T")[0],
      };

      const formData = new FormData();
      formData.append(
        "ProjectName",
        formattedData.ProjectName || data.ProjectName
      );
      formData.append("Details", formattedData.Details || data.Details);
      formData.append(
        "Start_Date",
        formattedData.Start_Date || data.Start_Date
      );
      formData.append("End_Date", formattedData.End_Date || data.End_Date);
      formData.append("Location", formattedData.Location || data.Location);
      formData.append("Status", selectedStatus);

      // Append file to form data only if it exists and is not null
      if (file !== null) {
        formData.append("file", file);
      } else {
        // Append existing file from data if file is null or not selected
        formData.append("file", data.file);
      }

      const response = await axios.put(
        `${apiUrl}/project/updateDetails/${projectID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status !== 201) {
        detailsChanged = true;
      }

      if (statusChanged && detailsChanged) {
        toast.success("Overall Details Updated Successfully!!");
      } else if (statusChanged) {
        toast.success("Status Got Updated Successfully!!");
      } else if (detailsChanged) {
        toast.success("Project Details Updated Successfully!!");
      } else {
        toast.info("No modification.");
      }

      // Reload the page after a delay
      if (window.location.pathname === "/admin/dashboard") {
        window.location.href = `/admin/dashboard`;
      } else if (window.location.pathname === `/`) {
        window.location.href = `/admin/dashboard`;
      } else {
        setTimeout(() => {
          window.location.href = `/admin/${data.ProjectName}/${data.ProjectID}`;
        }, 500);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error("Start date cannot be after end date.");
      }
    }
  };

  const editStatus = async () => {
    try {
      let statusvalue;
      switch (selectedStatus) {
        case "Upcoming":
          statusvalue = 0;
          break;
        case "Ongoing":
          statusvalue = 1;
          break;
        case "Completed":
          statusvalue = 2;
          break;
        default:
          statusvalue = -1;
          break;
      }
      const data = {
        status: statusvalue,
      };
      await axios.put(`${apiUrl}/project/updateStatus/${projectID}`, data);
    } catch (error) {
      console.log(error);
    }
  };

  const ProjectStatus = [
    { value: "Upcoming", label: "Upcoming" },
    { value: "Ongoing", label: "Ongoing" },
    { value: "Completed", label: "Completed" },
  ];

  return (
    <form
      className="p-1 flex flex-col gap-3 items-center min-h-max max-h-[440px] overflow-y-auto mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={EditProjects}
    >
      <div className="flex flex-col md:flex-row gap-3">
        <div className="flex flex-col gap-3">
          <GeneralInput
            label="Project Name"
            type="text"
            placeholder="Enter Project Name"
            value={data.ProjectName}
            required
            onChange={(e) => {
              setData({ ...data, ProjectName: e.target.value });
            }}
          />
          <GeneralSelect
            options={ProjectStatus}
            label="Select Project Status"
            placeholder="Upcoming/Ongoing/Complete"
            value={ProjectStatus.find((el) => el.value === selectedStatus)}
            onChange={(selectedOption) =>
              setSelectedStatus(selectedOption.value)
            }
          />

          <GeneralInput
            label="Project Image"
            id="Photo"
            name="Photo"
            type="file"
            onChange={handleFileChange}
            accept="image/*"
          />
        </div>

        <div className="flex flex-col gap-3">
          {/* <GeneralInput
            label="Project Details"
            type="textarea"
            placeholder="Enter Project Details"
            value={data.Details}
            required
            onChange={(e) => {
              setData({ ...data, Details: e.target.value });
            }}
          /> */}

          <GeneralInput
            label="Project Start Date"
            type="date"
            placeholder="Enter Project Start Date"
            value={data.Start_Date ? data.Start_Date.split("T")[0] : ""}
            onChange={(e) => {
              setData({ ...data, Start_Date: e.target.value.split("T")[0] });
            }}
          />
          <GeneralInput
            label="Project End Date"
            type="date"
            placeholder="Enter Project End Date"
            value={data.End_Date ? data.End_Date.split("T")[0] : ""}
            onChange={(e) => {
              setData({ ...data, End_Date: e.target.value.split("T")[0] });
            }}
          />
          <GeneralInput
            label="Project Location"
            type="textarea"
            placeholder="Enter Project location"
            value={data.Location}
            required
            onChange={(e) => {
              setData({ ...data, Location: e.target.value });
            }}
          />
        </div>
      </div>
      <button className={`${submitButton} mt-3`}>Update</button>
    </form>
  );
};

export default EditProject;
