import React, { useState } from "react";
import { hoverScale } from "../../../../components/DesignStandardize";
import AddIcon from "@mui/icons-material/PostAddOutlined";
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import CreateRequest from "./CreateRequest";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { isEmployeeLoggedIn } from "../../../../ApiUrlAndToken";
import RequestList from "./RequestList";
import GeneralSelect from "../../../../components/GeneralSelect";
import Pending from "./Pending";
import Approved from "./Approved";
import DisApproved from "./DisApproved";
import ImageRequest from "./ImageRequest";
import GeneralModal from "../../../../components/GeneralModal";

const MaterialRequest = ({ project, userID }) => {
  const [addRequest, setAddRequest] = useState(false);
  const toggleAddRequestModal = () => {
    setAddRequest((prevShowModal) => !prevShowModal);
  };

  const [addRequestImage, setAddRequestImage] = useState(false);
  const toggleAddRequestImageModal = () => {
    setAddRequestImage((prevShowModal) => !prevShowModal);
  };

  const [status, setStatus] = useState("All");
  const Status = [
    { value: "All", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Disapproved", label: "Disapproved" },
  ];

  const renderComponentByStatus = () => {
    switch (status) {
      case "All":
        return <RequestList project={project} userID={userID} />;
      case "Pending":
        return (
          <Pending
            project={project}
            status={status === "Pending" && 0}
            userID={userID}
          />
        );
      case "Approved":
        return (
          <Approved
            project={project}
            status={status === "Approved" && 1}
            userID={userID}
          />
        );
      case "Disapproved":
        return (
          <DisApproved
            project={project}
            status={status === "Disapproved" && 2}
            userID={userID}
          />
        );
      default:
        return <RequestList project={project} userID={userID} />;
    }
  };
  return (
    <>
      <div className="border-[1px] border-slate-300 hover:border-slate-400 rounded-lg lg:p-2 min-w-96">
        <div className="flex gap-2 justify-between p-2 md:p-0 border-b-[1px] border-slate-400">
          <p className="text-lg text-info font-semibold">Request</p>{" "}
          {isEmployeeLoggedIn && (
            <div className="flex gap-2 px-2">
              <div
                className={`${hoverScale}`}
                onClick={toggleAddRequestModal}
                // data-tip={"Add Task"}
              >
                <AddIcon />
              </div>
              <div
                className={`${hoverScale}`}
                onClick={toggleAddRequestImageModal}
              >
                <UploadIcon className="text-sm" />
              </div>
            </div>
          )}
        </div>
        {/* <div className="flex justify-center p-2 md:p-0 md:mb-3"> */}
        <GeneralSelect
          options={Status}
          placeholder="Pending/Aprroved/Disapproved"
          onChange={(selectedOption) => setStatus(selectedOption.value)}
          value={Status.find((el) => el.value === status) || null}
        />
        {/* </div> */}
        {renderComponentByStatus()}
      </div>

      {/* Modal to add material request */}
      {addRequest && (
        <GeneralModal
          title="Add - Material Request"
          onClose={toggleAddRequestModal}
        >
          <CreateRequest project={project} userID={userID} />
        </GeneralModal>
      )}

      {/* Modal to add material request Image */}
      {addRequestImage && (
        <GeneralModal
          title="Add - Material Request"
          onClose={toggleAddRequestImageModal}
        >
          <ImageRequest project={project} userID={userID} />
        </GeneralModal>
      )}
    </>
  );
};

export default MaterialRequest;
