import React, { useEffect, useState } from "react";
import MaterialRequest from "./request/MaterialRequest";
import MaterialReport from "./report/MaterialReport";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../../pageTitle/PageTitle";
import bg from "../../../assets/img/bg.avif";
import { apiUrl, isEmployeeLoggedIn } from "../../../ApiUrlAndToken";
import axios from "axios";

const MaterialRequisition = () => {
  const id = useParams();
  const userID = parseInt(localStorage.getItem("userId"));
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState("");
  const getProjectByID = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/project/fetchProject/${id.ProjectID}`
      );
      setProject(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (id.ProjectID) getProjectByID();
  }, [id.ProjectID]);
  const titles = `${id.ProjectName} - ${
    parseInt(project.Status) === 0
      ? "Upcoming"
      : parseInt(project.Status) === 1
      ? "Ongoing"
      : parseInt(project.Status) === 2
      ? "Completed"
      : ""
  }`;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <div className="overflow-y-hidden">
      <PageTitle title={titles} insideProject={1} />

      <div
        className="pt-16 lg:pt-0 md:ml-40 flex flex-col gap-6 font-Inter h-screen overflow-y-hidden overflow-x-auto"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {loading ? (
          <div
            className={`mt-16 animate-spin rounded-full h-16 w-16 border-t-4 ${
              parseInt(project.Status) === 0
                ? "border-blue-500"
                : parseInt(project.Status) === 1
                ? "border-orange-500"
                : "border-green-500"
            }  border-solid m-auto`}
          />
        ) : (
          <div>
            <div className=" mb-3 w-max mx-auto mt-12 md:mt-14 flex">
              <Link
                to={`/${isEmployeeLoggedIn ? "employee" : "admin"}/${
                  id.ProjectName
                }/${id.ProjectID}`}
                className={`px-2 pb-1 border-b-2 border-slate-400 hover:border-slate-700`}
              >
                Scope of Work
              </Link>
              <p className={`font-bold border-b-2 border-black px-2 pb-1`}>
                Material Requisition
              </p>
              <Link
                to={`/${isEmployeeLoggedIn ? "employee" : "admin"}/workflow/${
                  id.ProjectName
                }/${id.ProjectID}`}
                className={`px-2 pb-1 border-b-2 border-slate-400 hover:border-slate-700`}
              >
                Workflow
              </Link>
            </div>

            <div className="border-[1px] border-slate-300 rounded-lg p-2 min-w-[780px] font-Inter">
              <p className="font-semibold text-xl text-primary text-center pb-2 border-b-[1px] border-slate-300">
                Material Requisition
              </p>
              <div className="grid grid-cols-2 gap-1 pt-1 min-w-[770px]">
                <MaterialRequest project={id.ProjectID} userID={userID} />
                <MaterialReport project={id.ProjectID} userID={userID} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MaterialRequisition;
