import React, { useEffect, useState } from "react";
import GeneralInput from "../../../../components/GeneralInput";
import { submitButton } from "../../../../components/DesignStandardize";
import axios from "axios";
import { apiUrl } from "../../../../ApiUrlAndToken";
import { toast } from "react-toastify";

const CreateReport = ({ userID, requestID }) => {
  const [materials, setMaterials] = useState([]);

  const getRequestInfo = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/materialReq/getAllByMRID/${requestID}`
      );
      //   console.log(response.data.RequestedItems);

      // Extract ItemNames and AmountExpected from the response data
      const items = response.data.RequestedItems.map((item, index) => ({
        id: index + 1,
        RequestedItemID: item.RequestedItemID,
        ItemName: item.ItemName,
        AmountExpected: item.AmountExpected,
        ExpectedDate: item.ExpectedDate,
        Thickness: "",
        SIZE: "",
        AmountGood: "",
        ReportDate: new Date().toISOString().split("T")[0],
        Quality: "",
        Remarks: "",
      }));

      //   console.log(items);

      setMaterials(items);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRequestInfo();
  }, [requestID]);

  const handleInputChange = (id, updatedField, value) => {
    const rowIndex = materials.findIndex((row) => row.id === id);
    if (rowIndex !== -1) {
      const updatedRows = [...materials];
      updatedRows[rowIndex][updatedField] = value;
      setMaterials(updatedRows);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Check if any required fields are empty for any item
      const hasEmptyFields = materials.some(
        (el) =>
          el.AmountGood === "" ||
          el.Thickness === "" ||
          el.SIZE === "" ||
          el.Quality === ""
      );

      if (hasEmptyFields) {
        toast.error("Required fields cannot be empty!");
        return;
      }

      // If no required fields are empty, proceed with submitting data
      for (const el of materials) {
        const data = {
          RequestedItemID: el.RequestedItemID,
          ReportDate: el.ReportDate,
          Remarks: el.Remarks,
          AmountGood: el.AmountGood,
          Thickness: el.Thickness,
          Size: el.SIZE,
          Quality: el.Quality,
        };

        const response = await axios.post(
          `${apiUrl}/QCReport/createQCReport`,
          data
        );
        console.log("response:", response.data);
      }
      toast.success("New report has been created successfully");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(`${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response has been received from the server.");
      }
    }
  };

  return (
    <>
      <div className="overflow-x-auto max-w-[320px] md:max-w-[520px] lg:max-w-[800px]">
        <table className="">
          <thead>
            <tr className="text-base text-black text-center">
              <th className="p-1">Item Name</th>
              <th className="p-1">Thickness</th>
              <th className="p-1">Size</th>
              <th className="p-1">Amount Expected</th>
              <th className="p-1">Amount Received</th>
              <th className="p-1">Expected Date</th>
              <th className="p-1">Arrival Date</th>
              <th className="p-1">Quality</th>
              <th className="p-1">Remarks</th>
            </tr>
          </thead>
          <tbody>
            {materials.map((row) => (
              <tr key={row.RequestedItemID}>
                <td className="p-0">
                  <div
                    className={`px-3 py-1 text-sm flex-1 h-10 bg-transparent border w-40 mt-2 cursor-not-allowed bg-slate-300`}
                  >
                    <p className="mt-1">{row.ItemName}</p>
                  </div>
                </td>

                <td className="p-0">
                  <GeneralInput
                    type="text"
                    placeholder="Thickness"
                    value={row.Thickness}
                    onChange={(e) =>
                      handleInputChange(row.id, "Thickness", e.target.value)
                    }
                    table={1}
                  />
                </td>

                <td className="p-0">
                  <GeneralInput
                    type="text"
                    placeholder="Size"
                    value={row.SIZE}
                    onChange={(e) =>
                      handleInputChange(row.id, "SIZE", e.target.value)
                    }
                    table={1}
                  />
                </td>

                <td className="p-0">
                  <div
                    className={`px-3 py-1 text-sm flex-1 h-10 bg-transparent border w-40 mt-2 cursor-not-allowed bg-slate-300`}
                  >
                    <p className="mt-1">{row.AmountExpected}</p>
                  </div>
                </td>

                <td className="p-0">
                  <GeneralInput
                    type="number"
                    placeholder="Received Amount"
                    value={row.AmountGood}
                    onChange={(e) =>
                      handleInputChange(row.id, "AmountGood", e.target.value)
                    }
                    table={1}
                    min={0}
                  />
                </td>

                <td className="p-0">
                  <div
                    className={`px-3 py-1 text-sm flex-1 h-10 bg-transparent border w-40 mt-2 cursor-not-allowed bg-slate-300`}
                  >
                    <p className="mt-1">{row.ExpectedDate}</p>
                  </div>
                </td>

                <td className="p-0">
                  <GeneralInput
                    type="date"
                    value={row.ReportDate}
                    onChange={(e) =>
                      handleInputChange(row.id, "ReportDate", e.target.value)
                    }
                    table={1}
                  />
                </td>

                <td className="p-0">
                  <GeneralInput
                    type="text"
                    placeholder="Quality"
                    value={row.Quality}
                    onChange={(e) =>
                      handleInputChange(row.id, "Quality", e.target.value)
                    }
                    table={1}
                  />
                </td>
                <td className="p-0">
                  <GeneralInput
                    type="text"
                    placeholder="Remarks"
                    value={row.Remarks}
                    onChange={(e) =>
                      handleInputChange(row.id, "Remarks", e.target.value)
                    }
                    table={1}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button className={`${submitButton} mt-3 mx-auto`} onClick={handleSubmit}>
        Submit
      </button>
    </>
  );
};

export default CreateReport;
