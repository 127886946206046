import React, { useState } from "react";
import { submitButton } from "../../../../components/DesignStandardize";
import GeneralInput from "../../../../components/GeneralInput";
import axios from "axios";
import { apiUrl, isAdminLoggedIn } from "../../../../ApiUrlAndToken";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const AddTarget = ({ scopeOfWorks }) => {
  const id = useParams();

  // Filter scopeOfWorks to include only objects with Status=1
  const filteredWorks = scopeOfWorks.filter((work) => work.Status === 1);

  // Initialize rows state with default values for filtered works
  const initialRows = filteredWorks.map((work) => ({
    WorkID: work.WorkID,
    targetValue: 0,
  }));

  // Initialize state for rows
  const [rows, setRows] = useState(initialRows);

  // Update targetValue in rows state when input changes
  const handleInputChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index].targetValue = parseFloat(value);
    setRows(updatedRows);
  };

  const handleAdd = async () => {
    try {
      for (const row of rows) {
        const data = {
          workID: row.WorkID,
          amount: row.targetValue,
          date: new Date().toISOString().split("T")[0],
        };
        // console.log(data);
        const response = await axios.post(
          `${apiUrl}/workflow/createTarget`,
          data
        );
        // console.log(response.data);
      }
      toast.success("Target Work has been created successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 500);
      // console.log("Rows data:", rows);
      // console.log("scopeOfWorks:", scopeOfWorks);
    } catch (error) {
      console.log(error);
      // if(error.response){
      //   toast.error(`${error.response}`)
      // }
    }
  };

  return (
    <>
      {filteredWorks.length > 0 ? (
        <>
          <div className="overflow-x-auto max-w-[320px] md:max-w-[520px] lg:max-w-[800px]">
            <table>
              <thead>
                <tr className="text-base text-black text-center">
                  <th className="p-1">Scope of Work</th>

                  <th className="p-1">Today's Expected</th>
                </tr>
              </thead>
              <tbody>
                {filteredWorks.map((work, index) => (
                  <tr key={work.WorkID}>
                    <td className="p-0">
                      <div
                        className={`px-3 py-1 text-sm flex-1 h-10 bg-transparent border w-full mt-2 cursor-not-allowed bg-slate-300`}
                      >
                        <p className="mt-1 text-left">{work.Work_Name}</p>
                      </div>
                    </td>

                    <td className="p-0">
                      <GeneralInput
                        type="number"
                        value={(rows[index] && rows[index].targetValue) || 0}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                        table={1}
                        min={0}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            className={`${submitButton} mt-3 mx-auto`}
            onClick={handleAdd}
          >
            Add
          </button>
        </>
      ) : (
        <>
          <p className="font-semibold text-lg text-success animate-pulse">
            Please add Scope of Work first!
          </p>

          <Link
            to={`/${isAdminLoggedIn ? "admin" : "employee"}/${id.ProjectName}/${
              id.ProjectID
            }`}
            className={`w-max mx-auto hover:cursor-pointer rounded-full text-green-600 hover:btn-warning btn btn-outline gap-0`}
          >
            Add Scope of Work <ArrowForwardIcon fontSize="small" />
          </Link>
        </>
      )}
    </>
  );
};

export default AddTarget;
