import React, { useState } from "react";
import PageTitle from "../pageTitle/PageTitle";
import { hoverScale, submitButton } from "../../components/DesignStandardize";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import AddNewEmployee from "./components/AddNewEmployee";
import EmployeeList from "./components/EmployeeList";
import { isAdminLoggedIn } from "../../ApiUrlAndToken";
import EngineerIcon from "@mui/icons-material/Engineering";
import GeneralModal from "../../components/GeneralModal";

const Employee = () => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  return (
    <>
      <PageTitle title="Employee" />
      <div className="my-12 md:ml-40 p-4">
        <EmployeeList />
      </div>

      {/* Button to add new employee */}
      <>
        {isAdminLoggedIn && (
          <div
            className={`w-max hidden md:block fixed bottom-2 right-2 hover:cursor-pointer font-Inter ${submitButton} ${hoverScale}`}
            onClick={toggleModal}
          >
            <EngineerIcon className="text-sm" /> Add Employee
          </div>
        )}
        {isAdminLoggedIn && (
          <div
            className={`w-max fixed bottom-2 left-2 md:hidden hover:cursor-pointer font-Inter ${submitButton} ${hoverScale}`}
            onClick={toggleModal}
          >
            <EngineerIcon className="text-sm" /> Add Employee
          </div>
        )}
      </>

      {/* To Register New Employee */}
      {modal && (
        <GeneralModal title="Add New Employee" onClose={toggleModal}>
          <AddNewEmployee />
        </GeneralModal>
      )}
    </>
  );
};

export default Employee;
