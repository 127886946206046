import React, { useState } from "react";
import axios from "axios";
import { apiUrl } from "../../../ApiUrlAndToken";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import { submitButton } from "../../../components/DesignStandardize";
import GeneralSelect from "../../../components/GeneralSelect";

const ScopeWorkCreate = ({ id }) => {
  const [workName, setWorkName] = useState("");
  const [area, setArea] = useState(0);
  const [description, setDescription] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const SoWStatus = [
    { value: "Upcoming", label: "Upcoming" },
    { value: "Ongoing", label: "Ongoing" },
    { value: "Completed", label: "Completed" },
  ];

  const createScopeWork = async (ev) => {
    ev.preventDefault();
    let statusValue;
    switch (selectedStatus) {
      case "Upcoming":
        statusValue = 0;
        break;
      case "Ongoing":
        statusValue = 1;
        break;
      case "Completed":
        statusValue = 2;
        break;
      default:
        statusValue = -1;
        break;
    }
    if (statusValue === -1) {
      toast.error("Invalid project status");
      return;
    }

    try {
      const data = {
        workName: workName,
        // area: area,
        description: description,
        sqrft: area || 0,
        status: statusValue,
        projectId: parseInt(id),
      };

      const response = await axios.post(`${apiUrl}/scope-works`, data);
      // console.log("response.status", response.data);
      toast.success(`Scope work created successfully`);
      setTimeout(() => {
        window.location.reload();
      }, 200);
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        toast.error(`${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response received");
      } else {
        toast.error("An error occurred while processing the request");
      }
    }
  };

  return (
    <form
      className="flex flex-col gap-3 items-center mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={createScopeWork}
    >
      <GeneralInput
        label="Title:"
        type="text"
        placeholder="Write a scope of work"
        value={workName}
        onChange={(ev) => setWorkName(ev.target.value)}
        required
      />

      <GeneralInput
        label="Total Area (sq ft):"
        type="number"
        placeholder="Total square feet"
        value={area}
        onChange={(ev) => setArea(ev.target.value)}
        min={0}
        required
      />

      <GeneralInput
        label="Description:"
        type="textarea"
        placeholder="Write Description"
        value={description}
        onChange={(ev) => setDescription(ev.target.value)}
        required
      />

      <GeneralSelect
        label="Status:"
        options={SoWStatus}
        placeholder="Upcoming/Ongoing/Complete"
        onChange={(selectedOption) => setSelectedStatus(selectedOption.value)}
        value={SoWStatus.find((el) => el.value === selectedStatus) || null}
      />

      <button className={`${submitButton} mt-1`} type="submit">
        Add
      </button>
    </form>
  );
};

export default ScopeWorkCreate;
