import React, { useEffect, useState } from "react";
import { submitButton } from "../../../../components/DesignStandardize";
import GeneralInput from "../../../../components/GeneralInput";
import axios from "axios";
import { apiUrl } from "../../../../ApiUrlAndToken";
import { toast } from "react-toastify";

const EditItem = ({ itemId }) => {
  const [data, setData] = useState({
    ItemName: "",
    AmountExpected: "",
    ExpectedDate: "",
    Thickness: "",
    SIZE: "",
    REMARKS: "",
  });

  const { ItemName, AmountExpected, ExpectedDate, Thickness, SIZE, REMARKS } =
    data;
  const getIndividualItem = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/requestedItem/fetchItemByID/${itemId}`
      );

      setData(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getIndividualItem();
  }, [itemId]);

  const updateItemInfo = async (e) => {
    e.preventDefault();
    // Check if any of the required fields are empty
    if (
      !ItemName ||
      !String(AmountExpected).trim() ||
      !ExpectedDate ||
      !Thickness ||
      !SIZE
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }
    data["remarks"] = data["REMARKS"];
    delete data["REMARKS"];

    try {
      const response = await axios.put(
        `${apiUrl}/requestedItem/updateRequestedItemInformation/${itemId}`,
        data
      );
      // console.log(response.data.message);
      toast.success("Updated Information Successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.request.statusText);
      }
    }
  };

  return (
    <form
      className="flex flex-col gap-3 items-center mx-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={updateItemInfo}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 items-center max-h-96 h-max overflow-y-auto p-1">
        <GeneralInput
          type="text"
          label="Item Name"
          placeholder="Enter Item Name"
          value={ItemName}
          onChange={(ev) => setData({ ...data, ItemName: ev.target.value })}
          required
        />
        <GeneralInput
          type="text"
          label="Thickness"
          value={Thickness}
          onChange={(ev) => setData({ ...data, Thickness: ev.target.value })}
          required
        />
        <GeneralInput
          type="text"
          label="Size"
          value={SIZE}
          onChange={(ev) => setData({ ...data, SIZE: ev.target.value })}
          required
        />
        <GeneralInput
          label="Expected Amount"
          type="number"
          placeholder="Enter Amount"
          value={AmountExpected}
          onChange={(ev) =>
            setData({ ...data, AmountExpected: ev.target.value })
          }
          required
        />
        <GeneralInput
          label="Expected Date"
          type="date"
          placeholder="Enter Expected Date"
          value={ExpectedDate}
          onChange={(ev) => setData({ ...data, ExpectedDate: ev.target.value })}
          required
        />

        <GeneralInput
          label="Remarks"
          type="textarea"
          placeholder="Enter Remarks"
          value={REMARKS}
          onChange={(ev) => setData({ ...data, REMARKS: ev.target.value })}
        />
      </div>
      <button className={`${submitButton} mt-3`}>Update Details</button>
    </form>
  );
};

export default EditItem;
