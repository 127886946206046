import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { apiUrl } from "../../../ApiUrlAndToken";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import { submitButton } from "../../../components/DesignStandardize";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const EmployeeLogin = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        // Send a POST request to your backend for user login
        const response = await axios.post(`${apiUrl}/users/login`, values);
        // console.log(response.data.token);
        // Handle success, e.g., save the employeeToken to local storage or redirect
        localStorage.setItem("employeeToken", response.data.token);
        localStorage.setItem("userId", response.data.userId);

        toast.success("Successful Login");
        setTimeout(() => {
          window.location.href = "/employee/projects";
        }, 500);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Handle 401 status for invalid credentials
          toast.error(
            "Login unsuccessful. Please check your email and password."
          );
        } else {
          // Handle other errors (e.g., network issues)
          toast.error(`Error: ${error}`);
        }
      }
    },
  });

  return (
    <div className="flex flex-col gap-3">
      <p className="text-xl text-blue-900 font-medium">Employee Login</p>
      <div className="card w-96 skeleton border-2 border-solid shadow-md">
        <div className="card-body p-5">
          <form
            className="flex flex-col gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
            onSubmit={formik.handleSubmit}
          >
            <div>
              <GeneralInput
                label="Employee Email"
                id="email"
                name="email"
                type="email"
                placeholder="Enter Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div>{formik.errors.email}</div>
              ) : null}
            </div>

            <div>
              <GeneralInput
                label="Password"
                id="password"
                name="password"
                type="password"
                placeholder="Enter Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <div>{formik.errors.password}</div>
              ) : null}
            </div>

            <button className={`${submitButton} mt-3`} type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmployeeLogin;
