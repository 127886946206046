import React, { useEffect, useState } from "react";
import profileDefault from "../../../assets/img/icons/profile.png";
import { hoverScale } from "../../../components/DesignStandardize";
import EditIcon from "@mui/icons-material/BorderColor";
import EditEmployee from "../../employee/components/EditEmployee";
import axios from "axios";
import { apiUrl } from "../../../ApiUrlAndToken";
import GeneralModal from "../../../components/GeneralModal";

const EmployeeSessionMenu = () => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };

  const [user, setUser] = useState([]);
  const [loginInfo, setLoginInfo] = useState(localStorage.getItem("userId"));

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await axios.get(`${apiUrl}/users/${loginInfo}`);
        setUser(response.data);
      } catch (error) {}
    };
    if (loginInfo) {
      getUserInfo();
    }
    return () => setLoginInfo(null);
  }, [loginInfo]);

  // console.log("user:", user);

  return (
    <>
      <div className="dropdown dropdown-bottom dropdown-end">
        <img
          tabIndex={0}
          className={`w-8 h-8 hover:cursor-pointer hover:shadow-lg rounded-full mt-1 ${hoverScale}`}
          src={user.Photo ? `${apiUrl}/uploads/${user.Photo}` : profileDefault}
          alt="Profile"
        />

        <div
          tabIndex={0}
          className="dropdown-content z-[1] menu p-3 shadow bg-base-100 rounded-box w-64 flex flex-col gap-3"
        >
          <div className="flex flex-row justify-between gap-1">
            <img
              tabIndex={0}
              className={`w-20 h-20 shadow rounded-full ${hoverScale}`}
              src={
                user.Photo ? `${apiUrl}/uploads/${user.Photo}` : profileDefault
              }
              alt="Profile"
            />

            <div
              className={`mb-auto hover:cursor-pointer flex items-center justify-center rounded-full text-black ${hoverScale}`}
              onClick={toggleModal}
            >
              <EditIcon fontSize="1px" />
            </div>
          </div>
          <div className="flex flex-col gap-3 font-semibold text-left">
            <p className="">Name: {user.Name && user.Name}</p>
            {/* <p className="">Employee ID: {user.UserID && user.UserID}</p> */}
            <p className="">Email: {user.Email && user.Email}</p>
          </div>
        </div>
      </div>

      {/* modal update employee details */}
      {modal && (
        <GeneralModal title="Update Profile" onClose={toggleModal}>
          <EditEmployee userId={user.UserID} />
        </GeneralModal>
      )}
    </>
  );
};

export default EmployeeSessionMenu;
