import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const WorkflowPie = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    let chartInstance = null;

    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      if (chartInstance) {
        chartInstance.destroy(); // Destroy the existing chart instance
      }

      chartInstance = new Chart(ctx, {
        type: "pie",
        data: {
          labels: data.labels,
          datasets: [
            {
              data: data.values,
              backgroundColor: data.colors,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          // Add any additional options here
        },
      });
    }

    return () => {
      // Cleanup function to destroy the chart instance when the component unmounts
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [data]);

  return (
    <div className="h-60 w-60 mx-auto">
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default WorkflowPie;
