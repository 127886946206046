import React from "react";
import GetImageReportByStatus from "./GetImageReportByStatus";
import GetReportByStatus from "./GetReportByStatus";
const DisApprovedReport = ({ project, status, userID }) => {
  return (
    <>
      <div className="min-w-96 overflow-x-auto h-screen">
        <div className="my-2">
          <GetReportByStatus
            project={project}
            status={status}
            userID={userID}
          />

          <GetImageReportByStatus
            project={project}
            status={status}
            userID={userID}
          />
        </div>
        <div className="mb-72" />
      </div>
    </>
  );
};

export default DisApprovedReport;
