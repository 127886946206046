import React, { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../../ApiUrlAndToken";

const DownloadWorkQualityReport = ({ project }) => {
  const id = useParams();
  const [workQualityReport, setWorkQualityReport] = useState([]);
  const getWorkQualityReportByProjectID = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/work-qualities/project/${parseInt(id.ProjectID)}`
      );
      setWorkQualityReport(
        response.data.map((item) => ({
          "কাজের নাম": item.WorkName,
          "সম্পূর্ণ কৃতকাজের পরিমাণ - স্কয়ার ফিট হিসেবে": item.TotalWork,
          "এলাইনমেন্ট সঠিক কিনা":
            item.Allignment === 2
              ? "-"
              : item.Allignment === 1
              ? "ভালো"
              : "ভালো নয়",
          "এক্সাসরিস সঠিকভাবে লাগানো হয়েছে কিনা":
            item.Accessories === 2
              ? "-"
              : item.Accessories === 1
              ? "ভালো"
              : "ভালো নয়",
          "সিলিকন আউট সাইট সঠিক":
            item.SiliconOut === 2
              ? "-"
              : item.SiliconOut === 1
              ? "ভালো"
              : "ভালো নয়",
          "সিলিকন ইন সাইট সঠিক":
            item.SiliconIn === 2
              ? "-"
              : item.SiliconIn === 1
              ? "ভালো"
              : "ভালো নয়",
          আচরণ: item.Behaviour,
          "মন্তব্য (যদি থাকে)": item.Coment,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = () => {
    // Convert data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(workQualityReport);

    // Set column widths
    const columnWidths = [
      { wch: 10 },
      { wch: 40 },
      { wch: 20 },
      { wch: 35 },
      { wch: 25 },
      { wch: 25 },
      { wch: 10 },
      { wch: 40 },
    ];

    worksheet["!cols"] = columnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Work Quality Report");

    // Generate a download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(
      blob,
      `${id.ProjectName}_StartDate ${project.Start_Date}_Location ${project.Location} work_quality_report.xlsx`
    );
  };

  useEffect(() => {
    getWorkQualityReportByProjectID();
  }, [id.ProjectID]);
  return (
    <button
      onClick={handleDownload}
      className="border border-black hover:bg-success rounded hover:text-white"
    >
      <DownloadIcon />
    </button>
  );
};

export default DownloadWorkQualityReport;
