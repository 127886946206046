import React, { useEffect, useState } from "react";
import PageTitle from "../pageTitle/PageTitle";
import Ongoing from "./components/Ongoing";
import Completed from "./components/Completed";
import UpComing from "./components/UpComing";
import SearchEmpProjects from "./components/SearchEmpProjects";

const EmpProjects = () => {
  const [loading, setLoading] = useState(true);
  const userID = parseInt(localStorage.getItem("userId"));
  // const userID = par
  // console.log(typeof userID);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      <PageTitle title="Assigned Projects" />

      <div className="my-14 md:my-16 md:ml-40 flex flex-col gap-6 font-Inter">
        {loading ? (
          <div className="mt-5 animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid m-auto" />
        ) : (
          <>
            <SearchEmpProjects id={userID} />
            <Ongoing id={userID} />
            <Completed id={userID} />
            <UpComing id={userID} />
          </>
        )}
      </div>
    </>
  );
};

export default EmpProjects;
