import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../../../../ApiUrlAndToken";
import { useParams } from "react-router-dom";
import WorkflowPie from "../../workflow/components/WorkflowPie";
// import ExcelJS from "exceljs";
// import { saveAs } from "file-saver";

const ScopeWorkDetails = () => {
  const { scope_work_id } = useParams();
  const [sow, setSow] = useState();
  const [workflowData, setWorkflowData] = useState();

  useEffect(() => {
    const fetchScopeWork = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/scope-works/${scope_work_id}`
        );
        setSow(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const getAllWorks = async () => {
      try {
        const [resTarget, resComplete] = await Promise.all([
          axios.get(
            `${apiUrl}/workflow/getTargetIDWithoutDate/${scope_work_id}`
          ),
          axios.get(
            `${apiUrl}/workflow/getCompletedWorksWithoutDate/${scope_work_id}`
          ),
        ]);

        const targetWork = resTarget.data.map((sow) => ({
          targetId: sow.targetworkflow_id,
          todayTarget: sow.amount,
          date: sow.date,
        }));

        const completeWork = resComplete.data
          .map((sow, index) => {
            if (sow.targetworkflow_id === targetWork[index].targetId) {
              return {
                ...targetWork[index],
                todayComplete: sow.amount,
              };
            }
            return undefined;
          })
          .filter((item) => item !== undefined);

        setWorkflowData(completeWork);
      } catch (error) {
        console.error(error);
      }
    };

    fetchScopeWork();
    getAllWorks();
  }, [scope_work_id]);

  //   const downloadRecord = async () => {
  //     const workbook = new ExcelJS.Workbook();
  //     const worksheet = workbook.addWorksheet("ScopeOfWork");

  //     // Add columns with headers and specify widths
  //     worksheet.columns = [
  //       { header: "Date", key: "Date", width: 15 },
  //       { header: "Target", key: "Target", width: 30 },
  //       { header: "Completed", key: "Completed", width: 30 },
  //     ];

  //     // Add rows with filtered data
  //     workflowData.forEach((entry) => {
  //       worksheet.addRow({
  //         Date: entry.date,
  //         todayTarget: entry.todayTarget,
  //         todayComplete: entry.todayComplete,
  //       });
  //     });

  //     // Set text wrapping for all cells
  //     worksheet.eachRow((row, rowNumber) => {
  //       row.eachCell((cell, colNumber) => {
  //         cell.alignment = { wrapText: true };
  //       });
  //     });

  //     const fileName = `Project_${ProjectName}_${scope_work_name}.xlsx`;

  //     const buffer = await workbook.xlsx.writeBuffer();
  //     const blob = new Blob([buffer], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     saveAs(blob, fileName);
  //   };
  return (
    <div className="flex flex-row gap-5 p-4 justify-center">
      {sow && (
        <div className="flex flex-col gap-2 w-max h-max text-left p-4 border rounded-lg shadow text-[#0D1829B2] bg-gradient-to-b from-green-100 to-blue-100">
          <p className="w-60">
            Name: <span className="font-semibold">{sow.Work_Name}</span>
          </p>
          <p className="w-60">
            Description:{" "}
            <span className="font-semibold">
              {sow.Description === "" ? "-" : sow.Description}
            </span>
          </p>
          <p className="w-60">
            Total Area:{" "}
            <span className="font-semibold">
              {sow.sqrfoot === null ? (
                <p className="text-red-700 animate-pulse font-bold">
                  Please add total area
                </p>
              ) : (
                sow.sqrfoot
              )}
            </span>{" "}
            sq ft
          </p>
        </div>

        //   {workflowData.length > 0 && (
        //     <button onClick={downloadRecord} className="light-button ml-auto">
        //       Download Record
        //     </button>
        //   )}
      )}
      <div className="flex flex-col gap-5 overflow-x-auto h-screen">
        {workflowData && (
          <table className="mx-auto">
            <thead>
              <tr className="text-white text-lg border-b border-slate-300 bg-green-800">
                <th className="p-1 border-x border-slate-300">Date</th>
                <th className="p-1 w-60 border-x border-slate-300">
                  Statistics
                </th>
              </tr>
            </thead>
            <tbody className="text-left border border-slate-300">
              {workflowData.map((el, i) => {
                const chartData = {
                  labels: [
                    `Completed Work (${el.todayComplete} sq ft)`,
                    `Target Work (${el.todayTarget} sq ft)`,
                  ],
                  values: [el.todayComplete, el.todayTarget],
                  colors: ["blue", "#87ceeb"],
                };
                return (
                  <tr key={i} className="border-b border-slate-300">
                    <td className="p-2 border-r border-slate-300">{el.date}</td>
                    <td className="p-2 w-60">
                      <WorkflowPie data={chartData} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        <div className="mb-24" />
      </div>
    </div>
  );
};

export default ScopeWorkDetails;
