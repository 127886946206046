import React, { useState } from "react";
import DownloadMaterialReport from "./DownloadMaterialReport";
import ReportList from "./ReportList";
import GeneralSelect from "../../../../components/GeneralSelect";
import PendingReport from "./PendingReport";
import ApprovedReport from "./ApprovedReport";
import DisApprovedReport from "./DisApprovedReport";

const MaterialReport = ({ project, userID }) => {
  const [status, setStatus] = useState("All");
  const Status = [
    { value: "All", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Disapproved", label: "Disapproved" },
  ];
  const renderComponentByStatus = () => {
    switch (status) {
      case "All":
        return <ReportList project={project} userID={userID} />;
      case "Pending":
        return (
          <PendingReport
            project={project}
            status={status === "Pending" && 0}
            userID={userID}
          />
        );
      case "Approved":
        return (
          <ApprovedReport
            project={project}
            status={status === "Approved" && 1}
            userID={userID}
          />
        );
      case "Disapproved":
        return (
          <DisApprovedReport
            project={project}
            status={status === "Disapproved" && 2}
            userID={userID}
          />
        );
      default:
        return <ReportList project={project} userID={userID} />;
    }
  };

  return (
    <>
      <div className="border-[1px] border-slate-300 hover:border-slate-400 rounded-lg lg:p-2 min-w-96">
        <div className="flex gap-2 justify-between p-2 md:p-0 border-b-[1px] border-slate-400">
          <p className="text-lg text-[#8a4ee4] font-semibold">Report</p>{" "}
          <DownloadMaterialReport project={project} userID={userID} />
        </div>

        <GeneralSelect
          options={Status}
          placeholder="Pending/Aprroved/Disapproved"
          onChange={(selectedOption) => setStatus(selectedOption.value)}
          value={Status.find((el) => el.value === status) || null}
        />

        {renderComponentByStatus()}
      </div>
    </>
  );
};

export default MaterialReport;
