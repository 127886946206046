import React, { useEffect, useState } from "react";
import { apiUrl, isEmployeeLoggedIn } from "../../../../ApiUrlAndToken";
import EditIcon from "@mui/icons-material/BorderColor";
import axios from "axios";
import UpdateImageReport from "./UpdateImageReport";
import UpdateStatusImageReport from "./UpdateStatusImageReport";
import GeneralModal from "../../../../components/GeneralModal";
import ImageModal from "../../../../components/ImageModal";
import { handleDownloadImageRequest } from "../request/RequestList";
import DownloadIcon from "@mui/icons-material/CloudDownloadOutlined";

const GetImageReportByStatus = ({ project, status, userID }) => {
  const [materialsImage, setMaterialsImage] = useState([]);

  const getAllImageRequestsByStatus = async () => {
    try {
      const response = await axios.get(`${apiUrl}/report/project/${project}`);

      const imageReports = response.data;

      // Filter imageRequests to include only items with ReqStatus equal to status
      const filteredRequests = imageReports.filter(
        (el) => el.ReqStatus === status
      );

      // Fetch user information for each AssignID
      const userPromises = filteredRequests.map(async (imageReports) => {
        try {
          const userResponse = await axios.get(
            `${apiUrl}/assign/fetchUserIDName/${imageReports.AssignID}`
          );
          return {
            ...imageReports,
            userName: userResponse.data.Name,
            UserID: userResponse.data.UserID,
          };
        } catch (error) {
          console.log("Error fetching user info:", error);
          return { ...imageReports, user: null }; // Handle error case
        }
      });

      // Wait for all user information requests to complete
      const imageRequestsWithUser = await Promise.all(userPromises);

      setMaterialsImage(imageRequestsWithUser);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllImageRequestsByStatus();
  }, [project]);

  // edit image report
  const [editImgReportId, setEditImgReportId] = useState(null);
  const [editImgRequestId, setEditImgRequestId] = useState(null);
  const [editImgReportModal, setEditImgReportModal] = useState(false);
  const toggleEditImgReportModal = () => {
    setEditImgReportModal((prevShowModal) => !prevShowModal);
  };
  const handleEditImgRequest = (reportId, requestId) => {
    setEditImgReportId(reportId);
    setEditImgRequestId(requestId);
    toggleEditImgReportModal();
  };

  const [modalImageUrl, setModalImageUrl] = useState(null);

  const openModal = (imageUrl) => {
    setModalImageUrl(imageUrl);
  };

  const closeModal = () => {
    setModalImageUrl(null);
  };
  return (
    <>
      {materialsImage.map((material, index) => (
        <div
          key={index}
          className={`w-full border-[1px] border-solid rounded-lg flex flex-col gap-3 hover:shadow hover:cursor-pointer p-2 mt-3 ${
            material.ReqStatus === 0
              ? "bg-slate-50"
              : material.ReqStatus === 1
              ? "bg-green-100"
              : "bg-red-100"
          }`}
        >
          <div className="flex gap-3">
            <div className="flex flex-col gap-1 text-left flex-1">
              <p>
                Report for Request ID: Image
                <span className="font-semibold">{material.id}</span>
              </p>
              <p>
                Request from:{" "}
                <span className="font-semibold">{material.userName}</span>
              </p>
            </div>

            {/* Status badge */}
            <div
              className={`badge ${
                material.ReqStatus === 0
                  ? "badge-info"
                  : material.ReqStatus === 1
                  ? "badge-success"
                  : "badge-error"
              } gap-2 ml-auto`}
            >
              {material.ReqStatus === 0
                ? "Pending"
                : material.ReqStatus === 1
                ? "Approved"
                : "Disapproved"}
            </div>

            {/* Button to download the request */}
            <button
              className="mb-auto"
              onClick={() => handleDownloadImageRequest(material.Picture)}
            >
              <DownloadIcon
                className="hover:text-green-500 text-black"
                fontSize="small"
              />
            </button>

            {/* Button to edit the request */}
            {material.UserID === userID &&
              isEmployeeLoggedIn &&
              material.ReqStatus === 0 &&
              status === 0 && (
                <button
                  className="mb-auto"
                  onClick={() => {
                    handleEditImgRequest(material.id, material.RequestId);
                  }}
                >
                  <EditIcon
                    className="hover:text-blue-500 text-black"
                    fontSize="inherit"
                  />
                </button>
              )}
          </div>

          {status === 0 && (
            <UpdateStatusImageReport
              MR_ID={material.id}
              MR_Status={material.ReqStatus}
            />
          )}

          {material.Picture && (
            <img
              src={`${apiUrl}/uploads/${material.Picture}`}
              alt="RequestdMaterials"
              onClick={() => openModal(`${apiUrl}/uploads/${material.Picture}`)}
            />
          )}
        </div>
      ))}

      {/* modal to edit image report */}
      {editImgReportModal && editImgReportId && editImgRequestId && (
        <GeneralModal
          title="Edit Image Report"
          onClose={toggleEditImgReportModal}
        >
          <UpdateImageReport
            project={project}
            userID={userID}
            reportId={editImgReportId}
            requestId={editImgRequestId}
          />
        </GeneralModal>
      )}

      {modalImageUrl && (
        <ImageModal
          imageUrl={modalImageUrl}
          onClose={closeModal}
          title="Material Report"
        />
      )}
    </>
  );
};

export default GetImageReportByStatus;
