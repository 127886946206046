import React, { useEffect, useState } from "react";
import { apiUrl, isEmployeeLoggedIn } from "../../../../ApiUrlAndToken";
import EditIcon from "@mui/icons-material/BorderColor";
import axios from "axios";
import { hoverScale } from "../../../../components/DesignStandardize";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import CreateImageReport from "../report/CreateImageReport";
import AddMRRIcon from "@mui/icons-material/Addchart";
import UpdateImageRequest from "./UpdateImageRequest";
import { handleDownloadImageRequest } from "./RequestList";
import DownloadIcon from "@mui/icons-material/CloudDownloadOutlined";
import ImageModal from "../../../../components/ImageModal";
import GeneralModal from "../../../../components/GeneralModal";

const GetImageRequestByStatus = ({ project, status, userID }) => {
  // console.log(status);
  const [materialsImage, setMaterialsImage] = useState([]);

  const getAllImageRequests = async () => {
    try {
      const response = await axios.get(`${apiUrl}/request/project/${project}`);

      const imageRequests = response.data;

      // Filter imageRequests to include only items with ReqStatus equal to status
      const filteredRequests = imageRequests.filter(
        (el) => el.ReqStatus === status
      );

      // Fetch user information for each AssignID
      const userPromises = filteredRequests.map(async (imageRequest) => {
        try {
          const userResponse = await axios.get(
            `${apiUrl}/assign/fetchUserIDName/${imageRequest.AssignID}`
          );
          return {
            ...imageRequest,
            userName: userResponse.data.Name,
            UserID: userResponse.data.UserID,
          };
        } catch (error) {
          console.log("Error fetching user info:", error);
          return { ...imageRequest, user: null }; // Handle error case
        }
      });

      // Wait for all user information requests to complete
      const imageRequestsWithUser = await Promise.all(userPromises);

      setMaterialsImage(imageRequestsWithUser);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllImageRequests();
  }, [project]);

  //add image in the report
  const [addImageReportModal, setAddImageReportModal] = useState(false);
  const [editImgRequestId, setEditImgRequestId] = useState(null);
  const toggleAddImageReportModal = () => {
    setAddImageReportModal((prevShowModal) => !prevShowModal);
  };
  const handleAddImageReport = (reportId) => {
    setEditImgRequestId(reportId);
    toggleAddImageReportModal();
  };

  const [editImgRequestModal, setEditImgRequestModal] = useState(false);
  const toggleEditImgRequestModal = () => {
    setEditImgRequestModal((prevShowModal) => !prevShowModal);
  };
  const handleEditImgRequest = (requestId) => {
    setEditImgRequestId(requestId);
    toggleEditImgRequestModal();
  };

  const [modalImageUrl, setModalImageUrl] = useState(null);

  const openModal = (imageUrl) => {
    setModalImageUrl(imageUrl);
  };

  const closeModal = () => {
    setModalImageUrl(null);
  };

  return (
    <>
      {materialsImage.map((material, index) => (
        <div
          key={index}
          className={`w-full border-[1px] border-solid rounded-lg flex flex-col gap-3 hover:shadow hover:cursor-pointer p-2 ${
            material.ReqStatus === 0
              ? "bg-slate-50"
              : material.ReqStatus === 1
              ? "bg-green-100"
              : "bg-red-100"
          }`}
        >
          <div className="flex gap-3">
            <div className="flex flex-col gap-1 text-left flex-1">
              <p>
                Request ID: Image
                <span className="font-semibold">{material.id}</span>
              </p>
              <p>
                Request from:{" "}
                <span className="font-semibold">{material.userName}</span>
              </p>
            </div>

            <div className="flex flex-col gap-1">
              <div className="flex gap-3">
                {/* Status badge */}
                <div
                  className={`badge ${
                    material.ReqStatus === 0
                      ? "badge-info"
                      : material.ReqStatus === 1
                      ? "badge-success"
                      : "badge-error"
                  } gap-2 ml-auto`}
                >
                  {material.ReqStatus === 0
                    ? "Pending"
                    : material.ReqStatus === 1
                    ? "Approved"
                    : "Disapproved"}
                </div>

                {/* Button to download the request */}
                <button
                  onClick={() => handleDownloadImageRequest(material.Picture)}
                >
                  <DownloadIcon
                    className="hover:text-green-500 text-black"
                    fontSize="small"
                  />
                </button>
              </div>
              {/* Button to add report */}
              {material.UserID === userID &&
                isEmployeeLoggedIn &&
                material.ReqStatus === 1 && (
                  <div
                    onClick={() => {
                      handleAddImageReport(material.id);
                    }}
                    className={`btn btn-outline btn-xs text-[12px]`}
                  >
                    <AddMRRIcon fontSize="small" /> Add Report
                  </div>
                )}
            </div>

            {/* Button to edit the request */}
            {material.UserID === userID &&
              isEmployeeLoggedIn &&
              material.ReqStatus === 0 && (
                <button
                  className="mb-auto"
                  onClick={() => {
                    handleEditImgRequest(material.id);
                  }}
                >
                  <EditIcon
                    className="hover:text-blue-500 text-black"
                    fontSize="inherit"
                  />
                </button>
              )}
          </div>

          {material.Picture && (
            <img
              src={`${apiUrl}/uploads/${material.Picture}`}
              alt="RequestdMaterials"
              onClick={() => openModal(`${apiUrl}/uploads/${material.Picture}`)}
            />
          )}
        </div>
      ))}

      {/* Modal to add image report */}
      {addImageReportModal && editImgRequestId && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleAddImageReportModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold text-left">
                Add Report for <br /> Request ID Image {editImgRequestId}
              </p>
              <CloseIcon
                onClick={toggleAddImageReportModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>

            <CreateImageReport
              project={project}
              userID={userID}
              requestID={editImgRequestId}
              onCancel={() => {
                setEditImgRequestId(null);
              }}
            />
          </div>
        </div>
      )}

      {/* Modal to edit image request */}
      {editImgRequestModal && editImgRequestId && (
        <GeneralModal
          title="Edit Image Request"
          onClose={toggleEditImgRequestModal}
        >
          <UpdateImageRequest
            project={project}
            userID={userID}
            requestId={editImgRequestId}
          />
        </GeneralModal>
      )}

      {modalImageUrl && (
        <ImageModal
          imageUrl={modalImageUrl}
          onClose={closeModal}
          title="Material Request"
        />
      )}
    </>
  );
};

export default GetImageRequestByStatus;
