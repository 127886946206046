import React, { useState, useEffect } from "react";
import { submitButton } from "../../../../components/DesignStandardize";
import GeneralInput from "../../../../components/GeneralInput";
import axios from "axios";
import { toast } from "react-toastify";
import { apiUrl } from "../../../../ApiUrlAndToken";

const AddCompleted = ({ scopeOfWorks }) => {
  const filteredWorks = scopeOfWorks.filter((work) => work.Status === 1);

  // Initialize rows state with default values
  const initialRows = filteredWorks.map((work) => ({
    WorkID: work.WorkID,
    completedValue: 0,
  }));
  const [rows, setRows] = useState(initialRows);
  const [targetIDs, setTargetIDs] = useState([]);
  const date = new Date().toISOString().split("T")[0];
  const handleInputChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index].completedValue = parseFloat(value);
    setRows(updatedRows);
  };

  useEffect(() => {
    const fetchTargetIDs = async () => {
      try {
        const targetIDsArray = await Promise.all(
          filteredWorks.map(async (work) => {
            const response = await axios.get(
              `${apiUrl}/workflow/getTargetID/${work.WorkID}/${date}`
            );
            // console.log(response.data);
            // console.log(response.data[0].targetworkflow_id);
            return response.data[0].targetworkflow_id;
          })
        );
        // console.log(targetIDsArray);
        setTargetIDs(targetIDsArray);
      } catch (error) {
        console.error("Error fetching target IDs:", error);
      }
    };
    fetchTargetIDs();
  }, [filteredWorks]);

  // Handle adding completed work
  const handleAdd = async () => {
    try {
      for (let i = 0; i < rows.length; i++) {
        const data = {
          targetWorkID: targetIDs[i],
          amount: rows[i].completedValue,
          date: new Date().toISOString().split("T")[0],
        };
        // console.log(data);
        const response = await axios.post(
          `${apiUrl}/workflow/createCompleteWork`,
          data
        );
        // console.log(response.data);
      }
      toast.success("Completed Work has been created successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.error("Error adding completed work:", error);
      toast.error("Error adding completed work");
    }
  };

  return (
    <div>
      <div className="overflow-x-auto max-w-[320px] md:max-w-[520px] lg:max-w-[800px]">
        <table className="">
          <thead>
            <tr className="text-base text-black text-center">
              <th className="p-1">Scope of Work</th>
              <th className="p-1">Today's Completed</th>
            </tr>
          </thead>
          <tbody>
            {filteredWorks.map((work, index) => (
              <tr key={work.WorkID}>
                <td className="p-0">
                  <div
                    className={`px-3 py-1 text-sm flex-1 h-10 bg-transparent border w-full mt-2 cursor-not-allowed bg-slate-300`}
                  >
                    <p className="mt-1 text-left">{work.Work_Name}</p>
                  </div>
                </td>
                <td className="p-0">
                  <GeneralInput
                    type="number"
                    value={(rows[index] && rows[index].completedValue) || 0}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    table={1}
                    min={0}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button className={`${submitButton} mt-3 mx-auto`} onClick={handleAdd}>
        Add
      </button>
    </div>
  );
};

export default AddCompleted;
