import React, { useState, useEffect } from "react";
import axios from "axios";
import inProgress from "../../assets/img/icons/inProgress.jpg";
import EditIcon from "@mui/icons-material/BorderColor";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  apiUrl,
  isAdminLoggedIn,
  isEmployeeLoggedIn,
} from "../../ApiUrlAndToken";
import AddAssignedEngineer from "./components/AddAssignedEngineer";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { hoverScale } from "../../components/DesignStandardize";
import { Link } from "react-router-dom";
import EditProject from "./components/EditProject";
import LocationIcon from "@mui/icons-material/LocationOn";
import DetailsModalProject from "./components/DetailsModalProject";
import AddAssignWorker from "./components/AddAssignWorker";
import PeopleIcon from "@mui/icons-material/PeopleAltOutlined";
import EngineerIcon from "@mui/icons-material/Engineering";
import AddIcon from "@mui/icons-material/Add";
import GeneralModal from "../../components/GeneralModal";
import DeleteProject from "./components/DeleteProject";

const OngoingProjects = () => {
  const [projects, setProjects] = useState([]);
  const [projectID, setProjectID] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [project, setProject] = useState("");

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  const AddAssignedEngineerModal = (id, name) => {
    setProjectID(id);
    setProjectName(name);
    toggleModal();
  };

  const [workerModal, setWorkerModal] = useState(false);
  const toggleWorkerModal = () => {
    setWorkerModal((prevShowModal) => !prevShowModal);
  };
  const AddAssignedWorkerModal = (id, name) => {
    setProjectID(id);
    setProjectName(name);
    toggleWorkerModal();
  };

  const [editModal, setEditModal] = useState(false);
  const toggleEditModal = () => {
    setEditModal((prevShowModal) => !prevShowModal);
  };
  const EditProjectModal = (id, name) => {
    setProjectID(id);
    setProjectName(name);
    toggleEditModal();
  };

  const [detailsModal, setDetailsModal] = useState(false);
  const toggleDetailsModal = () => {
    setDetailsModal((prevShowModal) => !prevShowModal);
  };
  const ProjectDetailsModal = (project) => {
    setProject(project);
    toggleDetailsModal();
  };

  const getOnGoingProjects = async () => {
    try {
      const data = {
        status: 1,
      };
      const response = await axios.get(
        `${apiUrl}/project/fetchAllProjectByStatus?status=${data.status}`
      );

      setProjects(response.data.result);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getOnGoingProjects();
  }, []);

  const currentDate = new Date();

  return (
    <>
      <div className="text-left w-11/12 mx-auto">
        <p className="text-xl divider divider-start divider-warning h-10 font-bold">
          <img
            src={inProgress}
            alt="Ongoing"
            className="w-10 h-10 rounded-full shadow shadow-orange-200"
          />
          Ongoing Projects
        </p>

        <div className="flex flex-row flex-nowrap md:flex-wrap gap-4 overflow-x-auto md:overflow-x-hidden">
          {projects.map((el, i) => {
            // Check if end_Date is less than today's date
            const enDate = new Date(el.End_Date.split("T")[0]);
            const bgColor =
              enDate < currentDate ? "bg-red-200" : "bg-slate-100";

            return (
              <div
                key={i}
                className={`p-4 w-60 border-[1px] border-solid rounded-xl ${bgColor} flex flex-col gap-3 hover:shadow mb-2`}
              >
                <div className="flex justify-between gap-3 border-b-[1px] border-solid pb-1">
                  {/* Project Name */}
                  <p className="text-xl font-semibold hover:cursor-pointer w-36 truncate">
                    <Link to={`/admin/${el.ProjectName}/${el.ProjectID}`}>
                      {el.ProjectName}
                    </Link>
                  </p>

                  <div className="flex gap-1">
                    <DeleteProject
                      projectId={el.ProjectID}
                      projectName={el.ProjectName}
                    />
                    {/* Edit button to edit project */}
                    <div
                      onClick={() =>
                        EditProjectModal(el.ProjectID, el.ProjectName)
                      }
                      className={`hover:cursor-pointe rounded-full ${hoverScale} text-blue-600 hover:text-blue-700 `}
                    >
                      <EditIcon fontSize="1px" />
                    </div>

                    {/* Arrow to go to inside of a project */}
                    <div
                      className={`hover:cursor-pointer rounded-full text-slate-900 hover:text-black hover:bg-slate-300 p-[2px] hover:translate-x-1 transition-all duration-300`}
                    >
                      {isAdminLoggedIn ? (
                        <Link to={`/admin/${el.ProjectName}/${el.ProjectID}`}>
                          <ArrowForwardIcon />
                        </Link>
                      ) : isEmployeeLoggedIn ? (
                        <Link
                          to={`/employee/${el.ProjectName}/${el.ProjectID}`}
                        >
                          <ArrowForwardIcon />
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                {/* Image of the project */}
                {el.Uploads ? (
                  <Link to={`/admin/${el.ProjectName}/${el.ProjectID}`}>
                    <img
                      className={`w-full h-32 hover:cursor-pointer rounded-xl`}
                      src={`${apiUrl}/uploads/${el.Uploads}`}
                      alt="Profile"
                    />
                  </Link>
                ) : (
                  <Link
                    to={`/admin/${el.ProjectName}/${el.ProjectID}`}
                    className={`w-full h-32  ${
                      i % 2 === 0 ? "bg-[#a1a0a6]" : "bg-[#d3e0cc]"
                    } rounded-xl`}
                  />
                )}

                <div className="grid grid-cols-2 gap-3 border-b-[1px] border-solid pb-1">
                  <div
                    onClick={() =>
                      AddAssignedEngineerModal(el.ProjectID, el.ProjectName)
                    }
                    className={`hover:cursor-pointer rounded-full text-green-600 hover:btn-warning btn btn-outline gap-0`}
                  >
                    <EngineerIcon /> <AddIcon fontSize="small" />
                    Engineers
                  </div>

                  <div
                    onClick={() =>
                      AddAssignedWorkerModal(el.ProjectID, el.ProjectName)
                    }
                    className={`hover:cursor-pointer rounded-full text-blue-600 hover:btn-info btn btn-outline gap-0`}
                  >
                    <PeopleIcon /> <AddIcon fontSize="small" />
                    Workers
                  </div>
                </div>

                <div>
                  <div className="grid grid-cols-2">
                    <p className="text-sm text-slate-400">Start Date</p>
                    <p>: {el.Start_Date.split("T")[0]}</p>
                    <p className="text-sm text-slate-400">End Date</p>
                    <p>: {el.End_Date.split("T")[0]}</p>
                  </div>
                  {/* <p className="text-sm text-slate-400">Location: </p> */}
                  <p className="truncate flex items-center">
                    <LocationIcon fontSize="1px" />
                    <p className="">{el.Location}</p>
                  </p>
                  <div
                    onClick={() => ProjectDetailsModal(el)}
                    className={`hover:cursor-pointe btn btn-outline btn-sm mt-3`}
                  >
                    <VisibilityIcon fontSize="1px" /> View Details
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Modal to assign engineer */}
      {modal && projectID && projectName && (
        <GeneralModal
          title={`Assign Engineer to ${projectName}`}
          onClose={toggleModal}
        >
          <AddAssignedEngineer
            projectID={projectID}
            onCancel={() => setProjectID(null)}
          />
        </GeneralModal>
      )}

      {/* Modal to assign worker */}
      {workerModal && projectID && projectName && (
        <GeneralModal
          title={`Assign Worker to ${projectName}`}
          onClose={toggleWorkerModal}
        >
          <AddAssignWorker
            projectID={projectID}
            onCancel={() => setProjectID(null)}
          />
        </GeneralModal>
      )}

      {/* Modal to edit project */}
      {editModal && projectID && projectName && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleEditModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                Edit {projectName} -{" "}
                <span className="text-warning">Ongoing</span>
              </p>
              <CloseIcon
                onClick={toggleEditModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
            <EditProject projectID={projectID} />
          </div>
        </div>
      )}

      {/* Modal to see details of a project */}
      {detailsModal && project && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleDetailsModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                {project.ProjectName} -{" "}
                <span className="text-warning">Ongoing</span>
              </p>
              <CloseIcon
                onClick={toggleDetailsModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>

            <DetailsModalProject project={project} />
          </div>
        </div>
      )}
    </>
  );
};

export default OngoingProjects;
