import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl, isEmployeeLoggedIn } from "../../../../ApiUrlAndToken";
import UpdateStatusRequest from "./UpdateStatusRequest";
import EditIcon from "@mui/icons-material/BorderColor";
import EditItem from "./EditItem";
import EditRequest from "./EditRequest";
import AddMRRIcon from "@mui/icons-material/Addchart";
import CreateReport from "../report/CreateReport";
import GeneralModal from "../../../../components/GeneralModal";

const GetRequestByStatus = ({ project, status, userID }) => {
  // console.log(status);
  const [materials, setMaterials] = useState([]);
  const [assignData, setAssignData] = useState([]);

  //   edit request
  const [editRequestModal, setEditRequestModal] = useState(false);
  const [editRequestId, setEditRequestId] = useState(null);
  const toggleEditRequestModal = () => {
    setEditRequestModal((prevShowModal) => !prevShowModal);
  };
  const handleEditRequest = (requestId) => {
    setEditRequestId(requestId);
    toggleEditRequestModal();
  };

  // to edit item
  const [eachItemEditModal, setEachItemEditModal] = useState(false);
  const [editItemID, setEditItemID] = useState(null);
  const toggleModal = () => {
    setEachItemEditModal((prevShowModal) => !prevShowModal);
  };
  const handleEditItem = (itemId) => {
    setEditItemID(itemId);
    toggleModal();
  };

  // add report
  const [addReportModal, setAddReportModal] = useState(false);
  const toggleAddReportModal = () => {
    setAddReportModal((prevShowModal) => !prevShowModal);
  };
  const handleAddReport = (reportId) => {
    setEditRequestId(reportId);
    toggleAddReportModal();
  };

  const fetchAssignUserData = async () => {
    try {
      const assignDataResponse = await axios.get(
        `${apiUrl}/assign/fetchAssignIDUserID/${project}`
      );
      setAssignData(assignDataResponse.data);
    } catch (error) {
      console.error("Error fetching assign data:", error);
    }
  };

  const searchByStatus = async () => {
    try {
      const responseMaterials = await axios.get(
        `${apiUrl}/materialReq/getMAByProject_Status/${project}?status=${status}`
      );

      // Iterate over materials and add UserID
      const updatedMaterials = responseMaterials.data.requestItemArr.map(
        (material) => {
          const assignInfo = assignData.find(
            (assign) => assign.AssignID === material.AssignedID
          );
          if (assignInfo) {
            return { ...material, UserID: assignInfo.UserID };
          } else {
            return material;
          }
        }
      );

      setMaterials(updatedMaterials);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAssignUserData();
  }, [project]);

  useEffect(() => {
    if (assignData.length > 0) {
      searchByStatus();
    }
  }, [assignData, project]);

  return (
    <>
      {materials.map((material, index) => (
        <div
          key={index}
          className={`w-full border-[1px] border-solid rounded-lg flex flex-col gap-3 hover:shadow p-2 ${
            material.reqStatus === 0
              ? "bg-slate-50"
              : material.reqStatus === 1
              ? "bg-green-100"
              : "bg-red-100"
          }`}
        >
          <div className="flex gap-3">
            <div className="flex flex-col gap-1 text-left flex-1">
              <p>
                Request ID:{" "}
                <span className="font-semibold">{material.MR_ID}</span>
              </p>
              <p>
                Request from:{" "}
                <span className="font-semibold">{material.Name}</span>
              </p>
            </div>

            <div className="flex flex-col gap-1">
              {/* Status badge */}
              <div
                className={`badge ${
                  material.reqStatus === 0
                    ? "badge-info"
                    : material.reqStatus === 1
                    ? "badge-success"
                    : "badge-error"
                } gap-2 ml-auto`}
              >
                {material.reqStatus === 0
                  ? "Pending"
                  : material.reqStatus === 1
                  ? "Approved"
                  : "Disapproved"}
              </div>

              {/* Button to add report */}
              {material.UserID === userID &&
                isEmployeeLoggedIn &&
                material.reqStatus === 1 && (
                  <div
                    onClick={() => {
                      handleAddReport(material.MR_ID);
                    }}
                    className={`btn btn-outline btn-xs text-[12px]`}
                  >
                    <AddMRRIcon fontSize="small" /> Add Report
                  </div>
                )}
            </div>

            {/* Edit Request button */}
            {material.UserID === userID &&
              isEmployeeLoggedIn &&
              material.reqStatus === 0 && (
                <button
                  className="mb-auto"
                  onClick={() => {
                    handleEditRequest(material.MR_ID);
                  }}
                >
                  <EditIcon
                    className="hover:text-blue-500 text-black"
                    fontSize="inherit"
                  />
                </button>
              )}
          </div>

          <UpdateStatusRequest
            MR_ID={material.MR_ID}
            MR_Status={material.reqStatus}
          />

          <div className="overflow-x-auto">
            <table className="table text-center border border-slate-300">
              <thead>
                <tr className="font-semibold text-black border-b border-slate-300">
                  <th className="p-1 w-16">Item Name</th>
                  <th className="p-1 border-x border-slate-300">Thickness</th>
                  <th className="p-1">Size</th>
                  <th className="p-1 border-x border-slate-300">Amount</th>
                  <th className="p-1">Expected Date</th>
                  <th className="p-1 border-x border-slate-300">Remarks</th>
                  {material.UserID === userID &&
                    material.reqStatus === 0 &&
                    isEmployeeLoggedIn && <th className="p-1">Edit</th>}
                </tr>
              </thead>
              {material.Data.length > 0 &&
                material.Data.map((item, i) => (
                  <tbody>
                    <tr className="border-b border-slate-300">
                      <td className="p-1 w-16 text-left">{item.ItemName}</td>
                      <td className="p-1 border-x border-slate-300">
                        {item.Thickness}
                      </td>
                      <td className="p-1">{item.SIZE}</td>
                      <td className="p-1 border-x border-slate-300">
                        {item.AmountExpected}
                      </td>
                      <td className="p-1">{item.ExpectedDate}</td>
                      <td className="p-1 border-x border-slate-300">
                        {item.Remarks}
                      </td>
                      {material.UserID === userID &&
                        isEmployeeLoggedIn &&
                        material.reqStatus === 0 && (
                          <td className="p-1">
                            <button
                              onClick={() => {
                                handleEditItem(item.RequestedItemID);
                              }}
                            >
                              <EditIcon
                                className="hover:text-blue-500 text-black"
                                fontSize="inherit"
                              />
                            </button>
                          </td>
                        )}
                    </tr>
                  </tbody>
                ))}
            </table>
          </div>
        </div>
      ))}

      {/* modal to edit each item */}
      {eachItemEditModal && editItemID && (
        <GeneralModal title="Edit Item Details" onClose={toggleModal}>
          <EditItem itemId={editItemID} onCancel={() => setEditItemID(null)} />
        </GeneralModal>
      )}

      {/* modal to edit request */}
      {editRequestModal && editRequestId && (
        <GeneralModal title="Edit Request" onClose={toggleEditRequestModal}>
          {/* Edit Request form */}
          <EditRequest
            editID={editRequestId}
            onCancel={() => {
              setEditRequestId(null);
            }}
          />
        </GeneralModal>
      )}

      {/* modal to add report */}
      {addReportModal && editRequestId && (
        <GeneralModal
          title={`Add Report for Request ID ${editRequestId}`}
          onClose={toggleAddReportModal}
        >
          <CreateReport
            userID={userID}
            requestID={editRequestId}
            onCancel={() => {
              setEditRequestId(null);
            }}
          />
        </GeneralModal>
      )}
    </>
  );
};

export default GetRequestByStatus;
