import React, { useEffect, useState } from "react";
import { apiUrl, isEmployeeLoggedIn } from "../../../../ApiUrlAndToken";
import EditIcon from "@mui/icons-material/BorderColor";
import UpdateStatusReport from "./UpdateStatusReport";
import EditReport from "./EditReport";
import axios from "axios";
import GeneralModal from "../../../../components/GeneralModal";

const GetReportByStatus = ({ project, status, userID }) => {
  const [materials, setMaterials] = useState([]);
  const [requestedItemNames, setRequestedItemNames] = useState([]);
  const [assignData, setAssignData] = useState([]);

  // edit item
  const [eachItemEditModal, setEachItemEditModal] = useState(false);
  const [editItemID, setEditItemID] = useState(null);
  const toggleModal = () => {
    setEachItemEditModal((prevShowModal) => !prevShowModal);
  };
  const handleEditItem = (itemId) => {
    setEditItemID(itemId);
    toggleModal();
  };

  const fetchAssignUserData = async () => {
    try {
      const assignDataResponse = await axios.get(
        `${apiUrl}/assign/fetchAssignIDUserID/${project}`
      );
      setAssignData(assignDataResponse.data);
    } catch (error) {
      console.error("Error fetching assign data:", error);
    }
  };
  useEffect(() => {
    fetchAssignUserData();
  }, [project]);

  const getAllRequests = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/materialReq/getAllMRbyProjectID/${project}`
      );
      const requestItemArr = response.data.requestItemArr;

      // Fetch QC report data for each MR ID
      const promises = requestItemArr.map((material) =>
        axios.get(`${apiUrl}/QCReport/fetchQCReportByMRID/${material.MR_ID}`)
      );
      const responses = await Promise.all(promises);

      // Combine material data with QC report data and add UserID
      const materialsWithUserID = responses.flatMap((response, index) => {
        const material = requestItemArr[index];
        const filteredResult = response.data.filteredResult;
        const assignInfo = assignData.find(
          (assign) => assign.AssignID === material.AssignedID
        );
        const userID = assignInfo ? assignInfo.UserID : null;

        // Add UserID to each report in filteredResult
        const reportsWithUserID = filteredResult.map((report) => ({
          ...report,
          MR_ID: material.MR_ID,
          AssignID: material.AssignedID,
          Names: material.Name,
          UserID: userID,
        }));

        return reportsWithUserID;
      });

      const PendingResults = materialsWithUserID.filter(
        (item) => item[0]?.ApprovalStatus === status
      );

      setMaterials(PendingResults);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (assignData.length > 0) {
      getAllRequests();
    }
  }, [project, assignData]);

  useEffect(() => {
    const getRequestedItemNames = async () => {
      try {
        const requestedItemIDs = materials
          .map((obj) => (obj["0"] ? obj["0"]["RequestedItemID"] : undefined))
          .filter((id) => id !== undefined);

        let responseData = [];
        for (const el of requestedItemIDs) {
          const response = await axios.get(
            `${apiUrl}/requestedItem/fetchItemByID/${el}`
          );
          responseData = responseData.concat(
            response.data.map((el1) => ({
              itemId: el,
              ItemName: el1.ItemName,
              ExThickness: el1.Thickness,
              ExSize: el1.SIZE,
              ExAmount: el1.AmountExpected,
              ExDate: el1.ExpectedDate,
            }))
          );
        }
        setRequestedItemNames(responseData);
      } catch (error) {
        console.error("Error fetching requested item names:", error);
      }
    };

    if (materials.length > 0) {
      getRequestedItemNames();
    }
  }, [materials]);

  const groupedMaterials = materials.reduce((acc, item) => {
    const key = `${item.MR_ID}_${item.AssignID}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  return (
    <>
      {Object.entries(groupedMaterials).map(([MR_ID, reports]) => (
        <div key={MR_ID} className="flex flex-col gap-3">
          {reports.map((report, index) => (
            <div key={index}>
              {Object.values(report).map(
                (innerReport, innerIndex) =>
                  typeof innerReport === "object" && (
                    <div
                      key={innerIndex}
                      className={`w-full border-[1px] border-solid rounded-lg hover:shadow p-2 ${
                        innerReport.ApprovalStatus === 0
                          ? "bg-slate-50"
                          : innerReport.ApprovalStatus === 1
                          ? "bg-green-100"
                          : "bg-red-100"
                      }`}
                    >
                      <div className="flex mb-3">
                        <div className="flex flex-col gap-1 text-left flex-1">
                          <p>
                            Report for Request ID:{" "}
                            <span className="font-semibold">
                              {groupedMaterials[MR_ID][0].MR_ID}
                            </span>
                          </p>
                          <p>
                            Report From:{" "}
                            <span className="font-semibold">
                              {groupedMaterials[MR_ID][0].Names}
                            </span>
                          </p>
                          <p>
                            Item Name:{" "}
                            <span className="font-semibold">
                              {requestedItemNames
                                .filter(
                                  (item) =>
                                    item.itemId === innerReport.RequestedItemID
                                )
                                .map((item) => item.ItemName)}
                            </span>
                          </p>
                        </div>

                        {/* Status badge */}
                        <div
                          className={`badge ${
                            innerReport.ApprovalStatus === 0
                              ? "badge-info"
                              : innerReport.ApprovalStatus === 1
                              ? "badge-success"
                              : "badge-error"
                          } gap-2 ml-auto`}
                        >
                          {innerReport.ApprovalStatus === 0
                            ? "Pending"
                            : innerReport.ApprovalStatus === 1
                            ? "Approved"
                            : "Disapproved"}
                        </div>

                        {/* Button to edit the request */}
                        {isEmployeeLoggedIn &&
                          innerReport.ApprovalStatus === 0 &&
                          groupedMaterials[MR_ID][0].UserID === userID && (
                            <button
                              className="mb-auto"
                              onClick={() => {
                                handleEditItem(
                                  innerReport.QualityControlReportID
                                );
                              }}
                            >
                              <EditIcon
                                className="hover:text-blue-500 text-black ml-2"
                                fontSize="inherit"
                              />
                            </button>
                          )}
                      </div>

                      <UpdateStatusReport
                        QCReport_ID={innerReport.QualityControlReportID}
                        QCReport_Status={innerReport.ApprovalStatus}
                      />

                      {/* Display details for the requested item */}
                      <div className="grid grid-cols-3 border border-slate-300">
                        <div>
                          <p> - </p>
                          <p className="border-y border-slate-300">Thickness</p>
                          <p>Size</p>
                          <p className="border-y border-slate-300">Amount</p>
                          <p>Date</p>
                        </div>
                        {requestedItemNames.map((item) => {
                          if (item.itemId === innerReport.RequestedItemID) {
                            return (
                              <div
                                key={item.itemId}
                                className="border-x border-slate-300"
                              >
                                <p>Expected</p>
                                <p className="font-semibold border-y border-slate-300">
                                  {item.ExThickness}
                                </p>
                                <p className="font-semibold">{item.ExSize}</p>
                                <p className="font-semibold border-y border-slate-300">
                                  {item.ExAmount}
                                </p>
                                <p className="font-semibold">{item.ExDate}</p>
                              </div>
                            );
                          }
                        })}
                        {requestedItemNames.map((item) => {
                          if (item.itemId === innerReport.RequestedItemID) {
                            return (
                              <div key={item.itemId} className="">
                                <p>Arrived</p>

                                <p className="font-semibold border-y border-slate-300">
                                  {innerReport.Thickness}
                                </p>
                                <p className="font-semibold">
                                  {innerReport.SIZE}
                                </p>
                                <p className="font-semibold border-y border-slate-300">
                                  {innerReport.AmountGood}
                                </p>
                                <p className="font-semibold">
                                  {innerReport.ReportDate}
                                </p>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  )
              )}
            </div>
          ))}
        </div>
      ))}

      {/* modal to edit each item */}
      {eachItemEditModal && editItemID && (
        <GeneralModal title="Edit Item Details in Report" onClose={toggleModal}>
          <EditReport
            reportId={editItemID}
            onCancel={() => setEditItemID(null)}
          />
        </GeneralModal>
      )}
    </>
  );
};

export default GetReportByStatus;
