import React, { useEffect, useRef, useState } from "react";
import profileDefault from "../../../assets/img/icons/profile.png";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import axios from "axios";
import { useParams } from "react-router-dom";
import PersonRemoveIcon from "@mui/icons-material/PersonRemoveAlt1Outlined";
import { toast } from "react-toastify";
import ListContractorsWorkers from "./ListContractorsWorkers";

const InsideProjectTopNavbar = () => {
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);
  const id = useParams();

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [assignedEng, setAssignedEngineer] = useState([]);
  const getAssignedEngineerByProjects = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/assign/fetchAllUsersByProjectID/${id.ProjectID}`
      );
      // console.log(response.data);
      setAssignedEngineer(response.data);
    } catch (error) {
      console.log([]);
    }
  };

  const removeEngineer = async (userID, name) => {
    window.alert(`Are u sure you want to remove ${name}?`);
    try {
      const response = await axios.delete(
        `${apiUrl}/assign/deleteSpecificAssigned/${userID}/${id.ProjectID}`
      );
      // console.log(response.data);
      toast.success("Engineer Deleted Successfully!");
      getAssignedEngineerByProjects();
      setTimeout(() => {
        window.location.href = `/admin/${id.ProjectName}/${id.ProjectID}`;
      }, 500);
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(`${error.response.message}`);
      }
    }
  };

  useEffect(() => {
    getAssignedEngineerByProjects();
  }, [id.ProjectID]);

  return (
    <>
      <div className="flex flex-row justify-end w-full gap-1 md:gap-3">
        {/* <DownloadWorkQualityReport project={project} /> */}

        {/* List of assigned engineers */}
        <div ref={dropdownRef} className="relative inline-block font-Inter">
          <div className="avatar-group -space-x-3 rtl:space-x-reverse hover:cursor-pointer">
            <div onClick={toggleOptions} className="avatar placeholder">
              <div className="w-8 h-8 rounded-full m-auto bg-blue-500 font-semibold text-white text-[14px]">
                {assignedEng.length > 0
                  ? assignedEng[0]?.Name.charAt(0)
                  : "N/A"}
              </div>
            </div>
            {assignedEng.length > 1 && (
              <div onClick={toggleOptions} className="avatar placeholder">
                <div className="w-8 bg-neutral text-neutral-content">
                  <span>
                    +{assignedEng.length > 0 && assignedEng.length - 1}
                  </span>
                </div>
              </div>
            )}
          </div>

          <ul
            className={`absolute z-10 top-full right-0 w-max bg-blue-100 border border-white rounded-lg mt-1 p-3 ${
              showOptions ? "opacity-100 scale-y-100" : "opacity-0 scale-y-0"
            } transition-all duration-300 transform origin-top-right`}
          >
            {assignedEng.length > 0 ? (
              <>
                {assignedEng.map((option, i) => (
                  <li
                    key={i}
                    onClick={toggleOptions}
                    className="flex items-center p-2 hover:bg-blue-200 rounded-lg"
                  >
                    {isAdminLoggedIn && (
                      <div
                        className="tooltip tooltip-bottom"
                        data-tip="Remove"
                        onClick={() => {
                          removeEngineer(option.UserID, option.Name);
                        }}
                      >
                        <PersonRemoveIcon className="hover:cursor-pointer mr-3" />
                      </div>
                    )}

                    <img
                      src={profileDefault}
                      alt={option.alt}
                      className="w-6 mr-2"
                    />
                    <span className="font-semibold">{option.Name}</span>
                  </li>
                ))}
              </>
            ) : (
              <p className="font-semibold animate-pulse">
                No engineer are assigned for {id.ProjectName}
              </p>
            )}
          </ul>
        </div>

        {/* List of Cotractor and Workers */}
        <ListContractorsWorkers />
      </div>
    </>
  );
};

export default InsideProjectTopNavbar;
