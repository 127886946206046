import React, { useState, useEffect } from "react";
import axios from "axios";
import Completed from "../../../assets/img/icons/complete.jpg";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { hoverScale } from "../../../components/DesignStandardize";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { apiUrl } from "../../../ApiUrlAndToken";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LocationIcon from "@mui/icons-material/LocationOn";
import DetailsModalProject from "../../projects/components/DetailsModalProject";

const UpComing = ({ id }) => {
  // console.log(id);
  const [projects, setProjects] = useState([]);
  const getOnGoingProjects = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/assign/fetchProjectsByUserID/${id}`
      );
      // console.log(response.data);
      const projectsData = response.data;

      // Fetch detailed information for each project
      const projectsWithDetails = await Promise.all(
        projectsData.map(async (project) => {
          const detailedInfo = await getProjectDetails(project.ID);
          // console.log(detailedInfo);
          return { ...project, ...detailedInfo };
        })
      );
      const ongoingProjects = projectsWithDetails.filter(
        (item) => parseInt(item.Status) === 2
      );
      // console.log(ongoingProjects);
      setProjects(ongoingProjects);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectDetails = async (projectId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/project/fetchProject/${projectId}`
      );
      return response.data; // Assuming the detailed information is directly available in the response
    } catch (error) {
      console.log(error);
      return {}; // Or handle the error appropriately
    }
  };

  useEffect(() => {
    getOnGoingProjects();
  }, []);

  const currentDate = new Date();

  const [project, setProject] = useState("");
  const [detailsModal, setDetailsModal] = useState(false);
  const toggleDetailsModal = () => {
    setDetailsModal((prevShowModal) => !prevShowModal);
  };
  const ProjectDetailsModal = (project) => {
    setProject(project);
    toggleDetailsModal();
  };

  return (
    <>
      <div className="text-left w-11/12 mx-auto">
        <p className="text-xl divider divider-start divider-success h-10 font-bold">
          <img
            src={Completed}
            alt="Completed"
            className="w-10 h-10 rounded-full shadow shadow-blue-400"
          />
          Completed Projects
        </p>

        <div className="flex flex-row flex-nowrap md:flex-wrap gap-4 overflow-x-auto md:overflow-x-hidden">
          {projects.map((el, i) => {
            // Check if end_Date is less than today's date
            const enDate = new Date(el.End_Date.split("T")[0]);
            const bgColor =
              enDate < currentDate ? "bg-red-200" : "bg-slate-100";

            return (
              <div
                key={i}
                className={`p-4 w-60 border-[1px] border-solid bg-slate-100 rounded-xl flex flex-col gap-3 hover:shadow mb-2 hover:cursor-pointer`}
              >
                <div className="flex justify-between gap-3 border-b-[1px] border-solid pb-1">
                  <p className="text-xl font-semibold hover:cursor-pointer w-36 truncate">
                    <Link to={`/employee/${el.ProjectName}/${el.ProjectID}`}>
                      {el.ProjectName}
                    </Link>
                  </p>
                  <div
                    className={`hover:cursor-pointer rounded-full text-slate-900 hover:text-black hover:bg-slate-300 p-[2px] hover:translate-x-1 transition-all duration-300`}
                  >
                    <Link to={`/employee/${el.ProjectName}/${el.ProjectID}`}>
                      <ArrowForwardIcon />
                    </Link>
                  </div>
                </div>

                {el.Uploads ? (
                  <Link to={`/employee/${el.ProjectName}/${el.ProjectID}`}>
                    <img
                      className={`w-full h-32 hover:cursor-pointer rounded-xl`}
                      src={`${apiUrl}/uploads/${el.Uploads}`}
                      alt="Profile"
                    />
                  </Link>
                ) : (
                  <Link
                    to={`/employee/${el.ProjectName}/${el.ProjectID}`}
                    className={`w-full h-32  ${
                      i % 2 === 0 ? "bg-[#d3e0cc]" : "bg-[#a1a0a6]"
                    } rounded-xl`}
                  />
                )}

                <div>
                  <div className="grid grid-cols-2">
                    <p className="text-sm text-slate-400">Start Date</p>
                    <p>: {el.Start_Date.split("T")[0]}</p>
                    <p className="text-sm text-slate-400">End Date</p>
                    <p>: {el.End_Date.split("T")[0]}</p>
                  </div>
                  <p className="truncate flex items-center">
                    <LocationIcon fontSize="1px" />
                    <p className="">{el.Location}</p>
                  </p>
                  <div
                    onClick={() => ProjectDetailsModal(el)}
                    className={`hover:cursor-pointe btn btn-outline btn-sm mt-3`}
                  >
                    <VisibilityIcon fontSize="1px" /> View Details
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Modal to see details of a project */}
      {detailsModal && project && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleDetailsModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                {project.ProjectName} -{" "}
                <span className="text-success">Completed</span>
              </p>
              <CloseIcon
                onClick={toggleDetailsModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>

            <DetailsModalProject project={project} />
          </div>
        </div>
      )}
    </>
  );
};

export default UpComing;
