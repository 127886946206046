import React, { useEffect, useState } from "react";
import { submitButton } from "../../../components/DesignStandardize";
import axios from "axios";
import { apiUrl } from "../../../ApiUrlAndToken";
import GeneralInput from "../../../components/GeneralInput";
import { toast } from "react-toastify";

const AddWorkQualityReport = ({ workID, projectId, workName }) => {
  const [totalWork, setTotalWork] = useState("");
  const [behaviour, setBehaviour] = useState("");
  const [comment, setComment] = useState("");
  const [Current_date, setCurrent_Date] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [workQuality, setWorkQuality] = useState({
    Allignment: "",
    Accesories: "",
    SiliconIn: "",
    SiliconOut: "",
  });

  const CreateReport = async (ev) => {
    ev.preventDefault();
    try {
      const data = {
        WorkName: workName,
        ProjectID: parseInt(projectId),
        WorkID: workID,
        Allignment: workQuality.Allignment
          ? workQuality.Allignment === "Good"
            ? 1
            : 0
          : 2,
        Accesories: workQuality.Accesories
          ? workQuality.Accesories === "Good"
            ? 1
            : 0
          : 2,
        SiliconIn: workQuality.SiliconIn
          ? workQuality.SiliconIn === "Good"
            ? 1
            : 0
          : 2,
        SiliconOut: workQuality.SiliconOut
          ? workQuality.SiliconOut === "Good"
            ? 1
            : 0
          : 2,
        Current_Date: new Date().toISOString().split("T")[0],
        TotalWork: totalWork,
        Behaviour: behaviour,
        Comment: comment,
      };
      if (data.Behaviour) {
        // const response =
        await axios.post(`${apiUrl}/work-qualities`, data);
        toast.success(
          `Work Quality Report is created successfully for ${workName}`
        );
        setTimeout(() => {
          window.location.reload();
        }, 200);
      } else {
        toast.error(`Please Fill Up the Required Fields.`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleWorkQualityChange = (wq, value) => {
    if (workQuality[wq] === value) {
      setWorkQuality((prev) => ({ ...prev, [wq]: "" }));
    } else {
      setWorkQuality((prev) => ({ ...prev, [wq]: value }));

      const oppositeValue = value === "Good" ? "Bad" : "Good";
      const otherWQ = `WQ${wq.substring(2)}`;
      if (workQuality[otherWQ] === oppositeValue) {
        setWorkQuality((prev) => ({ ...prev, [otherWQ]: "" }));
      }
    }
  };
  return (
    <form
      onSubmit={CreateReport}
      className="flex flex-col gap-3 items-center mx-auto font-semibold font-Inter text-sm text-left md:text-base"
    >
      <div className="flex flex-row flex-wrap gap-3 w-64 md:w-max max-h-96 overflow-y-auto justify-center p-1">
        <div className="flex flex-col gap-3 md:border-r-[2px] md:pr-3">
          <GeneralInput
            label="সম্পূর্ণ কৃতকাজের পরিমাণ - স্কয়ার ফিট হিসেবে"
            type="text"
            placeholder="..."
            value={totalWork}
            onChange={(ev) => setTotalWork(ev.target.value)}
          />

          <GeneralInput
            label="আচরণ"
            type="textarea"
            placeholder="..."
            value={behaviour}
            onChange={(ev) => setBehaviour(ev.target.value)}
          />

          <GeneralInput
            label="মন্তব্য (যদি থাকে)"
            type="textarea"
            placeholder="..."
            value={comment}
            onChange={(ev) => setComment(ev.target.value)}
          />
        </div>

        <div className="w-60 flex flex-col gap-3">
          <div>
            <p className="font-semibold">এলাইনমেন্ট সঠিক কিনা</p>
            <div className="flex gap-3 justify-between">
              <div
                className="cursor-pointer label"
                onClick={() => handleWorkQualityChange("Allignment", "Good")}
              >
                <span className="label-text mr-2">ভালো</span>
                <input
                  type="checkbox"
                  className="checkbox checkbox-success"
                  checked={workQuality.Allignment === "Good"}
                  readOnly
                />
              </div>

              <div
                className="cursor-pointer label"
                onClick={() => handleWorkQualityChange("Allignment", "Bad")}
              >
                <span className="label-text mr-2">ভালো নয়</span>
                <input
                  type="checkbox"
                  className="checkbox checkbox-error"
                  checked={workQuality.Allignment === "Bad"}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div>
            <p className="font-semibold">
              এক্সাসরিস সঠিকভাবে লাগানো হয়েছে কিনা
            </p>
            <div className="flex gap-3 justify-between">
              <div
                className="cursor-pointer label"
                onClick={() => handleWorkQualityChange("Accesories", "Good")}
              >
                <span className="label-text mr-2">ভালো</span>
                <input
                  type="checkbox"
                  className="checkbox checkbox-success"
                  checked={workQuality.Accesories === "Good"}
                  readOnly
                />
              </div>

              <div
                className="cursor-pointer label"
                onClick={() => handleWorkQualityChange("Accesories", "Bad")}
              >
                <span className="label-text mr-2">ভালো নয়</span>
                <input
                  type="checkbox"
                  className="checkbox checkbox-error"
                  checked={workQuality.Accesories === "Bad"}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div>
            <p className="font-semibold">সিলিকন আউট সাইট সঠিক</p>
            <div className="flex gap-3 justify-between">
              <div
                className="cursor-pointer label"
                onClick={() => handleWorkQualityChange("SiliconOut", "Good")}
              >
                <span className="label-text mr-2">ভালো</span>
                <input
                  type="checkbox"
                  className="checkbox checkbox-success"
                  checked={workQuality.SiliconOut === "Good"}
                  readOnly
                />
              </div>

              <div
                className="cursor-pointer label"
                onClick={() => handleWorkQualityChange("SiliconOut", "Bad")}
              >
                <span className="label-text mr-2">ভালো নয়</span>
                <input
                  type="checkbox"
                  className="checkbox checkbox-error"
                  checked={workQuality.SiliconOut === "Bad"}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div>
            <p className="font-semibold">সিলিকন ইন সাইট সঠিক</p>

            <div className="flex gap-3 justify-between">
              <div
                className="cursor-pointer label"
                onClick={() => handleWorkQualityChange("SiliconIn", "Good")}
              >
                <span className="label-text mr-2">ভালো</span>
                <input
                  type="checkbox"
                  className="checkbox checkbox-success"
                  checked={workQuality.SiliconIn === "Good"}
                  readOnly
                />
              </div>

              <div
                className="cursor-pointer label"
                onClick={() => handleWorkQualityChange("SiliconIn", "Bad")}
              >
                <span className="label-text mr-2">ভালো নয়</span>
                <input
                  type="checkbox"
                  className="checkbox checkbox-error"
                  checked={workQuality.SiliconIn === "Bad"}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className={`${submitButton} mt-3`}>Add</button>
    </form>
  );
};

export default AddWorkQualityReport;
