import React, { useEffect, useState } from "react";
import PageTitle from "../../../pageTitle/PageTitle";
import bg from "../../../../assets/img/bg.avif";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl, isEmployeeLoggedIn } from "../../../../ApiUrlAndToken";
import ScopeWorkDetails from "./ScopeWorkDetails";

const WorkflowScopeOfWork = () => {
  const { ProjectName, ProjectID } = useParams();

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState("");

  useEffect(() => {
    const getProjectByID = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/project/fetchProject/${ProjectID}`
        );
        setProject(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    if (ProjectID) getProjectByID();
  }, [ProjectID]);
  const titles = `${ProjectName} - ${
    parseInt(project.Status) === 0
      ? "Upcoming"
      : parseInt(project.Status) === 1
      ? "Ongoing"
      : parseInt(project.Status) === 2
      ? "Completed"
      : ""
  }`;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <div className="overflow-y-hidden">
      <PageTitle title={titles} insideProject={1} />

      <div
        className="pt-12 md:pt-0 md:ml-40 flex flex-col gap-6 font-Inter h-screen overflow-y-hidden overflow-x-auto"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {loading ? (
          <div
            className={`mt-16 animate-spin rounded-full h-16 w-16 border-t-4 ${
              parseInt(project.Status) === 0
                ? "border-blue-500"
                : parseInt(project.Status) === 1
                ? "border-orange-500"
                : "border-green-500"
            }  border-solid m-auto`}
          />
        ) : (
          <div>
            <div className="mb-3 w-max mx-auto mt-12 md:mt-14 flex">
              <Link
                to={`/${
                  isEmployeeLoggedIn ? "employee" : "admin"
                }/${ProjectName}/${ProjectID}`}
                className={`px-2 pb-1 border-b-2 border-slate-400 hover:border-slate-700`}
              >
                Scope of Work
              </Link>
              <Link
                to={`/${
                  isEmployeeLoggedIn ? "employee" : "admin"
                }/material-requisition/${ProjectName}/${ProjectID}`}
                className={`px-2 pb-1 border-b-2 border-slate-400 hover:border-slate-700`}
              >
                Material Requisition
              </Link>
              <Link
                to={`/${
                  isEmployeeLoggedIn ? "employee" : "admin"
                }/workflow/${ProjectName}/${ProjectID}`}
                className={`px-2 pb-1 border-b-2 border-slate-400 hover:border-slate-700`}
              >
                Workflow
              </Link>
            </div>
            <ScopeWorkDetails />
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkflowScopeOfWork;
