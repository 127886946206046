import React, { useEffect, useState } from "react";
import {
  apiUrl,
  isAdminLoggedIn,
  isEmployeeLoggedIn,
} from "../../../ApiUrlAndToken";
import axios from "axios";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import EditIcon from "@mui/icons-material/BorderColor";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { hoverScale } from "../../../components/DesignStandardize";
import { Link } from "react-router-dom";
import LocationIcon from "@mui/icons-material/LocationOn";
import AddAssignedEngineer from "../../projects/components/AddAssignedEngineer";
import EditProject from "../../projects/components/EditProject";
import GeneralModal from "../../../components/GeneralModal";

const SearchForm = () => {
  const [query, setQuery] = useState("");
  const [projects, setProjects] = useState([]);
  const [projectSuggestions, setProjectSuggestions] = useState([]);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState("");
  const [locationInputField, setLocationInputField] = useState(false);
  const [projectInputField, setProjectInputField] = useState(false);
  const [projectsByLocation, setProjectsByLocation] = useState([]);
  const handleProjectInputChange = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    if (inputValue === "") {
      setProjectSuggestions([]);
      return;
    }
    const filtered = projects.filter((project) =>
      project.ProjectName.toLowerCase().includes(inputValue.toLowerCase())
    );
    setProjectSuggestions(filtered);
  };

  const handleLocationInputChange = (e) => {
    const inputValue = e.target.value.trim();
    setQuery(inputValue);
    const filtered = projects.filter((project) =>
      project.Location.toLowerCase().includes(inputValue.toLowerCase().trim())
    );
    if (inputValue === "") {
      setLocationSuggestions([]);
      return;
    }
    const uniqueLocations = Array.from(
      new Set(filtered.map((project) => project.Location))
    );

    setLocationSuggestions(
      uniqueLocations.map((location) =>
        filtered.find((project) => project.Location === location)
      )
    );
  };

  const handleProjectSuggestionClick = (project) => {
    setQuery(project.ProjectName);
    setProjectSuggestions([]);
  };

  const handleLocationSuggestionClick = (project) => {
    setQuery(project.Location);
    setLocationSuggestions([]);
  };

  const fetchAllProjects = async () => {
    try {
      const response = await axios.get(`${apiUrl}/project/fetchAll`);
      setProjects(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllProjects();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const filterProject = projects.find((item) => item.ProjectName === query);

    setSelectedProjects(filterProject);
    // console.log(filterProject);
    return filterProject;
  };

  const handleLocationSubmit = (e) => {
    e.preventDefault();
    const filterProject = projects.filter((item) => item.Location === query);
    // console.log(filterProject);
    setProjectsByLocation(filterProject);
    return filterProject;
  };

  const handleClearField = (e) => {
    e.preventDefault();
    setQuery("");
    setProjectsByLocation([]);
    setSelectedProjects("");
  };

  const [projectID, setProjectID] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  const AddAssignedEngineerModal = (id, name) => {
    setProjectID(id);
    setProjectName(name);
    toggleModal();
  };

  const [editModal, setEditModal] = useState(false);
  const toggleEditModal = () => {
    setEditModal((prevShowModal) => !prevShowModal);
  };

  const [detailsModal, setDetailsModal] = useState(false);
  const toggleDetailsModal = () => {
    setDetailsModal((prevShowModal) => !prevShowModal);
  };
  return (
    <>
      <div className="flex flex-col gap-3">
        <div className="flex flex-row flex-wrap gap-5 justify-center">
          <button
            onClick={() => {
              setLocationInputField(!locationInputField);
              setProjectInputField(false);
              setQuery("");
              setProjectsByLocation([]);
              setSelectedProjects("");
            }}
            className={`text-blue-400 hover:text-blue-500 underline underline-offset-2`}
          >
            Search By Project Location
          </button>
          <button
            onClick={() => {
              setProjectInputField(!projectInputField);
              setLocationInputField(false);
              setQuery("");
              setProjectsByLocation([]);
              setSelectedProjects("");
            }}
            className={`text-blue-400 hover:text-blue-500 underline underline-offset-2`}
          >
            Search By Project Name
          </button>
        </div>

        {locationInputField && !projectInputField && (
          <div className="mb-[2px] animate-bounce-once">
            <input
              type="text"
              placeholder="Search by Project Location..."
              value={query}
              className="input input-bordered w-72"
              onChange={handleLocationInputChange}
              autoFocus
            />
            <button type="submit" className={`-ml-14`}>
              <SearchIcon onClick={handleLocationSubmit} className="mr-1" />

              <SearchOffIcon onClick={handleClearField} />
            </button>
          </div>
        )}

        {projectInputField && !locationInputField && (
          <form onSubmit={handleSubmit}>
            <div className="mb-[2px] animate-bounce-once">
              <input
                type="text"
                placeholder="Search by Project Name..."
                value={query}
                className="input input-bordered w-72"
                onChange={handleProjectInputChange}
                autoFocus
              />
              <button type="submit" className={`-ml-14`}>
                <SearchIcon onClick={handleSubmit} className="mr-1" />

                <SearchOffIcon onClick={handleClearField} />
              </button>
            </div>
          </form>
        )}

        {/* Display suggestions */}
        {(projectSuggestions.length > 0 || locationSuggestions.length > 0) &&
          query !== "" && (
            <div className="flex flex-col text-left p-2 gap-1 w-[292px] mx-auto bg-slate-50 rounded">
              {projectInputField ? (
                projectSuggestions.map((project) => (
                  <div
                    key={project.id}
                    onClick={() => handleProjectSuggestionClick(project)}
                    className="border-b py-2 cursor-pointer text-base hover:text-blue-500 hover:bg-slate-100"
                  >
                    {project.ProjectName}
                  </div>
                ))
              ) : locationInputField ? (
                locationSuggestions.map((project) => (
                  <div
                    key={project.id}
                    onClick={() => handleLocationSuggestionClick(project)}
                    className="border-b py-2 cursor-pointer text-base hover:text-blue-500 hover:bg-slate-100"
                  >
                    {project.Location}
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          )}

        {selectedProjects && (
          <div
            className={`text-left p-4 w-60 border-[1px] border-solid rounded-xl bg-slate-100 flex flex-col gap-3 hover:shadow mb-2 mx-auto`}
          >
            {selectedProjects.Uploads ? (
              <Link
                to={`/admin/${selectedProjects.ProjectName}/${selectedProjects.ProjectID}`}
              >
                <img
                  className={`w-full h-32 hover:cursor-pointer rounded-xl`}
                  src={`${apiUrl}/uploads/${selectedProjects.Uploads}`}
                  alt="Profile"
                />
              </Link>
            ) : (
              <Link
                to={`/admin/${selectedProjects.ProjectName}/${selectedProjects.ProjectID}`}
                className={`w-full h-32 bg-[#a1a0a6] rounded-xl`}
              />
            )}

            <div className="flex justify-between gap-3 border-b-[1px] border-solid pb-1">
              <p className="text-xl font-semibold hover:cursor-pointer w-36 truncate">
                <Link
                  to={`/admin/${selectedProjects.ProjectName}/${selectedProjects.ProjectID}`}
                >
                  {selectedProjects.ProjectName}
                </Link>
              </p>

              <div
                className={`hover:cursor-pointer rounded-full text-slate-900 hover:text-black hover:bg-slate-300 p-[2px] hover:translate-x-1 transition-all duration-300`}
              >
                <Link
                  to={`/admin/${selectedProjects.ProjectName}/${selectedProjects.ProjectID}`}
                >
                  <ArrowForwardIcon />
                </Link>
              </div>
            </div>

            <div>
              <div className="grid grid-cols-2">
                <p className="text-sm text-slate-400">Start Date</p>
                <p>: {selectedProjects.Start_Date?.split("T")[0]}</p>
                <p className="text-sm text-slate-400">End Date</p>
                <p>: {selectedProjects.End_Date?.split("T")[0]}</p>
              </div>
              <p className="truncate flex items-center">
                <LocationIcon fontSize="1px" />
                <p className="">{selectedProjects.Location}</p>
              </p>
              <div
                onClick={toggleDetailsModal}
                className={`hover:cursor-pointe btn btn-outline btn-sm mt-3`}
              >
                <VisibilityIcon fontSize="1px" /> View Details
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col md:flex-row flex-wrap gap-4 overflow-x-auto md:overflow-x-hidden w-11/12 mx-auto">
          {projectsByLocation.map((el, i) => {
            return (
              <div
                key={i}
                onClick={toggleModal}
                className={`text-left p-4 w-60 border-[1px] border-solid rounded-xl bg-slate-100 flex flex-col gap-3 hover:shadow mb-2`}
              >
                {el.Uploads ? (
                  <Link to={`/admin/${el.ProjectName}/${el.ProjectID}`}>
                    <img
                      className={`w-full h-32 hover:cursor-pointer rounded-xl`}
                      src={`${apiUrl}/uploads/${el.Uploads}`}
                      alt="Profile"
                    />
                  </Link>
                ) : (
                  <Link
                    to={`/admin/${el.ProjectName}/${el.ProjectID}`}
                    className={`w-full h-32  ${
                      i % 2 === 0 ? "bg-[#d3e0cc]" : "bg-[#a1a0a6]"
                    } rounded-xl`}
                  />
                )}
                <div className="flex justify-between gap-3 border-b-[1px] border-solid pb-1">
                  <p className="text-xl font-semibold hover:cursor-pointer w-28 truncate">
                    <Link to={`/admin/${el.ProjectName}/${el.ProjectID}`}>
                      {el.ProjectName}
                    </Link>
                  </p>
                  <div className="flex flex-row gap-2">
                    {/* <div
                      onClick={() =>
                        AddAssignedEngineerModal(el.ProjectID, el.ProjectName)
                      }
                      className={`hover:cursor-pointer rounded-full text-green-600 hover:text-green-700`}
                    >
                      <PersonAddAltIcon />
                    </div> */}

                    {/* <div
                      className={`hover:cursor-pointe rounded-full ${hoverScale} text-blue-600 hover:text-blue-700 `}
                    >
                      <EditIcon fontSize="1px" />
                    </div> */}

                    <div
                      className={`hover:cursor-pointer rounded-full text-slate-900 hover:text-black hover:bg-slate-300 p-[2px] hover:translate-x-1 transition-all duration-300`}
                    >
                      <Link to={`/admin/${el.ProjectName}/${el.ProjectID}`}>
                        <ArrowForwardIcon />
                      </Link>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="grid grid-cols-2">
                    <p className="text-sm text-slate-400">Start Date</p>
                    <p>: {el.Start_Date.split("T")[0]}</p>
                    <p className="text-sm text-slate-400">End Date</p>
                    <p>: {el.End_Date.split("T")[0]}</p>
                  </div>
                  <p className="truncate flex items-center">
                    <LocationIcon fontSize="1px" />
                    <p className="">{el.Location}</p>
                  </p>
                  <div
                    className={`hover:cursor-pointe btn btn-outline btn-sm mt-3`}
                  >
                    <VisibilityIcon fontSize="1px" /> View Details
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Modal to assign engineer */}
      {modal && projectID && projectName && (
        <GeneralModal
          title={`Assign Engineer to ${projectName}`}
          onClose={toggleModal}
        >
          <AddAssignedEngineer projectID={projectID} />
        </GeneralModal>
      )}

      {/* Modal to edit project */}
      {editModal && selectedProjects && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleEditModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                Edit {selectedProjects.ProjectName} -{" "}
                <span className="text-warning">Ongoing</span>
              </p>
              <CloseIcon
                onClick={toggleEditModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
            <EditProject projectID={selectedProjects.ProjectID} />
          </div>
        </div>
      )}

      {/* Modal to see details of a project */}
      {detailsModal && selectedProjects && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleDetailsModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                {selectedProjects.ProjectName} -{" "}
                <span className="text-warning">Ongoing</span>
              </p>
              <CloseIcon
                onClick={toggleDetailsModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>

            <div className="max-h-96 overflow-x-auto">
              <table className="table border-[1px] border-solid">
                <tbody className="">
                  {/* start date */}
                  <tr className="bg-base-200">
                    <th className="border-r-[2px] border-solid">Start Date</th>
                    <td className="">
                      {selectedProjects.Start_Date.split("T")[0]}
                    </td>
                  </tr>
                  {/* end date */}
                  <tr>
                    <th className="border-r-[2px] border-solid">End Date</th>
                    <td className="">
                      {selectedProjects.Start_Date.split("T")[0]}
                    </td>
                  </tr>
                  {/* location */}
                  <tr className="bg-base-200 h-max">
                    <th className="border-r-[2px] border-solid">Location</th>
                    <td className="w-80">{selectedProjects.Location}</td>
                  </tr>
                  {/* description */}
                  <tr className="h-max">
                    <th className="border-r-[2px] border-solid">Description</th>
                    <td className="w-80">{selectedProjects.Details}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchForm;
