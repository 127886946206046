import "./App.css";
import { Routes, Route } from "react-router-dom";
import Auth from "./views/auth/Auth";
import EditUserForm from "./views/employee/components/EditEmployee";
import AdminReg from "./views/auth/components/AdminReg";
import { isAdminLoggedIn, isEmployeeLoggedIn } from "./ApiUrlAndToken";
import Navbar from "./components/Navbar";
import FloatingButton from "./components/FloatingButton";
import Dashbooard from "./views/dashboard/Dashbooard";
import Employee from "./views/employee/Employee";
import ProjectIndividual from "./views/projectIndividual/ProjectIndividual";
// import ProjectList from "./views/projects/ProjectList";
import EmpProjects from "./views/employeeProjects/EmpProjects.jsx";
import Manpower from "./views/manpower/Manpower.jsx";
import MaterialRequest from "./views/projectIndividual/materialRequest/request/MaterialRequest.jsx";
import MaterialRequisition from "./views/projectIndividual/materialRequest/MaterialRequisition.jsx";
import Worker from "./views/manpower/worker/Worker.jsx";
import Workflow from "./views/projectIndividual/workflow/Workflow.jsx";
import WorkflowScopeOfWork from "./views/projectIndividual/scopeOfWork/sowIndividual/WorkflowScopeOfWork.jsx";

function App() {
  return (
    <div className="App">
      {isAdminLoggedIn || isEmployeeLoggedIn ? <Navbar /> : <></>}
      <Routes>
        {isAdminLoggedIn ? (
          <Route path="/" element={<Dashbooard />} />
        ) : (
          <Route path="/" element={<Auth />} />
        )}

        {isEmployeeLoggedIn ? (
          <Route path="/employee/projects" element={<EmpProjects />} />
        ) : (
          <Route path="/" element={<Auth />} />
        )}

        <Route path="/add-admin" element={<AdminReg />} />
        {/* <Route path="/admin/admin-edit" element={<AdminEdit />} />
        <Route path="/edit-user" element={<EditUserForm />} /> */}

        {isAdminLoggedIn && (
          <>
            <Route path="/admin/dashboard" element={<Dashbooard />} />
            <Route path="/admin/employee-list" element={<Employee />} />
            <Route
              path="/admin/:ProjectName/:ProjectID"
              element={<ProjectIndividual />}
            />
            <Route
              path="/admin/material-requisition/:ProjectName/:ProjectID"
              element={<MaterialRequisition />}
            />
            <Route
              path="/admin/workflow/:ProjectName/:ProjectID"
              element={<Workflow />}
            />
            <Route
              path="/admin/scope-of-work/:ProjectName/:ProjectID/:scope_work_name/:scope_work_id"
              element={<WorkflowScopeOfWork />}
            />
            <Route path="/admin/manpower/contractor" element={<Manpower />} />
            <Route path="/admin/manpower/worker" element={<Worker />} />
          </>
        )}

        {isEmployeeLoggedIn && (
          <>
            <Route path="/employee/employee-list" element={<Employee />} />
            <Route path="/employee/projects" element={<EmpProjects />} />

            <Route
              path="/employee/:ProjectName/:ProjectID"
              element={<ProjectIndividual />}
            />
            <Route
              path="/employee/material-requisition/:ProjectName/:ProjectID"
              element={<MaterialRequisition />}
            />
            <Route
              path="/employee/workflow/:ProjectName/:ProjectID"
              element={<Workflow />}
            />
            <Route
              path="/employee/scope-of-work/:ProjectName/:ProjectID/:scope_work_name/:scope_work_id"
              element={<WorkflowScopeOfWork />}
            />
            <Route
              path="/employee/manpower/contractor"
              element={<Manpower />}
            />
            <Route path="/employee/manpower/worker" element={<Worker />} />
          </>
        )}
      </Routes>
      {isAdminLoggedIn || isEmployeeLoggedIn ? <FloatingButton /> : <></>}
    </div>
  );
}

export default App;
