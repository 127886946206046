import React, { useEffect, useState } from "react";
import { submitButton } from "../../../components/DesignStandardize";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/PeopleAltOutlined";
import AddIcon from "@mui/icons-material/Add";

const AddAssignWorker = ({ projectID }) => {
  const [workers, setWorkers] = useState([]);
  const [selectedWorkers, setSelectedWorkers] = useState([]);

  const projectWorkers = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/assignWorker/getWorkerNotAssigned/${projectID}`
      );
      // console.log(response.data.workersArr);
      setWorkers(response.data.workersArr);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    projectWorkers();
  }, [projectID]);

  const handleCheckboxChange = (event, worker) => {
    if (event.target.checked) {
      setSelectedWorkers((prevSelected) => [
        ...prevSelected,
        {
          workerID: worker.worker_id,
          contractorID: worker.contractor_id,
          Name: worker.name,
        },
      ]);
    } else {
      setSelectedWorkers((prevSelected) =>
        prevSelected.filter((e) => e.workerID !== worker.worker_id)
      );
    }
  };

  const ProjectWorkerAssigned = async () => {
    try {
      for (const worker of selectedWorkers) {
        // console.log(worker);
        const data = {
          workerID: worker.workerID,
          contractorID: worker.contractorID,
          projectID: projectID,
        };
        // console.log(data);
        const response = await axios.post(
          `${apiUrl}/assignWorker/createAssign`,
          data
        );
        // console.log(response.data);
      }
      setTimeout(() => {
        window.location.reload();
      }, 200);
      toast.success("Workers assigned Successfully!!");
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(`${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response received from the server");
      } else {
        toast.error("An error occurred while processing the request");
      }
    }
  };

  return (
    <div className="flex flex-col gap-3 justify-between">
      {workers.length > 0 ? (
        <>
          {workers.map((worker) => {
            return (
              <div className="flex flex-row gap-3">
                <input
                  type="checkbox"
                  checked={selectedWorkers.some(
                    (e) => e.workerID === worker.worker_id
                  )}
                  className="checkbox checkbox-sm checkbox-primary"
                  onChange={(event) => handleCheckboxChange(event, worker)}
                />
                <span className="">{worker.name}</span>
                <span className="text-gray-500">
                  {"("}
                  {worker.contractorName}
                  {")"}
                </span>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <p className="font-semibold text-info animate-pulse text-lg">
            No workers available!!
          </p>
          <Link
            to={`/${
              isAdminLoggedIn ? "admin" : "employee"
            }/manpower/contractor`}
            className={`w-max mx-auto hover:cursor-pointer rounded-full text-blue-600 hover:btn-info btn btn-outline gap-0`}
          >
            <PeopleIcon /> <AddIcon fontSize="small" /> Add Worker
          </Link>
        </>
      )}

      {workers.length > 0 && (
        <button
          className={`${submitButton} mt-3 mx-auto`}
          onClick={ProjectWorkerAssigned}
        >
          Save
        </button>
      )}
    </div>
  );
};

export default AddAssignWorker;
