import React, { useState, useEffect } from "react";
import { hoverScale } from "../../../components/DesignStandardize";
import profileDefault from "../../../assets/img/icons/profile.png";
import EditIcon from "@mui/icons-material/BorderColor";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import AdminProfileEdit from "../profileEdit/AdminProfileEdit";
import axios from "axios";
import { apiUrl } from "../../../ApiUrlAndToken";
import GeneralModal from "../../../components/GeneralModal";

const AdminSessionMenu = () => {
  const [modal, setModal] = useState(false);
  const [admin, setAdmin] = useState(null);
  const [photo, setPhoto] = useState(null);
  const adminToken = localStorage.getItem("admin");

  const getAdmin = async (id) => {
    try {
      const response = await axios.get(`${apiUrl}/admin/fetchAdmin/${id}`);
      setPhoto(response.data.Picture);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (adminToken) {
      const Object = JSON.parse(adminToken);
      // console.log("Admin Info:", Object);
      setAdmin(Object);
      getAdmin(Object.AdminID);
      // console.log({ });
    }
  }, [adminToken]);

  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };

  return (
    <>
      <div className="dropdown dropdown-bottom dropdown-end">
        <img
          tabIndex={0}
          className={`w-8 h-8 hover:cursor-pointer hover:shadow-lg rounded-full mt-1 ${hoverScale}`}
          src={photo ? `${apiUrl}/uploads/${photo}` : profileDefault}
          alt="Profile"
        />
        <div
          tabIndex={0}
          className="dropdown-content menu p-3 shadow bg-base-100 rounded-box w-64 flex flex-col gap-3 z-10"
        >
          <div className="flex flex-row justify-between gap-1">
            <img
              className={`w-20 h-20 shadow rounded-full`}
              src={photo ? `${apiUrl}/uploads/${photo}` : profileDefault}
              alt="Profile"
            />
            <div
              className={`mb-auto hover:cursor-pointer flex items-center justify-center rounded-full text-black ${hoverScale}`}
              onClick={toggleModal}
            >
              <EditIcon fontSize="1px" />
            </div>
          </div>
          <div className="flex flex-col gap-3 font-semibold text-left">
            <p className="">Email: {admin?.Email}</p>
            {/* <p className="">Admin ID: {admin?.AdminID}</p> */}
          </div>
        </div>
      </div>

      {/* modal update admin details */}
      {modal && (
        <GeneralModal title="Update Admin Profile" onClose={toggleModal}>
          <AdminProfileEdit id={admin.AdminID} />
        </GeneralModal>
      )}
    </>
  );
};

export default AdminSessionMenu;
