import React, { useState } from "react";
import { submitButton } from "../../../../components/DesignStandardize";
import GeneralInput from "../../../../components/GeneralInput";
import { toast } from "react-toastify";
import axios from "axios";
import { apiUrl } from "../../../../ApiUrlAndToken";

const AddContractors = () => {
  const [Name, setName] = useState("");
  const [Address, setAddress] = useState("");
  const [Contact, setContact] = useState("");
  const [BloodGroup, setBloodGroup] = useState("");
  const AddContractorInfo = async (e) => {
    e.preventDefault();
    if (Name === "") {
      toast.warning("Name field cannot be empty!");
      return;
    }
    // if (Address === "") {
    //   toast.warning("Address field cannot be empty!");
    //   return;
    // }
    // if (Contact === "") {
    //   toast.warning("Contact field cannot be empty!");
    //   return;
    // }
    try {
      const data = {
        name: Name,
        address: Address,
        contact: Contact,
        BGroup: BloodGroup,
      };
      // console.log(data);
      const response = await axios.post(
        `${apiUrl}/contractor/createContractor`,
        data
      );
      // console.log(response.data);
      toast.success("Contractor information has been added successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error.response.data.message.message);
      toast.error("Please enter correct blood group.");
    }
  };
  return (
    <form
      className="flex flex-col gap-3 items-center mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={AddContractorInfo}
    >
      <div className="flex md:flex-row flex-col gap-3">
        <GeneralInput
          label="Contractor Name"
          labelSpanText="*"
          type="text"
          placeholder="Enter Name"
          value={Name}
          onChange={(ev) => setName(ev.target.value)}
          required
        />
        <GeneralInput
          label="Contact"
          type="text"
          placeholder="Enter Contact No."
          value={Contact}
          onChange={(ev) => setContact(ev.target.value)}
          required
        />
      </div>

      <div className="flex md:flex-row flex-col gap-3">
        <GeneralInput
          label="Blood Group"
          type="text"
          placeholder="Enter Blood Group"
          value={BloodGroup}
          onChange={(ev) => setBloodGroup(ev.target.value)}
          required
        />
        <GeneralInput
          label="Address"
          type="textarea"
          placeholder="Enter Address"
          value={Address}
          onChange={(ev) => setAddress(ev.target.value)}
          required
        />
      </div>
      <button className={`${submitButton} mt-1`} type="submit">
        Submit
      </button>
    </form>
  );
};

export default AddContractors;
