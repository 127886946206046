import React from "react";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const DetailsModalProject = ({ project }) => {
  return (
    <div
      className={`flex ${
        project.Uploads ? "flex-row flex-wrap items-center" : "flex-col"
      }  justify-center gap-1`}
    >
      <div className="flex flex-col gap-1">
        {project.Uploads && (
          <Link to={`/admin/${project.ProjectName}/${project.ProjectID}`}>
            <img
              className={`w-60 hover:cursor-pointer rounded-xl`}
              src={`${apiUrl}/uploads/${project.Uploads}`}
              alt="Profile"
            />
          </Link>
        )}
        {(window.location.pathname === "/admin/dashboard" ||
          window.location.pathname === "/") && (
          <Link
            className={`hover:cursor-pointe btn btn-outline btn-sm w-max`}
            to={`/${isAdminLoggedIn ? "admin" : "employee"}/${
              project.ProjectName
            }/${project.ProjectID}`}
          >
            Go To Project
            <ArrowForwardIcon fontSize="1px" />
          </Link>
        )}
      </div>
      <div className="max-h-96 overflow-x-auto">
        <table className="table border-[1px] border-solid">
          <tbody className="">
            {/* start date */}
            <tr className="bg-base-200">
              <th className="border-r-[2px] border-solid">Start Date</th>
              <td className="">{project.Start_Date.split("T")[0]}</td>
            </tr>
            {/* end date */}
            <tr>
              <th className="border-r-[2px] border-solid">End Date</th>
              <td className="">{project.Start_Date.split("T")[0]}</td>
            </tr>
            {/* location */}
            <tr className="bg-base-200 h-max">
              <th className="border-r-[2px] border-solid">Location</th>
              <td className="w-80">{project.Location}</td>
            </tr>
            {/* description */}
            {/* <tr className="h-max">
              <th className="border-r-[2px] border-solid">Description</th>
              <td className="w-80">{project.Details}</td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetailsModalProject;
