import React, { useState, useEffect, useRef } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import EngineerIcon from "@mui/icons-material/Engineering";
import PeopleIcon from "@mui/icons-material/PeopleAltOutlined";
import { handleLogout } from "./Navbar";
import LogoutIcon from "@mui/icons-material/Logout";
import { hoverScale } from "./DesignStandardize";
import { isEmployeeLoggedIn, isAdminLoggedIn } from "../ApiUrlAndToken";

const FloatingButton = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const buttonRef = useRef(null);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const buttonStyle =
    "bg-blue-800 text-white p-2 cursor-pointer flex flex-row gap-1 rounded-lg";
  return (
    <div className="fixed bottom-2 right-2 flex flex-col items-end md:hidden z-50">
      {/* Circle button */}
      <div
        ref={buttonRef}
        className={`rounded-full bg-blue-700 text-white p-2 text-xs cursor-pointer relative ${
          isExpanded === true && "animate-spin"
        } `}
        onClick={toggleExpansion}
      >
        <MenuIcon />
      </div>

      {/* Expanded buttons */}
      <div
        className={`${
          isExpanded ? "flex flex-col gap-2 absolute w-max" : "hidden"
        } rounded-md`}
        style={{
          transform: `translateY(${isExpanded ? "-105%" : "0"})`,
        }}
      >
        <div
          className={`${buttonStyle} ${hoverScale}`}
          onClick={() => {
            if (isEmployeeLoggedIn) {
              window.location.href = "/employee/projects";
            } else if (isAdminLoggedIn) {
              window.location.href = "/admin/dashboard";
            }
          }}
        >
          <HomeIcon /> Dashboard
        </div>
        <div
          className={`${buttonStyle} ${hoverScale}`}
          onClick={() => {
            if (isEmployeeLoggedIn) {
              window.location.href = "/employee/employee-list";
            } else if (isAdminLoggedIn) {
              window.location.href = "/admin/employee-list";
            }
          }}
        >
          <EngineerIcon /> Employees
        </div>
        <div
          className={`${buttonStyle} ${hoverScale}`}
          onClick={() => {
            if (isEmployeeLoggedIn) {
              window.location.href = "/employee/manpower";
            } else if (isAdminLoggedIn) {
              window.location.href = "/admin/manpower";
            }
          }}
        >
          <PeopleIcon /> Manpower
        </div>

        <div className={`${buttonStyle}`} onClick={() => handleLogout()}>
          <LogoutIcon /> Log out
        </div>
      </div>
    </div>
  );
};

export default FloatingButton;
