import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl, isEmployeeLoggedIn } from "../../../../ApiUrlAndToken";
import WorkflowPie from "./WorkflowPie";
import { hoverScale } from "../../../../components/DesignStandardize";
import AddTarget from "./AddTarget";
import AddCompleted from "./AddCompleted";
import EditIcon from "@mui/icons-material/BorderColor";
import { EditTarget } from "./EditTarget";
import EditComplete from "./EditComplete";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import GeneralModal from "../../../../components/GeneralModal";
import { Link, useParams } from "react-router-dom";

const GetSoW = ({ id }) => {
  const { ProjectName, ProjectID } = useParams();

  const [mergedScopeOfWork, setMergedScopeOfWork] = useState([]);
  const [showAddTargetButton, setShowAddTargetButton] = useState(false);
  const [showAddCompletedButton, setShowAddCompletedButton] = useState(false);
  const date = new Date().toISOString().split("T")[0];
  const getAllWorks = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/scope-works/project/${parseInt(id)}`
      );
      // console.log(response.data);

      const mergedScopeOfWork = await Promise.all(
        response.data.map(async (item) => {
          const targetWork = await axios.get(
            `${apiUrl}/workflow/getTargetID/${item.WorkID}/${date}`
          );
          const completedWork = await axios.get(
            `${apiUrl}/workflow/getCompletedWorks/${item.WorkID}/${date}`
          );
          const remainingFetch = await axios.get(
            `${apiUrl}/workflow/getTargetWork/${item.WorkID}`
          );
          const totalCompleted = await axios.get(
            `${apiUrl}/workflow/getTotalWork/${item.WorkID}`
          );
          // console.log(totalCompleted.data.totalCompletedWork);
          // console.log(remainingFetch.data[0].remaining);
          return {
            ...item,
            targetWork: targetWork.data[0],
            completedWork: completedWork.data[0],
            remainingWork: remainingFetch.data[0]?.remaining,
            totalCompletedWork: totalCompleted.data.totalCompletedWork,
          };
        })
      );

      console.log(mergedScopeOfWork);
      setMergedScopeOfWork(mergedScopeOfWork);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllWorks();
  }, [id]);

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setShowAddTargetButton(
      mergedScopeOfWork.some(
        (item) => item.targetWork && item.targetWork.date === currentDate
      )
    );
    setShowAddCompletedButton(
      mergedScopeOfWork.some(
        (item) => item.completedWork && item.completedWork.date === currentDate
      )
    );
  }, [mergedScopeOfWork]);

  const [addTargetModal, setAddTargetModal] = useState(false);
  const toggleAddTargetModal = () => {
    setAddTargetModal((prevShowModal) => !prevShowModal);
  };
  const [editTargetModal, setEditTargetModal] = useState(false);
  const toggleEditTargetModal = () => {
    setEditTargetModal((prevShowModal) => !prevShowModal);
  };
  const [addCompletedModal, setAddCompletedModal] = useState(false);
  const toggleAddCompletedModal = () => {
    setAddCompletedModal((prevShowModal) => !prevShowModal);
  };
  const [EditCompletedModal, setEditCompletedModal] = useState(false);
  const toggleEditCompletedModal = () => {
    setEditCompletedModal((prevShowModal) => !prevShowModal);
  };
  const DeleteWork = async (workID, name) => {
    try {
      const response = await axios.delete(
        `${apiUrl}/scope-works/deleteScopeWork/${workID}`
      );
      // console.log(response.data);
      toast.warn(`${name} has been deleted successfully!`);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      toast.error("An error occurd!");
    }
  };

  // Filter scopeOfWorks to include only objects with Status=1
  const statusOneWorks = mergedScopeOfWork.filter((work) => work.Status === 1);

  // Check if any object with Status=1 has sqrfoot === null
  const hasNullSqrfoot = statusOneWorks.some((work) => work.sqrfoot === null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      <div className="flex gap-5 mb-3 justify-center">
        {hasNullSqrfoot ? (
          <p className="text-red-700 animate-pulse font-bold">
            All scope of works must have total area to add today's target and
            completed.
          </p>
        ) : (
          <>
            {loading ? (
              <div className="my-2 animate-spin rounded-full h-8 w-8 border-t-4 border-blue-500 border-solid m-auto" />
            ) : (
              <>
                {!showAddTargetButton && (
                  <div
                    onClick={() => toggleAddTargetModal()}
                    className="my-auto btn btn-sm btn-outline"
                  >
                    Add Today's Target
                  </div>
                )}
                {!showAddCompletedButton && showAddTargetButton && (
                  <div
                    onClick={() => toggleAddCompletedModal()}
                    className="my-auto btn btn-sm btn-outline"
                  >
                    Add Today's Completed
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>

      <div className="overflow-x-auto h-screen my-2 flex flex-col gap-3 px-2">
        <table className="m-2 lg:m-0 text-center border border-slate-300 text-lg">
          <thead>
            <tr className="text-white text-lg border-b border-slate-300 bg-green-800">
              <th className="p-1 w-40">Scope of Work</th>
              <th className="p-1 w-40 border-x border-slate-300">Total Area</th>
              <th className="p-1 w-80">
                Today's Target {"("}sq ft{")"}
                {showAddTargetButton ? (
                  <span
                    onClick={() => toggleEditTargetModal()}
                    className={`hover:cursor-pointer text-white`}
                  >
                    {" "}
                    <EditIcon fontSize="inherit" />
                  </span>
                ) : (
                  <></>
                )}
              </th>
              <th className="p-1 w-[360px] border-x border-slate-300">
                Today's Completed {"("}sq ft{")"}
                {showAddCompletedButton ? (
                  <span
                    onClick={() => toggleEditCompletedModal()}
                    className={`hover:cursor-pointer text-white`}
                  >
                    {" "}
                    <EditIcon fontSize="inherit" />
                  </span>
                ) : (
                  <></>
                )}
              </th>
              <th className="p-1 w-60 border-x border-slate-300">
                Per Day Statistics {"("}sq ft{")"}
              </th>
              <th className="p-1 w-60 border-x border-slate-300">
                Total Statistics {"("}sq ft{")"}
              </th>
              <th className="p-1 w-60 border-x border-slate-300">
                Delete Scope of Work
              </th>
            </tr>
          </thead>
          <tbody>
            {mergedScopeOfWork.map((item, index) => {
              const chartData = {
                labels: ["Remaining", "Target"],
                values: [
                  item.completedWork && item.targetWork.amount
                    ? item.targetWork.amount - item.completedWork.amount
                    : 0,
                  item.targetWork ? item.targetWork.amount : 0,
                ],
                colors: ["blue", "#87ceeb"],
              };
              const totalChartData = {
                labels: ["Remaining Work", "Completed Work"],
                values: [
                  item.sqrfoot - item.totalCompletedWork,
                  item.totalCompletedWork,
                ],
                colors: ["green", "yellow"],
              };
              return (
                <tr
                  className="border-b border-slate-300 overflow-y-auto"
                  key={index}
                >
                  {item.Status !== 0 && (
                    <>
                      <td className="p-1 w-40 font-semibold">
                        <Link
                          to={`/${
                            isEmployeeLoggedIn ? "employee" : "admin"
                          }/scope-of-work/${ProjectName}/${ProjectID}/${
                            item.Work_Name
                          }/${item.WorkID}`}
                        >
                          {item.Work_Name}
                        </Link>
                      </td>
                      <td className="p-1 w-40 border-x border-slate-300">
                        {item.sqrfoot || 0}
                      </td>
                      <td className="p-1 w-40 border-x border-slate-300">
                        {item.targetWork ? item.targetWork.amount : 0}
                      </td>
                      <td className="p-1 w-40 border-x border-slate-300">
                        {item.completedWork ? item.completedWork.amount : 0}
                      </td>
                      <td className="w-60 border-x border-slate-300">
                        <WorkflowPie data={chartData} />
                      </td>
                      <td className="w-60">
                        <WorkflowPie data={totalChartData} />
                      </td>
                      <td className="w-60 border-x border-slate-300">
                        <div
                          className={`hover:cursor-pointe rounded-full ${hoverScale} text-blue-600 hover:text-blue-700 `}
                          onClick={() => {
                            DeleteWork(item.WorkID, item.Work_Name);
                          }}
                        >
                          <DeleteIcon className="text-8xl" />
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="mb-32" />
      </div>

      {/* modal to add Today's Target */}
      {addTargetModal && (
        <GeneralModal title="Add Today's Target" onClose={toggleAddTargetModal}>
          <AddTarget scopeOfWorks={mergedScopeOfWork} />
        </GeneralModal>
      )}

      {/* modal to edit Today's Target */}
      {editTargetModal && (
        <GeneralModal
          title="Edit Today's Target"
          onClose={toggleEditTargetModal}
        >
          <EditTarget scopeOfWorks={mergedScopeOfWork} />
        </GeneralModal>
      )}

      {/* modal to add Today's Completed */}
      {addCompletedModal && (
        <GeneralModal
          title="Add Today's Completed"
          onClose={toggleAddCompletedModal}
        >
          <AddCompleted scopeOfWorks={mergedScopeOfWork} />
        </GeneralModal>
      )}

      {/* modal to edit Today's Completed */}
      {EditCompletedModal && (
        <GeneralModal
          title="Edit Today's Completed"
          onClose={toggleEditCompletedModal}
        >
          <EditComplete scopeOfWorks={mergedScopeOfWork} />
        </GeneralModal>
      )}
    </>
  );
};

export default GetSoW;
