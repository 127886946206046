import React, { useEffect, useState } from "react";
import GeneralInput from "../../../../components/GeneralInput";
import { submitButton } from "../../../../components/DesignStandardize";
import axios from "axios";
import { apiUrl } from "../../../../ApiUrlAndToken";
import { toast } from "react-toastify";

const EditWorkers = ({ workerID }) => {
  // console.log(workerID);
  const [data, setData] = useState({
    name: "",
    contact: "",
    address: "",
    bloodgroup: "",
  });
  const { name, contact, address, bloodgroup } = data;
  const getWorkersIndividiual = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/worker/getWorker/${workerID}`
      );
      // console.log(response.data.workerInfo);
      setData(response.data.workerInfo);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getWorkersIndividiual();
  }, [workerID]);

  const updateInfo = async (e) => {
    e.preventDefault();
    if (data.name === "") {
      toast.warning("Name cannot be empty");
      return;
    } else if (data.contact === "") {
      toast.warning("Contact cannot be empty");
      return;
    } else if (data.address === "") {
      toast.warning("Address cannot be empty");
      return;
    } else if (data.bloodGroup === "") {
      toast.warning("Blood Group cannot be empty");
      return;
    }
    try {
      const data = {
        name: name,
        contact: contact,
        address: address,
        bloodGroup: bloodgroup,
      };
      const response = await axios.put(
        `${apiUrl}/worker/updateWorkerWID/${workerID}`,
        data
      );
      // console.log(response.data);
      toast.success("Information updated successfully");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      toast.error("Failed to update Information");
    }
  };
  return (
    <form
      className="flex flex-col gap-3 items-center mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={updateInfo}
    >
      <div className="flex md:flex-row flex-col gap-3">
        <GeneralInput
          label="Worker Name"
          type="text"
          placeholder="Enter Worker Name"
          value={name}
          onChange={(ev) => setData({ ...data, name: ev.target.value })}
          required
        />
        <GeneralInput
          label="Contact"
          type="text"
          placeholder="Enter Contact No."
          value={contact}
          onChange={(ev) => setData({ ...data, contact: ev.target.value })}
          required
        />
      </div>

      <div className="flex md:flex-row flex-col gap-3">
        <GeneralInput
          label="Blood Group"
          type="text"
          placeholder="Enter Blood Group"
          value={bloodgroup}
          onChange={(ev) => setData({ ...data, bloodgroup: ev.target.value })}
          required
        />
        <GeneralInput
          label="Address"
          type="textarea"
          placeholder="Enter Address"
          value={address}
          onChange={(ev) => setData({ ...data, address: ev.target.value })}
          required
        />
      </div>
      <button className={`${submitButton} mt-1`} type="submit">
        Submit
      </button>
    </form>
  );
};

export default EditWorkers;
