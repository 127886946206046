import React from "react";
import { apiUrl, isAdminLoggedIn } from "../../../../ApiUrlAndToken";
import axios from "axios";
import { toast } from "react-toastify";

const UpdateStatusReport = ({ QCReport_ID, QCReport_Status }) => {
  const handleStatusChange = async (status) => {
    // console.log("status:", status);
    // console.log("status:", QCReport_Status, QCReport_ID);
    try {
      const data = {
        ApprovalStatus: status,
      };
      // console.log("data:", data.ApprovalStatus);

      const response = await axios.put(
        `${apiUrl}/QCReport/updateStatusApproval/${QCReport_ID}`,
        data
      );
      // console.log("data:", response);

      {
        status === 1
          ? toast.success("Report has been approved.")
          : toast.warning("Report has been denied.");
      }

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      toast.error("An error has occured!");
      console.log(error);
    }
  };

  return (
    <>
      {isAdminLoggedIn && QCReport_Status === 0 && (
        <div className="flex gap-3 justify-center my-3">
          <button
            className="btn btn-sm btn-success hover:text-white"
            onClick={() => {
              handleStatusChange(1);
            }}
          >
            Approve
          </button>

          <button
            className="btn btn-sm btn-error hover:text-white"
            onClick={() => {
              handleStatusChange(2);
            }}
          >
            Disapprove
          </button>
        </div>
      )}
    </>
  );
};

export default UpdateStatusReport;
