import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../../../ApiUrlAndToken";
import { toast } from "react-toastify";
import { submitButton } from "../../../components/DesignStandardize";
import { Link } from "react-router-dom";
import EngineerIcon from "@mui/icons-material/Engineering";
import AddIcon from "@mui/icons-material/Add";

const AddAssignedEngineer = ({ projectID }) => {
  // console.log(projectID);
  const [engineers, setEngineers] = useState([]);
  const [selectedEngineers, setSelectedEngineers] = useState([]);

  // to fetch avaiable engineers
  const projectEngineers = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/assign/fetchAvailableUsers/${projectID}`
      );
      console.log(response.data);
      setEngineers(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    projectEngineers();
  }, [projectID]);

  const handleCheckboxChange = (event, engineer) => {
    if (event.target.checked) {
      setSelectedEngineers((prevSelected) => [
        ...prevSelected,
        { userID: engineer.ID, Name: engineer.Name },
      ]);
    } else {
      setSelectedEngineers((prevSelected) =>
        prevSelected.filter((e) => e.userID !== engineer.ID)
      );
    }
  };

  useEffect(() => {
    console.log(selectedEngineers);
  }, [selectedEngineers]);

  const ProjectEngineerAssigned = async () => {
    try {
      for (const engineer of selectedEngineers) {
        // console.log(engineer)
        const data = {
          userID: engineer.userID,
          projectID: projectID,
        };
        // console.log(data);
        const response = await axios.post(`${apiUrl}/assign/register`, data);
        // console.log(response.data);
      }
      setTimeout(() => {
        window.location.reload();
      }, 200);
      toast.success("Engineers are assigned successfully!!");
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(`${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response received from the server");
      } else {
        toast.error("An error occurred while processing the request");
      }
    }
  };

  return (
    <div className="flex flex-col gap-3 justify-between">
      {engineers.length > 0 ? (
        <>
          {engineers.map((engineer) => {
            return (
              <div className="flex flex-row gap-3">
                <input
                  type="checkbox"
                  checked={selectedEngineers.some(
                    (e) => e.userID === engineer.ID
                  )}
                  className="checkbox checkbox-sm checkbox-primary"
                  onChange={(event) => handleCheckboxChange(event, engineer)}
                />
                <span className="">{engineer.Name}</span>
                <span className="text-gray-500">{engineer.Email}</span>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <p className="font-semibold text-lg text-success animate-pulse">
            No engineers available!!
          </p>

          <Link
            to={`/admin/employee-list`}
            className={`w-max mx-auto hover:cursor-pointer rounded-full text-green-600 hover:btn-warning btn btn-outline gap-0`}
          >
            <EngineerIcon /> <AddIcon fontSize="small" /> Add Engineer
          </Link>
        </>
      )}

      {engineers.length > 0 && (
        <button
          className={`${submitButton} mt-3 mx-auto`}
          onClick={ProjectEngineerAssigned}
        >
          Save
        </button>
      )}
    </div>
  );
};

export default AddAssignedEngineer;
