import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../../../ApiUrlAndToken";
import GeneralInput from "../../../components/GeneralInput";
import { submitButton } from "../../../components/DesignStandardize";
import { toast } from "react-toastify";

const EditWorkQualityReport = ({ WorkQualityID }) => {
  const [data, setData] = useState({
    TotalWork: "",
    Allignment: "",
    Current_Date: "",
    Accesories: "",
    SiliconIn: "",
    SiliconOut: "",
    Behaviour: "",
    Comment: "",
  });
  const {
    TotalWork,
    Allignment,
    Current_Date,
    Accesories,
    SiliconIn,
    SiliconOut,
    Behaviour,
    Comment,
  } = data;
  console.log(data);
  const getWorkQualityById = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/work-qualities/getWorkByID/${WorkQualityID}`
      );
      //console.log(response.data);

      // Convert Current_Date string to Date object
      const responseData = {
        ...response.data,
        Current_Date: new Date(response.data.Current_Date),
      };
      // console.log(responseData);
      setData(responseData);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getWorkQualityById();
  }, [WorkQualityID]);
  const handleWorkQualityChange = (wq, value) => {
    // console.log(value);
    if (data[wq] === value) {
      setData((prev) => ({ ...prev, [wq]: "" }));
    } else {
      setData((prev) => ({ ...prev, [wq]: value }));

      const oppositeValue = value === "Good" ? "Bad" : "Good";
      // console.log(oppositeValue);
      const otherWQ = `WQ${wq.substring(2)}`;
      if (data[otherWQ] === oppositeValue) {
        setData((prev) => ({ ...prev, [otherWQ]: "" }));
      }
    }
  };
  const updateWorkQuality = async (e) => {
    e.preventDefault();
    try {
      const data = {
        TotalWork,
        Current_Date: new Date().toISOString().split("T")[0],
        Accesories: Accesories === "Good" ? 1 : Accesories === "Bad" ? 0 : 2,
        Allignment: Allignment === "Good" ? 1 : Allignment === "Bad" ? 0 : 2,
        SiliconIn: SiliconIn === "Good" ? 1 : SiliconIn === "Bad" ? 0 : 2,
        SiliconOut: SiliconOut === "Good" ? 1 : SiliconOut === "Bad" ? 0 : 2,
        Behaviour,
        Comment,
      };
      // console.log(data);
      const response = await axios.put(
        `${apiUrl}/work-qualities/updateWorkQuality/${WorkQualityID}`,
        data
      );
      // console.log(response.data);
      toast.success("Work Quality Updated Successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      // console.log(error);
      if (error.response) {
        toast.error(`${error.response.message}`);
      } else if (error.request) {
        toast.error("No response receieved from the server");
      } else {
        toast.error("An error occured");
      }
    }
  };
  return (
    <>
      <form
        onSubmit={updateWorkQuality}
        className="flex flex-col gap-3 items-center mx-auto font-semibold font-Inter text-sm text-left md:text-base"
      >
        <div className="flex flex-row flex-wrap gap-3 w-64 md:w-max max-h-96 overflow-y-auto justify-center p-1">
          <div className="flex flex-col gap-3 md:border-r-[2px] md:pr-3">
            <GeneralInput
              label="সম্পূর্ণ কৃতকাজের পরিমাণ - স্কয়ার ফিট হিসেবে"
              type="text"
              placeholder="..."
              value={TotalWork}
              onChange={(ev) =>
                setData({ ...data, TotalWork: ev.target.value })
              }
            />

            <GeneralInput
              label="আচরণ"
              type="textarea"
              placeholder="..."
              value={Behaviour}
              onChange={(ev) =>
                setData({ ...data, Behaviour: ev.target.value })
              }
            />

            <GeneralInput
              label="মন্তব্য (যদি থাকে)"
              type="textarea"
              placeholder="..."
              value={Comment}
              onChange={(ev) => setData({ ...data, Comment: ev.target.value })}
            />
          </div>

          <div className="w-60 flex flex-col gap-3">
            <div>
              <>
                <p className="font-semibold">এলাইনমেন্ট সঠিক কিনা</p>
                <p className="flex font-normal">
                  {"("}
                  {data.Allignment === 2 ? (
                    <p>-</p>
                  ) : data.Allignment === 1 ? (
                    <p>ভালো</p>
                  ) : (
                    <p>ভালো নয়</p>
                  )}
                  {")"}
                </p>
              </>
              <div className="flex gap-3 justify-between">
                <div
                  className="cursor-pointer label"
                  onClick={() => handleWorkQualityChange("Allignment", "Good")}
                >
                  <span className="label-text mr-2">ভালো</span>
                  <input
                    type="checkbox"
                    className="checkbox checkbox-success"
                    checked={data.Allignment === "Good"}
                    readOnly
                  />
                </div>

                <div
                  className="cursor-pointer label"
                  onClick={() => handleWorkQualityChange("Allignment", "Bad")}
                >
                  <span className="label-text mr-2">ভালো নয়</span>
                  <input
                    type="checkbox"
                    className="checkbox checkbox-error"
                    checked={data.Allignment === "Bad"}
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div>
              <p className="font-semibold">
                এক্সাসরিস সঠিকভাবে লাগানো হয়েছে কিনা
                <p className="flex font-normal">
                  {"("}
                  {data.Accesories === 2 ? (
                    <p>-</p>
                  ) : data.Accesories === 1 ? (
                    <p>ভালো</p>
                  ) : (
                    <p>ভালো নয়</p>
                  )}
                  {")"}
                </p>
              </p>
              <div className="flex gap-3 justify-between">
                <div
                  className="cursor-pointer label"
                  onClick={() => handleWorkQualityChange("Accesories", "Good")}
                >
                  <span className="label-text mr-2">ভালো</span>
                  <input
                    type="checkbox"
                    className="checkbox checkbox-success"
                    checked={data.Accesories === "Good"}
                    readOnly
                  />
                </div>

                <div
                  className="cursor-pointer label"
                  onClick={() => handleWorkQualityChange("Accesories", "Bad")}
                >
                  <span className="label-text mr-2">ভালো নয়</span>
                  <input
                    type="checkbox"
                    className="checkbox checkbox-error"
                    checked={data.Accesories === "Bad"}
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div>
              <p className="font-semibold">
                সিলিকন আউট সাইট সঠিক
                <p className="flex font-normal">
                  {"("}
                  {data.SiliconOut === 2 ? (
                    <p>-</p>
                  ) : data.SiliconOut === 1 ? (
                    <p>ভালো</p>
                  ) : (
                    <p>ভালো নয়</p>
                  )}
                  {")"}
                </p>
              </p>
              <div className="flex gap-3 justify-between">
                <div
                  className="cursor-pointer label"
                  onClick={() => handleWorkQualityChange("SiliconOut", "Good")}
                >
                  <span className="label-text mr-2">ভালো</span>
                  <input
                    type="checkbox"
                    className="checkbox checkbox-success"
                    checked={data.SiliconOut === "Good"}
                    readOnly
                  />
                </div>

                <div
                  className="cursor-pointer label"
                  onClick={() => handleWorkQualityChange("SiliconOut", "Bad")}
                >
                  <span className="label-text mr-2">ভালো নয়</span>
                  <input
                    type="checkbox"
                    className="checkbox checkbox-error"
                    checked={data.SiliconOut === "Bad"}
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div>
              <p className="font-semibold">
                সিলিকন ইন সাইট সঠিক
                <p className="flex font-normal">
                  {"("}
                  {data.SiliconIn === 2 ? (
                    <p>-</p>
                  ) : data.SiliconIn === 1 ? (
                    <p>ভালো</p>
                  ) : (
                    <p>ভালো নয়</p>
                  )}
                  {")"}
                </p>
              </p>

              <div className="flex gap-3 justify-between">
                <div
                  className="cursor-pointer label"
                  onClick={() => handleWorkQualityChange("SiliconIn", "Good")}
                >
                  <span className="label-text mr-2">ভালো</span>
                  <input
                    type="checkbox"
                    className="checkbox checkbox-success"
                    checked={data.SiliconIn === "Good"}
                    readOnly
                  />
                </div>

                <div
                  className="cursor-pointer label"
                  onClick={() => handleWorkQualityChange("SiliconIn", "Bad")}
                >
                  <span className="label-text mr-2">ভালো নয়</span>
                  <input
                    type="checkbox"
                    className="checkbox checkbox-error"
                    checked={data.SiliconIn === "Bad"}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className={`${submitButton} mt-3`}>Update</button>
      </form>
    </>
  );
};

export default EditWorkQualityReport;
