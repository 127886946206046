import React, { useState } from "react";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import AddWorkersIcon from "@mui/icons-material/GroupAdd";
import RemoveIcon from "@mui/icons-material/RemoveCircle";
import GeneralInput from "../../../../components/GeneralInput";
import { submitButton } from "../../../../components/DesignStandardize";
import { toast } from "react-toastify";
import axios from "axios";
import { apiUrl } from "../../../../ApiUrlAndToken";

const AddWorkers = ({ contractorID }) => {
  // console.log(contractorID);
  const [rows, setRows] = useState([
    {
      id: 1,
      Name: "",
      Contact: "",
      Address: "",
      BloodGroup: "",
    },
  ]);

  const addRow = () => {
    const newRow = {
      id: rows.length + 1,
      Name: "",
      Contact: "",
      Address: "",
      BloodGroup: "",
    };
    setRows([...rows, newRow]);
  };
  const handleInputChange = (id, updatedField, value) => {
    const rowIndex = rows.findIndex((row) => row.id === id);
    if (rowIndex !== -1) {
      const updatedRows = [...rows];
      updatedRows[rowIndex][updatedField] = value;
      setRows(updatedRows);
    }
  };
  const deleteRow = (id) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };
  const WorkerInformation = async () => {
    try {
      const hasEmptyFields = rows.some((el) => el.Name === "");

      if (hasEmptyFields) {
        toast.error("Name ofthe workers cannot be empty");
        return;
      }
      for (const item of rows) {
        const data = {
          name: item.Name,
          contact: item.Contact || "-",
          address: item.Address || "-",
          bloodGroup: item.BloodGroup || "-",
          ContractorID: parseInt(contractorID),
        };
        console.log(data);
        const response = await axios.post(
          `${apiUrl}/worker/createWorker`,
          data
        );
        // console.log(response.data);

        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
      toast.success("Information has been added successfully!");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="overflow-auto max-h-96 max-w-[320px] md:max-w-[520px] lg:max-w-[800px]">
        <table className="">
          <thead>
            <tr className="text-base text-black text-center">
              <th />
              <th className="p-1">Name</th>
              <th className="p-1">Contact</th>
              <th className="p-1">Address</th>
              <th className="p-1">Blood Group</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id}>
                <td
                  className={`tooltip tooltip-right tooltip-success sticky left-0 z-40 bg-white ${
                    rows.length === 1 && "mt-2 h-10"
                  }`}
                  data-tip="Add row"
                >
                  <AddIcon
                    onClick={addRow}
                    className="mt-2 hover:text-green-500"
                  />
                </td>
                {rows.length > 1 && (
                  <td
                    className="tooltip tooltip-right tooltip-error sticky left-0 z-40 bg-white"
                    data-tip="Remove row"
                  >
                    <RemoveIcon
                      onClick={() => deleteRow(row.id)}
                      className="mt-2 hover:text-red-500"
                    />
                  </td>
                )}
                <td className="p-0">
                  <GeneralInput
                    type="text"
                    placeholder="Name"
                    value={row.Name}
                    onChange={(ev) =>
                      handleInputChange(row.id, "Name", ev.target.value)
                    }
                    table={1}
                  />
                </td>
                <td className="p-0">
                  <GeneralInput
                    type="text"
                    placeholder="Contact No."
                    value={row.Contact}
                    onChange={(ev) =>
                      handleInputChange(row.id, "Contact", ev.target.value)
                    }
                    table={1}
                  />
                </td>
                <td className="p-0">
                  <GeneralInput
                    type="text"
                    placeholder="Address"
                    value={row.Address}
                    onChange={(ev) =>
                      handleInputChange(row.id, "Address", ev.target.value)
                    }
                    table={1}
                  />
                </td>
                <td className="p-0">
                  <GeneralInput
                    type="text"
                    placeholder="BloodGroup"
                    value={row.BloodGroup}
                    onChange={(ev) =>
                      handleInputChange(row.id, "BloodGroup", ev.target.value)
                    }
                    min={0}
                    table={1}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        className={`${submitButton} mt-3 mx-auto`}
        onClick={WorkerInformation}
      >
        <AddWorkersIcon className="text-white -mt-2" /> Add
      </button>
    </>
  );
};

export default AddWorkers;
