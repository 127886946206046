import React, { useEffect, useState } from "react";
import { submitButton } from "../../../../components/DesignStandardize";
import axios from "axios";
import { apiUrl } from "../../../../ApiUrlAndToken";
import { toast } from "react-toastify";

const RateWorkers = ({ projectID }) => {
  const projID = parseInt(projectID.ProjectID);
  const [workerRatings, setWorkerRatings] = useState([]);

  const getAssignedWorkersInfo = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/assignWorker/getAssignedWorker/${projID}`
      );
      setWorkerRatings(
        response.data.Workers.map((worker) => ({ ...worker, rate: null }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAssignedWorkersInfo();
  }, [projectID]);

  const handleRatingChange = (workerId, rating) => {
    setWorkerRatings((prevRatings) =>
      prevRatings.map((worker) =>
        worker.worker_id === workerId ? { ...worker, rate: rating } : worker
      )
    );
  };

  const handleRatingSubmit = async () => {
    try {
      const promises = workerRatings.map(async (worker) => {
        if (worker.rate !== null) {
          const data = {
            rating: worker.rate,
          };
          const response = await axios.put(
            `${apiUrl}/assignWorker/updateRating/${worker.worker_id}/${projID}`,
            data
          );
          return response.data;
        }
      });

      const results = await Promise.all(promises);

      toast.success("Ratings updated Successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(`${error.response.request.statusText}`);
      } else if (error.request) {
        toast.error("No response received from the server");
      }
    }
  };

  return (
    <>
      <div className="max-h-96 overflow-y-auto">
        {workerRatings.map((worker, index) => (
          <div
            key={worker.id}
            className={`flex items-center justify-between text-left p-1 ${
              index % 2 === 0 ? "bg-blue-50" : "bg-inherit"
            }`}
          >
            <div className="w-20">
              {worker.name} {"("}Rating: {worker.rating}
              {")"}
            </div>
            <div className="rating rating-md">
              {[1, 2, 3, 4, 5].map((rating) => (
                <input
                  key={rating}
                  type="radio"
                  name={`rating-${worker.worker_id}`}
                  className={`mask mask-star-2 hover:bg-blue-300 ${
                    worker.rate === null ? "bg-slate-300" : "bg-blue-500"
                  } `}
                  checked={worker.rate === rating}
                  onChange={() => {
                    handleRatingChange(worker.worker_id, rating);
                  }}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      <button
        className={`${submitButton} mt-3 mx-auto`}
        onClick={handleRatingSubmit}
      >
        Submit
      </button>
    </>
  );
};

export default RateWorkers;
