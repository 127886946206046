import React, { useEffect, useState } from "react";
import complete from "../../assets/img/icons/complete.jpg";
import axios from "axios";
import { apiUrl } from "../../ApiUrlAndToken";
import EngineerIcon from "@mui/icons-material/Engineering";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/BorderColor";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { hoverScale } from "../../components/DesignStandardize";
import { Link } from "react-router-dom";
import AddAssignedEngineer from "./components/AddAssignedEngineer";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import EditProject from "./components/EditProject";
import LocationIcon from "@mui/icons-material/LocationOn";
import DetailsModalProject from "./components/DetailsModalProject";
import GeneralModal from "../../components/GeneralModal";
import DeleteProject from "./components/DeleteProject";

const CompletedProjects = () => {
  const [projects, setProjects] = useState([]);
  const [projectID, setProjectID] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [project, setProject] = useState("");

  const getCompletedProjects = async () => {
    try {
      const data = {
        status: 2,
      };
      const response = await axios.get(
        `${apiUrl}/project/fetchAllProjectByStatus?status=${data.status}`
      );
      // console.log(response.data.result);
      setProjects(response.data.result);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCompletedProjects();
  }, []);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  const AddAssignedEngineerModal = (id, name) => {
    setProjectID(id);
    setProjectName(name);
    toggleModal();
  };

  const [editModal, setEditModal] = useState(false);
  const toggleEditModal = () => {
    setEditModal((prevShowModal) => !prevShowModal);
  };
  const EditProjectModal = (id, name) => {
    setProjectID(id);
    setProjectName(name);
    toggleEditModal();
  };

  const [detailsModal, setDetailsModal] = useState(false);
  const toggleDetailsModal = () => {
    setDetailsModal((prevShowModal) => !prevShowModal);
  };
  const ProjectDetailsModal = (project) => {
    setProject(project);
    toggleDetailsModal();
  };
  return (
    <>
      <div className="text-left w-11/12 mx-auto">
        <p className="text-xl divider divider-start divider-success h-10 font-bold">
          <img
            src={complete}
            alt=""
            className="w-10 h-10 rounded-full shadow shadow-green-200"
          />
          Completed Projects
        </p>

        <div className="flex flex-row flex-nowrap md:flex-wrap gap-4 overflow-x-auto md:overflow-x-hidden">
          {projects.map((el, i) => {
            return (
              <div
                key={i}
                className={`p-4 w-60 border-[1px] border-solid rounded-xl bg-slate-100 flex flex-col gap-3 hover:shadow mb-2`}
              >
                <div className="flex justify-between gap-3 border-b-[1px] border-solid pb-1">
                  {/* Project Name */}
                  <p className="text-xl font-semibold hover:cursor-pointer w-36 truncate">
                    <Link to={`/admin/${el.ProjectName}/${el.ProjectID}`}>
                      {el.ProjectName}
                    </Link>
                  </p>

                  <div className="flex gap-1">
                    <DeleteProject
                      projectId={el.ProjectID}
                      projectName={el.ProjectName}
                    />

                    {/* Edit button to edit project */}
                    <div
                      onClick={() =>
                        EditProjectModal(el.ProjectID, el.ProjectName)
                      }
                      className={`hover:cursor-pointe rounded-full ${hoverScale} text-blue-600 hover:text-blue-700 `}
                    >
                      <EditIcon fontSize="1px" />
                    </div>

                    {/* Arrow to go to inside of a project */}
                    <Link
                      className={`hover:cursor-pointer rounded-full text-slate-900 hover:text-black hover:bg-slate-300 p-[2px] hover:translate-x-1 transition-all duration-300`}
                      to={`/admin/${el.ProjectName}/${el.ProjectID}`}
                    >
                      <ArrowForwardIcon />
                    </Link>
                  </div>
                </div>

                {/* Image of thr project */}
                {el.Uploads ? (
                  <Link to={`/admin/${el.ProjectName}/${el.ProjectID}`}>
                    <img
                      className={`w-full h-32 hover:cursor-pointer rounded-xl`}
                      src={`${apiUrl}/uploads/${el.Uploads}`}
                      alt="Profile"
                    />
                  </Link>
                ) : (
                  <Link
                    to={`/admin/${el.ProjectName}/${el.ProjectID}`}
                    className={`w-full h-32  ${
                      i % 2 === 0 ? "bg-[#d3e0cc]" : "bg-[#a1a0a6]"
                    } rounded-xl`}
                  />
                )}

                <div
                  onClick={() =>
                    AddAssignedEngineerModal(el.ProjectID, el.ProjectName)
                  }
                  className={`w-max mx-auto hover:cursor-pointer rounded-full text-green-600 hover:btn-warning btn btn-outline gap-0`}
                >
                  <EngineerIcon /> <AddIcon fontSize="small" />
                  Engineers
                </div>

                <div>
                  <div className="grid grid-cols-2">
                    <p className="text-sm text-slate-400">Start Date</p>
                    <p>: {el.Start_Date.split("T")[0]}</p>
                    <p className="text-sm text-slate-400">End Date</p>
                    <p>: {el.End_Date.split("T")[0]}</p>
                  </div>
                  <p className="truncate flex items-center">
                    <LocationIcon fontSize="1px" />
                    <p className="">{el.Location}</p>
                  </p>
                  <div
                    onClick={() => ProjectDetailsModal(el)}
                    className={`hover:cursor-pointe btn btn-outline btn-sm mt-3`}
                  >
                    <VisibilityIcon fontSize="1px" /> View Details
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Modal to assign engineer */}
      {modal && projectID && projectName && (
        <GeneralModal
          title={`Assign Engineer to ${projectName}`}
          onClose={toggleModal}
        >
          <AddAssignedEngineer
            projectID={projectID}
            onCancel={() => setProjectID(null)}
          />
        </GeneralModal>
      )}

      {/* Modal to edit project */}
      {editModal && projectID && projectName && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleEditModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                Edit {projectName} -{" "}
                <span className="text-success">Completed</span>
              </p>
              <CloseIcon
                onClick={toggleEditModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
            <EditProject projectID={projectID} />
          </div>
        </div>
      )}

      {/* Modal to see details of a project */}
      {detailsModal && project && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleDetailsModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                {project.ProjectName} -{" "}
                <span className="text-warning">Ongoing</span>
              </p>
              <CloseIcon
                onClick={toggleDetailsModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>

            <DetailsModalProject project={project} />
          </div>
        </div>
      )}
    </>
  );
};

export default CompletedProjects;
