import React, { useEffect, useState } from "react";
import PageTitle from "../pageTitle/PageTitle";
import { Link, useParams } from "react-router-dom";
import { apiUrl, isEmployeeLoggedIn } from "../../ApiUrlAndToken";
import axios from "axios";
import ScopeWork from "./scopeOfWork/ScopeWork";
import "../../components/Scrollbar.css";
import UpcomingScopeOfWork from "./components/UpcomingScopeOfWork";
import OngoingScopeOfWork from "./components/OngoingScopeOfWork";
import CompletedScopeOfWork from "./components/CompletedScopeOfWork";
import WorkQualityReport from "./workQualityReport/WorkQualityReport";
import bg from "../../assets/img/bg.avif";

const ProjectIndividual = () => {
  const id = useParams();
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState("");

  const getProjectByID = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/project/fetchProject/${id.ProjectID}`
      );
      setProject(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (id.ProjectID) getProjectByID();
  }, [id.ProjectID]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const titles = `${id.ProjectName} - ${
    parseInt(project.Status) === 0
      ? "Upcoming"
      : parseInt(project.Status) === 1
      ? "Ongoing"
      : parseInt(project.Status) === 2
      ? "Completed"
      : ""
  }`;

  return (
    <div className="overflow-y-hidden">
      <PageTitle title={titles} insideProject={1} />

      <div
        className="pt-16 md:pt-12 lg:pt-0 md:ml-40 flex flex-col gap-6 font-Inter h-screen overflow-y-hidden"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {loading ? (
          <div
            className={`mt-16 animate-spin rounded-full h-16 w-16 border-t-4 ${
              parseInt(project.Status) === 0
                ? "border-blue-500"
                : parseInt(project.Status) === 1
                ? "border-orange-500"
                : "border-green-500"
            }  border-solid m-auto`}
          />
        ) : (
          <div>
            <div className=" mb-3 w-max mx-auto mt-12 md:mt-14 flex">
              <p className={`font-bold border-b-2 border-black px-2 pb-1`}>
                Scope of Work
              </p>
              <Link
                to={`/${
                  isEmployeeLoggedIn ? "employee" : "admin"
                }/material-requisition/${id.ProjectName}/${id.ProjectID}`}
                className={`px-2 pb-1 border-b-2 border-slate-400 hover:border-slate-700`}
              >
                Material Requisition
              </Link>
              <Link
                to={`/${isEmployeeLoggedIn ? "employee" : "admin"}/workflow/${
                  id.ProjectName
                }/${id.ProjectID}`}
                className={`px-2 pb-1 border-b-2 border-slate-400 hover:border-slate-700`}
              >
                Workflow
              </Link>
            </div>

            <div className="px-2 grid grid-cols-[280px_280px_280px_280px_280px] lg:grid-cols-[280px_280px_280px_280px_1fr] gap-1 overflow-x-auto md:overflow-x-visible">
              <ScopeWork id={id.ProjectID} />
              <UpcomingScopeOfWork id={id.ProjectID} />
              <OngoingScopeOfWork id={id.ProjectID} />
              <CompletedScopeOfWork id={id.ProjectID} />
              <WorkQualityReport project={project} />
              {/* <DailyUpdates /> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectIndividual;
