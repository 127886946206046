import React, { useState } from "react";
import { apiUrl } from "../../../ApiUrlAndToken";
import axios from "axios";
import { toast } from "react-toastify";
import dtechLogo from "../../../assets/img/dtechLogo.png";
import GeneralInput from "../../../components/GeneralInput";
import { submitButton } from "../../../components/DesignStandardize";

const AdminReg = () => {
  const [name, SetName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const AdminReg = async (e) => {
    e.preventDefault();
    try {
      const data = {
        name: name,
        email: email,
        password: password,
      };
      const response = await axios.post(`${apiUrl}/admin/register`, data);
      // console.log(response.status);
      if (response.status === 201) {
        toast.success("Admin Registration Done Successfully!!!");
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      } else if (response.status === 409) {
        toast.error("Admin Already exists");
      }
    } catch (error) {
      toast.error("Admin already exists");
    }
  };

  return (
    <div className="w-screen h-screen flex flex-col gap-3 items-center justify-center bg-blue-100">
      <img src={dtechLogo} alt="DTech Corp. LTD" className="h-[100px] mb-5" />
      <div className="flex flex-col gap-3">
        <p className="text-xl text-blue-900 font-medium">Admin Registration</p>
        <div className="card w-96 skeleton border-2 border-solid shadow-md">
          <div className="card-body p-5">
            <form
              className="flex flex-col gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
              onSubmit={AdminReg}
            >
              <GeneralInput
                label="New Admin Name"
                labelSpanText="*"
                type="text"
                placeholder="Enter Admin Name"
                value={name}
                onChange={(ev) => SetName(ev.target.value)}
              />

              <GeneralInput
                label="New Admin Email"
                labelSpanText="*"
                type="email"
                placeholder="Enter Admin Email"
                value={email}
                onChange={(ev) => setEmail(ev.target.value)}
              />

              <GeneralInput
                label="New Admin Password"
                labelSpanText="*"
                type="password"
                placeholder="Enter Admin Password"
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
              />

              {name !== "" && email !== "" && password !== "" && (
                <button className={`${submitButton} mt-3`}>Register</button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminReg;
