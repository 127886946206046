import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { apiUrl } from "../../../ApiUrlAndToken";
import { submitButton } from "../../../components/DesignStandardize";
import GeneralInput from "../../../components/GeneralInput";
// import { useAuth } from "../../../AuthContext/AuthContext";
const AdminLogin = () => {
  // const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const AdminLogin = async (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      toast.error("Please fill the required fields");
      return;
    }
    try {
      const data = {
        email,
        password,
      };
      const response = await axios.post(`${apiUrl}/admin/login`, data);
      // console.log(response.data);
      if (response.status === 200) {
        const { token, admin } = response.data;
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("admin", JSON.stringify(admin));

        const decodedToken = parseJwt(token);
        // console.log(decodedToken);
        setTimeout(() => {
          window.location.href = "/admin/dashboard";
        }, 500);
      }
    } catch (error) {
      console.log(error);

      if (error.response) {
        toast.error(`${error.response.data}`);
      } else if (error.request) {
        toast.error("No response received from the server");
      } else {
        toast.error("An error occurred while processing the request");
      }
    }
  };
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  return (
    <div className="flex flex-col gap-3">
      <p className="text-xl text-blue-900 font-medium">Admin Login</p>
      <div className="card w-96 skeleton border-2 border-solid shadow-md">
        <div className="card-body p-5">
          <form
            className="flex flex-col gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
            onSubmit={AdminLogin}
          >
            <GeneralInput
              label="Admin Email"
              type="email"
              placeholder="Enter Admin Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <GeneralInput
              label="Password"
              type="password"
              placeholder="Enter Admin Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <button className={`${submitButton} mt-3`}>Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
