import React, { useState } from "react";
import { submitButton } from "../../../../components/DesignStandardize";
import GeneralInput from "../../../../components/GeneralInput";
import { apiUrl } from "../../../../ApiUrlAndToken";
import axios from "axios";
import { toast } from "react-toastify";

const UpdateImageRequest = ({ userID, project, requestId }) => {
  const [file, setFile] = useState(null);

  const handleUpdateImageRequest = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("ProjectID", project);
      formData.append("AssignID", userID);
      formData.append("ReqStatus", 0);

      if (file !== null) {
        formData.append("Picture", file);
      }

      const response = await axios.put(
        `${apiUrl}/request/update/${requestId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Material Request Updated Successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(`${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response received from the server");
      } else {
        toast.error("An error occurred while processing the request");
      }
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <div className="p-1 flex flex-col gap-3 itemsOfSow-center min-h-max max-h-[440px] overflow-y-auto mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base">
      <GeneralInput
        label="Change Image for Request"
        type="file"
        onChange={handleFileChange}
        accept="image/*"
      />

      <button
        className={`${submitButton} mt-3 mx-auto`}
        type="submit"
        onClick={handleUpdateImageRequest}
      >
        Update
      </button>
    </div>
  );
};

export default UpdateImageRequest;
