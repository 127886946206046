import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../../../../ApiUrlAndToken";
import GeneralInput from "../../../../components/GeneralInput";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import SendIcon from "@mui/icons-material/Send";
import RemoveIcon from "@mui/icons-material/RemoveCircle";
import { submitButton } from "../../../../components/DesignStandardize";
import { toast } from "react-toastify";
const EditRequest = ({ editID }) => {
  // console.log(editID);
  const [rows, setRows] = useState([]);

  const addRow = () => {
    const newRow = {
      id: rows.length + 1,
      itemName: "",
      amountExpected: 0,
      expectedDate: "",
      thickness: "",
      size: "",
      remarks: "",
    };
    setRows([...rows, newRow]);
  };

  const deleteRow = (id) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };

  const handleInputChange = (id, updatedField, value) => {
    const rowIndex = rows.findIndex((row) => row.id === id);
    if (rowIndex !== -1) {
      const updatedRows = [...rows];
      // console.log(updatedRows);
      updatedRows[rowIndex][updatedField] = value;
      setRows(updatedRows);
      // console.log(rows);
    }
  };
  const [materials, setMaterials] = useState([]);
  const getRequestInfo = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/materialReq/getAllByMRID/${editID}`
      );
      // console.log(response.data.RequestedItems);
      setMaterials(response.data.RequestedItems);
      const initialRows = response.data.RequestedItems.map((item, index) => ({
        id: index + 1,
        itemID: item.RequestedItemID,
        itemName: item.ItemName,
        amountExpected: item.AmountExpected,
        expectedDate: item.ExpectedDate,
        thickness: item.Thickness,
        size: item.SIZE,
        remarks: item.REMARKS,
      }));
      // console.log(initialRows);
      setRows(initialRows);
    } catch (error) {
      console.log(error);
    }
  };
  const updateInfo = async () => {
    try {
      // console.log(rows);
      for (const item of rows) {
        const data = {
          ItemName: item.itemName,
          AmountExpected: item.amountExpected,
          ExpectedDate: item.expectedDate,
          Thickness: item.thickness,
          SIZE: item.size,
          remarks: item.remarks,
        };
        // console.log(data);
        const response = await axios.put(
          `${apiUrl}/requestedItem/updateRequestedItemInformation/${item.itemID}`,
          data
        );
        // console.log(response.data);
        toast.success("Material Information Updated Successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      if (error.response) {
        toast.error(`${error.response.request.StatusText}`);
      }
    }
  };
  useEffect(() => {
    getRequestInfo();
  }, [editID]);

  return (
    <div>
      <div className="overflow-x-auto max-w-[320px] md:max-w-[520px] lg:max-w-[800px]">
        <table className="">
          <thead>
            <tr className="text-base text-black text-center">
              <th />
              <th className="p-1">Item Name</th>
              <th className="p-1">Thickness</th>
              <th className="p-1">Size</th>
              <th className="p-1">Amount Requested</th>
              <th className="p-1">Date Expected</th>
              <th className="p-1">Remarks</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id}>
                <td
                  className="tooltip tooltip-right tooltip-success"
                  data-tip="Add row"
                >
                  <AddIcon
                    onClick={addRow}
                    className="mt-2 hover:text-green-500"
                  />
                </td>
                {rows.length > 1 && (
                  <td
                    className="tooltip tooltip-right tooltip-error"
                    data-tip="Remove row"
                  >
                    <RemoveIcon
                      onClick={() => deleteRow(row.id)}
                      className="mt-2 hover:text-red-500"
                    />
                  </td>
                )}
                <td className="p-0">
                  <GeneralInput
                    type="text"
                    placeholder="Item Name"
                    value={row.itemName}
                    onChange={(ev) =>
                      handleInputChange(row.id, "itemName", ev.target.value)
                    }
                    table={1}
                  />
                </td>
                <td className="p-0">
                  <GeneralInput
                    type="text"
                    placeholder="Thickness"
                    value={row.thickness}
                    onChange={(ev) =>
                      handleInputChange(row.id, "thickness", ev.target.value)
                    }
                    table={1}
                  />
                </td>
                <td className="p-0">
                  <GeneralInput
                    type="text"
                    placeholder="Size"
                    value={row.size}
                    onChange={(ev) =>
                      handleInputChange(row.id, "size", ev.target.value)
                    }
                    table={1}
                  />
                </td>
                <td className="p-0">
                  <GeneralInput
                    type="number"
                    placeholder="Receieved Amount"
                    value={row.amountExpected}
                    onChange={(ev) =>
                      handleInputChange(
                        row.id,
                        "amountExpected",
                        ev.target.value
                      )
                    }
                    table={1}
                  />
                </td>
                <td className="p-0">
                  <GeneralInput
                    type="date"
                    value={row.expectedDate}
                    onChange={(ev) =>
                      handleInputChange(row.id, "expectedDate", ev.target.value)
                    }
                    table={1}
                  />
                </td>
                <td className="p-0">
                  <GeneralInput
                    type="text"
                    value={row.remarks}
                    onChange={(ev) =>
                      handleInputChange(row.id, "remarks", ev.target.value)
                    }
                    table={1}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button className={`${submitButton} mt-3 mx-auto`} onClick={updateInfo}>
        <SendIcon fontSize="1px" className="text-white -rotate-45 -mt-2" />
        Send Request{" "}
      </button>
    </div>
  );
};

export default EditRequest;
