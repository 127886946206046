import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { apiUrl } from "../../../ApiUrlAndToken";
import axios from "axios";
import { toast } from "react-toastify";

const DeleteProject = ({ projectId, projectName }) => {
  const handleDelete = async () => {
    if (window.confirm(`Are you sure you want to delete ${projectName}?`)) {
      console.log(`${apiUrl}/project/deleteProject/${projectId}`);
      try {
        await axios.delete(`${apiUrl}/project/deleteProject/${projectId}`);

        window.location.reload();
        toast.warning("Project has been deleted!!");
      } catch (error) {
        console.error(error);
      }
    }
  };
  return (
    <button className="hover:text-red-500 -mt-1" onClick={() => handleDelete()}>
      <DeleteIcon fontSize="small" />
    </button>
  );
};

export default DeleteProject;
