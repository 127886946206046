import axios from "axios";
import React, { useState, useEffect } from "react";
import { apiUrl } from "../../../../ApiUrlAndToken";
import GeneralInput from "../../../../components/GeneralInput";
import { submitButton } from "../../../../components/DesignStandardize";
import { toast } from "react-toastify";

const EditReport = ({ reportId }) => {
  const [data, setData] = useState({
    AmountGood: "",
    ReportDate: "",
    Thickness: "",
    SIZE: "",
    Quality: "",
    Remarks: "",
  });
  const { AmountGood, ReportDate, Thickness, SIZE, Quality, Remarks } = data;
  const getQualityControlReport = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/QCReport/fetchQCReportByID/${reportId}`
      );
      console.log(response.data.qcReport);
      setData(response.data.qcReport);
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("No response receieved from the server");
      }
    }
  };
  useEffect(() => {
    getQualityControlReport();
  }, [reportId]);
  const updateItemInfo = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${apiUrl}/QCReport/updateQCReportByID/${reportId}`,
        data
      );
      console.log(response.data);
      toast.success("Information Updated Successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form
      className="flex flex-col gap-3 items-center mx-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={updateItemInfo}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 items-center max-h-96 h-max overflow-y-auto p-1">
        <GeneralInput
          type="text"
          label="Thickness"
          value={Thickness}
          onChange={(ev) => setData({ ...data, Thickness: ev.target.value })}
          required
        />
        <GeneralInput
          type="text"
          label="Size"
          value={SIZE}
          onChange={(ev) => setData({ ...data, SIZE: ev.target.value })}
          required
        />
        <GeneralInput
          label="Amount Receieved"
          type="number"
          placeholder="Enter Amount"
          value={AmountGood}
          onChange={(ev) => setData({ ...data, AmountGood: ev.target.value })}
          required
        />
        <GeneralInput
          label="Report Date"
          type="date"
          placeholder="Enter Report Date"
          value={ReportDate}
          onChange={(ev) => setData({ ...data, ReportDate: ev.target.value })}
          required
        />
        <GeneralInput
          label="Quality"
          type="text"
          placeholder="Enter Quality"
          value={Quality}
          onChange={(ev) => setData({ ...data, Quality: ev.target.value })}
          required
        />
        <GeneralInput
          label="Remarks"
          type="text"
          placeholder="Enter Remarks"
          value={Remarks}
          onChange={(ev) => setData({ ...data, Remarks: ev.target.value })}
          required
        />
      </div>
      <button className={`${submitButton} mt-3`}>Update Details</button>
    </form>
  );
};

export default EditReport;
