import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl, isEmployeeLoggedIn } from "../../../../ApiUrlAndToken";
import EditIcon from "@mui/icons-material/BorderColor";
import UpdateStatusReport from "./UpdateStatusReport";
import EditReport from "./EditReport";
import DownloadIcon from "@mui/icons-material/CloudDownloadOutlined";
import UpdateImageReport from "./UpdateImageReport";
import UpdateStatusImageReport from "./UpdateStatusImageReport";
import GeneralModal from "../../../../components/GeneralModal";
import { handleDownloadImageRequest } from "../request/RequestList";
import ImageModal from "../../../../components/ImageModal";

const ReportList = ({ project, userID }) => {
  const [materials, setMaterials] = useState([]);
  const [requestedItemNames, setRequestedItemNames] = useState([]);
  const [materialsImage, setMaterialsImage] = useState([]);
  const [assignData, setAssignData] = useState([]);

  // edit item
  const [eachItemEditModal, setEachItemEditModal] = useState(false);
  const [editItemID, setEditItemID] = useState(null);
  const toggleModal = () => {
    setEachItemEditModal((prevShowModal) => !prevShowModal);
  };
  const handleEditItem = (itemId) => {
    setEditItemID(itemId);
    toggleModal();
  };

  const fetchAssignUserData = async () => {
    try {
      const assignDataResponse = await axios.get(
        `${apiUrl}/assign/fetchAssignIDUserID/${project}`
      );
      setAssignData(assignDataResponse.data);
    } catch (error) {
      console.error("Error fetching assign data:", error);
    }
  };
  useEffect(() => {
    fetchAssignUserData();
  }, [project]);

  // By Jamil Reza
  // const getAllRequestsss = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${apiUrl}/materialReq/getAllMRbyProjectID/${project}`
  //     );
  //     // console.log(response.data)
  //     const mrIds = response.data.requestItemArr.map((el) => el.MR_ID);
  //     const assignIDs = response.data.requestItemArr.map((el) => el.AssignedID);
  //     // const names = Promise.all((el)=>getUserName(el.AssignID));
  //     const names = response.data.requestItemArr.map((el) => el.Name);
  //     // console.log(names);
  //     const promises = mrIds.map((MR_ID) =>
  //       axios.get(`${apiUrl}/QCReport/fetchQCReportByMRID/${MR_ID}`)
  //     );
  //     const responses = await Promise.all(promises);
  //     // console.log(responses);
  //     const filteredResults = responses.flatMap((response, index) => {
  //       const MR_ID = mrIds[index];
  //       const AssignID = assignIDs[index];
  //       const Names = names[index];
  //       return response.data.filteredResult.map((report) => ({
  //         ...report,
  //         MR_ID,
  //         AssignID,
  //         Names,
  //       }));
  //     });

  //     setMaterials(filteredResults);
  //     // console.log(filteredResults);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getAllRequests = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/materialReq/getAllMRbyProjectID/${project}`
      );
      const requestItemArr = response.data.requestItemArr;

      // Fetch QC report data for each MR ID
      const promises = requestItemArr.map((material) =>
        axios.get(`${apiUrl}/QCReport/fetchQCReportByMRID/${material.MR_ID}`)
      );
      const responses = await Promise.all(promises);

      // Combine material data with QC report data and add UserID
      const materialsWithUserID = responses.flatMap((response, index) => {
        const material = requestItemArr[index];
        const filteredResult = response.data.filteredResult;
        const assignInfo = assignData.find(
          (assign) => assign.AssignID === material.AssignedID
        );
        const userID = assignInfo ? assignInfo.UserID : null;

        // Add UserID to each report in filteredResult
        const reportsWithUserID = filteredResult.map((report) => ({
          ...report,
          MR_ID: material.MR_ID,
          AssignID: material.AssignedID,
          Names: material.Name,
          UserID: userID,
        }));

        return reportsWithUserID;
      });

      setMaterials(materialsWithUserID);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllImageReportsByProjectID = async () => {
    try {
      const response = await axios.get(`${apiUrl}/report/project/${project}`);
      const imageReports = response.data;

      // Fetch user information for each AssignID
      const userPromises = imageReports.map(async (imageReports) => {
        try {
          const userResponse = await axios.get(
            `${apiUrl}/assign/fetchUserIDName/${imageReports.AssignID}`
          );
          return {
            ...imageReports,
            userName: userResponse.data.Name,
            UserID: userResponse.data.UserID,
          };
        } catch (error) {
          console.log("Error fetching user info:", error);
          return { ...imageReports, user: null }; // Handle error case
        }
      });

      // Wait for all user information requests to complete
      const imageReportsWithUser = await Promise.all(userPromises);

      setMaterialsImage(imageReportsWithUser);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (assignData.length > 0) {
      getAllRequests();
    }
    getAllImageReportsByProjectID();
  }, [project, assignData]);

  useEffect(() => {
    const getRequestedItemNames = async () => {
      try {
        const requestedItemIDs = materials
          .map((obj) => (obj["0"] ? obj["0"]["RequestedItemID"] : undefined))
          .filter((id) => id !== undefined);

        let responseData = [];
        for (const el of requestedItemIDs) {
          const response = await axios.get(
            `${apiUrl}/requestedItem/fetchItemByID/${el}`
          );
          responseData = responseData.concat(
            response.data.map((el1) => ({
              itemId: el,
              ItemName: el1.ItemName,
              ExThickness: el1.Thickness,
              ExSize: el1.SIZE,
              ExAmount: el1.AmountExpected,
              ExDate: el1.ExpectedDate,
            }))
          );
        }
        setRequestedItemNames(responseData);
      } catch (error) {
        console.error("Error fetching requested item names:", error);
      }
    };

    if (materials.length > 0) {
      getRequestedItemNames();
      // getQualityControlData();
    }
  }, [materials]);

  const groupedMaterials = materials.reduce((acc, item) => {
    const key = `${item.MR_ID}_${item.AssignID}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    // console.log(acc)
    return acc;
  }, {});

  // edit image report
  const [editImgReportId, setEditImgReportId] = useState(null);
  const [editImgRequestId, setEditImgRequestId] = useState(null);
  const [editImgReportModal, setEditImgReportModal] = useState(false);
  const toggleEditImgReportModal = () => {
    setEditImgReportModal((prevShowModal) => !prevShowModal);
  };
  const handleEditImgRequest = (reportId, requestId) => {
    setEditImgReportId(reportId);
    setEditImgRequestId(requestId);
    toggleEditImgReportModal();
  };

  const [modalImageUrl, setModalImageUrl] = useState(null);
  const openModal = (imageUrl) => {
    setModalImageUrl(imageUrl);
  };
  const closeModal = () => {
    setModalImageUrl(null);
  };
  return (
    <>
      <div className="min-w-96 overflow-x-auto h-screen">
        <div className="my-2 flex flex-col gap-3">
          {/* For table report */}
          {Object.entries(groupedMaterials).map(([MR_ID, reports]) => (
            <div key={MR_ID} className="flex flex-col gap-3">
              {reports.map((report, index) => (
                <div key={index}>
                  {Object.values(report).map(
                    (innerReport, innerIndex) =>
                      typeof innerReport === "object" && (
                        <div
                          key={innerIndex}
                          className={`w-full border-[1px] border-solid rounded-lg hover:shadow p-2 ${
                            innerReport.ApprovalStatus === 0
                              ? "bg-slate-50"
                              : innerReport.ApprovalStatus === 1
                              ? "bg-green-100"
                              : "bg-red-100"
                          }`}
                        >
                          <div className="flex mb-3">
                            <div className="flex flex-col gap-1 text-left flex-1">
                              <p>
                                Report for Request ID:{" "}
                                <span className="font-semibold">
                                  {groupedMaterials[MR_ID][0].MR_ID}
                                </span>
                              </p>
                              <p>
                                Report From:{" "}
                                <span className="font-semibold">
                                  {groupedMaterials[MR_ID][0].Names}
                                </span>
                              </p>
                              <p>
                                Item Name:{" "}
                                <span className="font-semibold">
                                  {requestedItemNames
                                    .filter(
                                      (item) =>
                                        item.itemId ===
                                        innerReport.RequestedItemID
                                    )
                                    .map((item) => item.ItemName)}
                                </span>
                              </p>
                            </div>

                            {/* Status badge */}
                            <div
                              className={`badge ${
                                innerReport.ApprovalStatus === 0
                                  ? "badge-info"
                                  : innerReport.ApprovalStatus === 1
                                  ? "badge-success"
                                  : "badge-error"
                              } gap-2 ml-auto`}
                            >
                              {innerReport.ApprovalStatus === 0
                                ? "Pending"
                                : innerReport.ApprovalStatus === 1
                                ? "Approved"
                                : "Disapproved"}
                            </div>

                            {/* Button to edit the request */}
                            {isEmployeeLoggedIn &&
                              innerReport.ApprovalStatus === 0 &&
                              groupedMaterials[MR_ID][0].UserID === userID && (
                                <button
                                  className="mb-auto"
                                  onClick={() => {
                                    handleEditItem(
                                      innerReport.QualityControlReportID
                                    );
                                  }}
                                >
                                  <EditIcon
                                    className="hover:text-blue-500 text-black ml-2"
                                    fontSize="inherit"
                                  />
                                </button>
                              )}
                          </div>

                          <UpdateStatusReport
                            QCReport_ID={innerReport.QualityControlReportID}
                            QCReport_Status={innerReport.ApprovalStatus}
                          />

                          {/* Display details for the requested item */}
                          <div className="grid grid-cols-3 border border-slate-300">
                            <div>
                              <p> - </p>
                              <p className="border-y border-slate-300">
                                Thickness
                              </p>
                              <p>Size</p>
                              <p className="border-y border-slate-300">
                                Amount
                              </p>
                              <p>Date</p>
                            </div>
                            {requestedItemNames.map((item) => {
                              if (item.itemId === innerReport.RequestedItemID) {
                                return (
                                  <div
                                    key={item.itemId}
                                    className="border-x border-slate-300"
                                  >
                                    <p>Expected</p>
                                    <p className="font-semibold border-y border-slate-300">
                                      {item.ExThickness}
                                    </p>
                                    <p className="font-semibold">
                                      {item.ExSize}
                                    </p>
                                    <p className="font-semibold border-y border-slate-300">
                                      {item.ExAmount}
                                    </p>
                                    <p className="font-semibold">
                                      {item.ExDate}
                                    </p>
                                  </div>
                                );
                              }
                            })}
                            {requestedItemNames.map((item) => {
                              if (item.itemId === innerReport.RequestedItemID) {
                                return (
                                  <div key={item.itemId} className="">
                                    <p>Arrived</p>

                                    <p className="font-semibold border-y border-slate-300">
                                      {innerReport.Thickness}
                                    </p>
                                    <p className="font-semibold">
                                      {innerReport.SIZE}
                                    </p>
                                    <p className="font-semibold border-y border-slate-300">
                                      {innerReport.AmountGood}
                                    </p>
                                    <p className="font-semibold">
                                      {innerReport.ReportDate}
                                    </p>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )
                  )}
                </div>
              ))}
            </div>
          ))}

          {/* For image report */}
          {materialsImage.map((material, index) => (
            <div
              key={index}
              className={`w-full border-[1px] border-solid rounded-lg flex flex-col gap-3 hover:shadow hover:cursor-pointer p-2 ${
                material.ReqStatus === 0
                  ? "bg-slate-50"
                  : material.ReqStatus === 1
                  ? "bg-green-100"
                  : "bg-red-100"
              }`}
            >
              <div className="flex gap-3">
                <div className="flex flex-col gap-1 text-left flex-1">
                  <p>
                    Request ID: Image
                    <span className="font-semibold">{material.id}</span>
                  </p>
                  <p>
                    Request from:{" "}
                    <span className="font-semibold">{material.userName}</span>
                  </p>
                </div>

                <div className="flex flex-col gap-1">
                  {/* Status badge */}
                  <div
                    className={`badge ${
                      material.ReqStatus === 0
                        ? "badge-info"
                        : material.ReqStatus === 1
                        ? "badge-success"
                        : "badge-error"
                    } gap-2 ml-auto`}
                  >
                    {material.ReqStatus === 0
                      ? "Pending"
                      : material.ReqStatus === 1
                      ? "Approved"
                      : "Disapproved"}
                  </div>
                </div>

                {/* Button to download the request */}
                <button
                  className="mb-auto"
                  onClick={() => handleDownloadImageRequest(material.Picture)}
                >
                  <DownloadIcon
                    className="hover:text-green-500 text-black"
                    fontSize="small"
                  />
                </button>

                {/* Button to edit the request */}
                {material.UserID === userID &&
                  isEmployeeLoggedIn &&
                  material.ReqStatus === 0 && (
                    <button
                      className="mb-auto"
                      onClick={() => {
                        handleEditImgRequest(material.id, material.RequestId);
                      }}
                    >
                      <EditIcon
                        className="hover:text-blue-500 text-black"
                        fontSize="inherit"
                      />
                    </button>
                  )}
              </div>

              <UpdateStatusImageReport
                MR_ID={material.id}
                MR_Status={material.ReqStatus}
              />

              {material.Picture && (
                <img
                  src={`${apiUrl}/uploads/${material.Picture}`}
                  alt="RequestdMaterials"
                  onClick={() =>
                    openModal(`${apiUrl}/uploads/${material.Picture}`)
                  }
                />
              )}
            </div>
          ))}
        </div>
        <div className="mb-72" />
      </div>

      {/* modal to edit each item */}
      {eachItemEditModal && editItemID && (
        <GeneralModal title="Edit Item Details in Report" onClose={toggleModal}>
          <EditReport
            reportId={editItemID}
            onCancel={() => setEditItemID(null)}
          />
        </GeneralModal>
      )}

      {/* modal to edit image report */}
      {editImgReportModal && editImgReportId && editImgRequestId && (
        <GeneralModal
          title="Edit Image Report"
          onClose={toggleEditImgReportModal}
        >
          <UpdateImageReport
            project={project}
            userID={userID}
            reportId={editImgReportId}
            requestId={editImgRequestId}
          />
        </GeneralModal>
      )}

      {modalImageUrl && (
        <ImageModal
          imageUrl={modalImageUrl}
          onClose={closeModal}
          title="Material Report"
        />
      )}
    </>
  );
};

export default ReportList;
